import { useSetRecoilState } from "recoil";
import { CancelButton, SaveButton } from "components/Common";
import { ReactComponent as UserIdIcon } from "assets/writeIcon/userid.svg";
import { ReactComponent as UserIcon } from "assets/writeIcon/name.svg";
import { ReactComponent as PartIcon } from "assets/writeIcon/part.svg";
import { ReactComponent as RoleIcon } from "assets/writeIcon/role.svg";
import { ReactComponent as PhoneIcon } from "assets/writeIcon/phone.svg";
import { ReactComponent as MailIcon } from "assets/writeIcon/mail.svg";
import { ReactComponent as PasswordIcon } from "assets/writeIcon/password.svg";
import { ReactComponent as AdminIcon } from "assets/writeIcon/admin.svg";
import { ReactComponent as NoteIcon } from "assets/writeIcon/note.svg";
import { useEffect, useId, useState } from "react";
import {
  createUser,
  Department,
  getPartList,
  getRoleList,
  Role,
} from "api/user";
import { userListState } from "recoil/stateStore";
import PhoneForm from "./PhoneForm";
import { SelectInput } from "components/SelectInput";
import { useErrorHandler } from "components/ErrorHandler";
import { Checkbox } from "@mui/material";

interface UserProps {
  onClose: (event?: any) => void;
  onSubmitSuccess: (e?: any) => void;
}

const NewUserWrite: React.FC<UserProps> = ({ onClose, onSubmitSuccess }) => {
  const [partList, setPartList] = useState<Department[]>([]);
  const [roleList, setRoleList] = useState<Role[]>([]);
  const handleError = useErrorHandler();
  const [userId, setUserId] = useState<string>("");
  const [partId, setPartId] = useState<number>(-1);
  const [roleId, setRoleId] = useState<number>(-1);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [compPhone, setCompPhone] = useState<string>("--");
  const [privatePhone, setPrivatePhone] = useState<string>("--");
  const [password, setPassword] = useState<string>("");
  const [isCar, setIsCar] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [note, setNote] = useState<string>("");
  const setUserList = useSetRecoilState(userListState);

  useEffect(() => {
    const callPartRoleList = async () => {
      const resPart = await getPartList();
      setPartList(resPart.data);
      const resRole = await getRoleList();
      setRoleList(resRole.data);
    };
    callPartRoleList();
  }, []);

  const id = useId();

  // 취소버튼
  const onCancel = () => {
    onClose();
  };

  // 저장방법
  const handleSubmit = async () => {
    // onSubmit 함수를 async로 선언
    console.log(typeof userId);
    if (userId === "" || name === "" || compPhone === "" || password === "") {
      alert("필수값이 입력되지 않았습니다. ");
    } else {
      try {
        const data = {
          user_id: userId,
          dep_id: partId,
          role_id: roleId,
          user_name: name,
          email: email,
          work_phone_number: compPhone,
          phone_number: privatePhone,
          password,
          is_car: isCar,
          is_admin: isAdmin,
          user_note: note,
        };

        await createUser(data);
        setUserList((prev) => [...prev, data]);
        onSubmitSuccess();
      } catch (error) {
        handleError(error); // 오류 처리 함수 호출
      }
    }
  };

  return (
    <>
      <div className="write_list_wrapper">
        <div className="write_content">
          <div>
            <div className="div_icon">
              <UserIdIcon style={{ marginRight: "4px" }} />
              사번
              <span style={{ color: "red" }}>&nbsp;*</span>
            </div>
            <input
              type="number"
              name="user_id"
              className="input_user_id"
              placeholder="숫자 입력"
              onChange={({ target }) => setUserId(target.value)}
            />
          </div>
          <div>
            <SelectInput
              label="부서"
              labelIcon={<PartIcon style={{ marginRight: "4px" }} />}
              id={`${id}-부서`}
              options={partList.map((part) => ({
                id: part.dep_id,
                name: part.dep_name,
              }))}
              value={partId}
              onChange={({ target }) => setPartId(parseInt(target.value))}
            />
          </div>
          <div>
            <SelectInput
              label="직책"
              labelIcon={<RoleIcon style={{ marginRight: "4px" }} />}
              id={`${id}-직책`}
              options={roleList.map((role) => ({
                id: role.role_id,
                name: role.role_name,
              }))}
              value={roleId}
              onChange={({ target }) => {
                setRoleId(parseInt(target.value));
              }}
            />
          </div>
          <div>
            <div className="div_icon">
              <UserIcon style={{ marginRight: "4px" }} />
              성명
              <span style={{ color: "red" }}>&nbsp;*</span>
            </div>

            <input
              type="text"
              name="user_name"
              onChange={({ target }) => setName(target.value)}
            />
          </div>
          <div>
            <div className="div_icon">
              <MailIcon style={{ marginRight: "4px" }} />
              이메일
              {/* <span style={{ color: "red" }}>&nbsp;*</span> */}
            </div>
            <input
              type="text"
              name="email"
              onChange={({ target }) => setEmail(target.value)}
              required
            />
          </div>
          <div className="input_phone_number">
            <div className="div_icon">
              <PhoneIcon style={{ marginRight: "4px" }} />
              연락처(업무)
              <span style={{ color: "red" }}>&nbsp;*</span>
            </div>

            <PhoneForm
              phoneNumberType="phone"
              phoneNumberData={compPhone}
              onPhoneNumberChange={setCompPhone}
            />
          </div>
          <div className="input_phone_number">
            <div className="div_icon">
              <PhoneIcon style={{ marginRight: "4px" }} />
              연락처(개인)
            </div>
            <PhoneForm
              phoneNumberType="phone"
              phoneNumberData={privatePhone}
              onPhoneNumberChange={setPrivatePhone}
            />
          </div>
          <div className="password_content">
            <div className="div_icon">
              <PasswordIcon style={{ marginRight: "4px" }} />
              비밀번호
              <span style={{ color: "red" }}>&nbsp;*</span>
            </div>
            <input
              type="password"
              name="password"
              onChange={({ target }) => setPassword(target.value)}
            />
          </div>
          {/* <div className="admin_box">
            <div className="div_icon">
              <Car
                style={{
                  marginRight: "4px",
                  fill: "black",
                }}
              />
              차량 유무
            </div>
            <div>
              <Checkbox
                sx={{
                  borderColor: "",
                }}
                checked={isCar ? true : false}
                inputProps={{ "aria-label": "controlled" }}
                onChange={({ target }) => setIsCar(target.checked)}
              />
            </div>
          </div> */}
          <div className="admin_box">
            <div className="div_icon">
              <AdminIcon
                style={{
                  marginRight: "4px",
                  fill: "black",
                  fillOpacity: "0.5",
                }}
              />
              관리자 계정
            </div>
            {/* <input
                type="checkbox"
                className="check_box"
                onChange={({ target }) => setIsAdmin(target.checked)}
              /> */}
            <div>
              <Checkbox
                sx={{
                  borderColor: "",
                }}
                checked={isAdmin}
                onChange={(e) => {
                  console.log("Checkbox checked:", e.target.checked);
                  setIsAdmin(e.target.checked);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
          </div>
          <div className="textarea_wrapper">
            <div className="div_icon">
              <NoteIcon style={{ marginRight: "4px" }} /> 비고
            </div>
            <textarea
              style={{ height: "140px", marginTop: "4px", color: "#000" }}
              name="work_content"
              onChange={({ target }) => setNote(target.value)}
            />
          </div>
        </div>
        <div className="write_bottom">
          <>
            <CancelButton onClickHandler={onCancel} />
            <SaveButton onClickHandler={handleSubmit} />
          </>
        </div>
      </div>
    </>
  );
};

export default NewUserWrite;
