import { Drawer } from "@mui/material";
import { ReactNode } from "react";

interface FormDrawerProps {
  open: boolean;
  onClose: (event: any) => void;
  children: ReactNode;
}

const WorkLogDrawer: React.FC<FormDrawerProps> = ({
  open,
  onClose,
  children,
}) => {
  return (
    <Drawer
      className="writeForm_Wrapper"
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        width: { sm: "35vw", xs: "45vw" }, // 화면 크기에 따라 너비 조정
        minWidth: 430, // 최소 너비
        "& .MuiDrawer-paper": {
          width: { sm: "35vw", xs: "45vw" },
          minWidth: 430,
        },
        zIndex: (theme) => theme.zIndex.drawer + 1, // 드로어의 z-index 설정
      }}
    >
      {children}
    </Drawer>
  );
};

export default WorkLogDrawer;
