import { useState } from "react";
import {
  DeleteButton,
  EditButton,
  emptyData,
  formatDate,
  handleMeetOptionText,
  handleWorkOptionText,
} from "components/Common";
import { useRecoilState, useSetRecoilState } from "recoil";
import { ReactComponent as CalendarIcon } from "assets/writeIcon/calendar.svg";
import { ReactComponent as UserIcon } from "assets/writeIcon/user.svg";
import { ReactComponent as WorkOptionIcon } from "assets/writeIcon/work_option.svg";
import { ReactComponent as MeetOptionIcon } from "assets/writeIcon/part.svg";
import { ReactComponent as CompanyIcon } from "assets/writeIcon/company.svg";
import { ReactComponent as PartIcon } from "assets/writeIcon/part.svg";
import { ReactComponent as MemberIcon } from "assets/writeIcon/member.svg";
import { ReactComponent as PlanIcon } from "assets/writeIcon/plan.svg";
import { ReactComponent as ResultIcon } from "assets/writeIcon/content.svg";
import { ReactComponent as ProgressIcon } from "assets/writeIcon/progress.svg";
import { ReactComponent as ExpectedIcon } from "assets/writeIcon/expected.svg";
import { ReactComponent as InprogressIcon } from "assets/writeIcon/inprogress.svg";
import { ReactComponent as CompleteIcon } from "assets/writeIcon/complete.svg";
import { ReactComponent as NotprogressIcon } from "assets/writeIcon/notprogress.svg";
import { ResponseWorkLog, deleteWorkLog, getWorkLog } from "api/workLog";
import DialogDelete from "components/DialogDelete";
import { workListState } from "recoil/stateStore";
import { autoSelectCompIdState, isEditedState } from "recoil/stateStore";

interface ViewWorkFormProps {
  workLogData: ResponseWorkLog;
  onClose: (event: any) => void;
  setDeletedId: Function;
}

const ViewWorkForm = ({
  workLogData,
  onClose,
  setDeletedId,
}: ViewWorkFormProps) => {
  const loginUserIdStr = localStorage.getItem("user_id");
  const loginUserId: string = loginUserIdStr ? loginUserIdStr : "";
  const setWorkList = useSetRecoilState(workListState);
  const [openDialog, setOpenDialog] = useState(false);
  const setAutoSelectCompId = useSetRecoilState(autoSelectCompIdState);
  const setIsEdit = useSetRecoilState(isEditedState);

  const handleDelete = async () => {
    const response = await deleteWorkLog(workLogData.work_id);
    setWorkList((prev: any) =>
      prev.filter(
        (works: ResponseWorkLog) => works.work_id !== workLogData.work_id
      )
    );
    setOpenDialog(false);
    onClose(false);
    setDeletedId(workLogData.work_id);
    console.log(response.data);
  };

  // 수정버튼
  const onEdit = async () => {
    setAutoSelectCompId(workLogData.company.comp_id);
    setIsEdit(true);
    setOpenDialog(false);
  };

  const formatProgress = (str: string) => {
    if (str === "COMPLETE") {
      return (
        <div className="div_icon" style={{ color: "#000" }}>
          완료 <CompleteIcon />
        </div>
      );
    }
    if (str === "INPROGRESS") {
      return (
        <div className="div_icon" style={{ color: "#000" }}>
          진행중 <InprogressIcon />
        </div>
      );
    }
    if (str === "EXPECTED") {
      return (
        <div className="div_icon" style={{ color: "#000" }}>
          예정 <ExpectedIcon />
        </div>
      );
    }
    if (str === "NOTPROGRESS") {
      return (
        <div className="div_icon" style={{ color: "#000" }}>
          미진행 <NotprogressIcon />
        </div>
      );
    }
  };

  const formatTime = (dateString: string) => {
    console.log(dateString, "dateString");
    const date = new Date(dateString);
    date.setHours(date.getHours() - 9);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  return (
    <div>
      <div className="write_list_wrapper">
        <div className="work_option">
          <div className="write_date">
            <div className="div_icon">
              <CalendarIcon style={{ marginRight: "4px" }} />
              일시
            </div>
            <span>
              {workLogData.work_date ? (
                <>
                  {(workLogData.work_date as unknown as string).split("T")[0]}
                  {" ("}
                  {new Intl.DateTimeFormat("ko-KR", {
                    weekday: "short",
                  }).format(new Date(workLogData.work_date))}
                  {")"}
                  {" /  "}
                  {formatTime(
                    workLogData.work_date
                      ? workLogData.work_date.toString()
                      : ""
                  )}
                </>
              ) : (
                ""
              )}
            </span>
          </div>
          <div>
            <div className="div_icon">
              <UserIcon style={{ marginRight: "4px" }} /> 담당자
            </div>
            <span>
              {workLogData.user?.user_name} ({workLogData.user?.user_id})
            </span>
          </div>
          <div>
            <div className="div_icon">
              <WorkOptionIcon style={{ marginRight: "4px" }} /> 업무구분
              <span style={{ color: "red" }}>&nbsp;*</span>
            </div>
            <span>{handleWorkOptionText(workLogData.work_option)}</span>
          </div>
          <div>
            <div className="div_icon">
              <MeetOptionIcon style={{ marginRight: "4px" }} /> 미팅방식
            </div>
            <span>{handleMeetOptionText(workLogData.meeting)}</span>
          </div>
          <div>
            <div className="div_icon">
              <PartIcon style={{ marginRight: "4px" }} /> 지역
            </div>
            <span>
              {workLogData.company?.comp_id === null ||
              workLogData.company?.comp_id === -1
                ? "-"
                : workLogData.company?.comp_sido +
                  " " +
                  workLogData.company?.comp_sgg}
            </span>
          </div>
          <div>
            <div className="div_icon">
              <CompanyIcon style={{ marginRight: "4px" }} /> 고객사명
              <span style={{ color: "red" }}>&nbsp;*</span>
            </div>
            <span>{emptyData(workLogData.company?.comp_name)}</span>
          </div>
          <div>
            <div className="div_icon">
              <MemberIcon style={{ marginRight: "4px" }} /> 고객사 담당자
              <span style={{ color: "red" }}></span>
            </div>
            <span>
              {emptyData(
                workLogData.member?.member_name +
                  " " +
                  workLogData.member?.member_role
              )}
            </span>
          </div>
          <div>
            <div className="div_icon">
              <PartIcon style={{ marginRight: "4px" }} />
              고객사 부서
            </div>
            <span>
              {workLogData.member?.member_id === null ||
              workLogData.member?.member_id === -1
                ? "-"
                : workLogData.member?.member_department}
            </span>
          </div>

          <div className="textarea_wrapper">
            <div className="div_icon">
              <PlanIcon style={{ marginRight: "4px" }} /> 계획일정
            </div>
            <textarea
              value={workLogData.plan ? workLogData.plan : ""}
              name="plan"
              disabled={true}
            />
          </div>
          <div className="textarea_wrapper">
            <div className="div_icon">
              <ResultIcon style={{ marginRight: "4px" }} /> 대응내용 및 결과
            </div>
            <textarea
              value={workLogData.work_content ? workLogData.work_content : ""}
              name="work_content"
              disabled={true}
            />
          </div>
          <div className="work_process">
            <div className="div_icon">
              <ProgressIcon style={{ marginRight: "4px" }} />
              진행상황
            </div>
            <span style={{ marginLeft: "8px" }}>
              {formatProgress(workLogData.progress)}
            </span>
          </div>
        </div>

        <div className="write_bottom">
          <>
            {loginUserId === workLogData.user?.user_id ? (
              <DeleteButton onClickHandler={() => setOpenDialog(true)} />
            ) : (
              ""
            )}
            {loginUserId === workLogData.user?.user_id ? (
              <EditButton onClickHandler={onEdit} />
            ) : (
              ""
            )}
            <DialogDelete
              open={openDialog}
              onCancel={() => setOpenDialog(false)}
              onDelete={handleDelete}
            />
          </>
        </div>
      </div>
    </div>
  );
};
export default ViewWorkForm;
