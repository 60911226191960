import { Outlet, useNavigate } from "react-router-dom";
import SideBar from "components/SideBar";
import Header from "components/Header";
import Box from "@mui/material/Box";
import "styles/MainStyle.css";
import useLogout from "components/useLogout";
import { useEffect, useState } from "react";

export default function MainPage() {
  const [token, setToken] = useState<string>(
    localStorage.getItem("token") || ""
  );
  useLogout();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [navigate, token]);

  if (!token) {
    return null;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Box sx={{ position: "sticky", top: 0, zIndex: 1100 }}>
        <Header />
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <Box
          component="nav"
          sx={{
            width: "220px",
          }}
        >
          <SideBar />
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            paddingTop: "65px",
            height: "calc(100vh - 5px)",
            overflowY: "auto",
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}
