import "./App.css";
import Routers from "components/Routers";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "util/theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routers />
    </ThemeProvider>
  );
}

export default App;
