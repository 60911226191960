import { client, getErrorMessage } from "./client";

export interface Company {
  comp_id: number;
  comp_name: string;
  comp_sido_nm: string;
  comp_sgg_nm: string;
  comp_address: string;
  sectors: string;
  comp_tel: string;
  comp_fax: string;
}
export interface Companies {
  total_count: number;
  content: Company[];
}

export interface CompanyList {
  content: Company[];
  page: number;
  pageSize: number;
  total_count: number;
}

export interface RegisterCompany {
  comp_name: string;
  comp_sido_nm: string;
  comp_sgg_nm: string;
  comp_address: string;
  sectors: string;
  comp_tel: string;
  comp_fax: string;
}

// id와 회사명
export interface SimpleCompany {
  comp_id: number;
  comp_name: string;
}

// 신규 업체 등록
export interface WriteNewCompany {
  comp_name: string;
  comp_sido_nm: string;
  comp_sgg_nm: string;
  comp_address: string;
  sectors: string;
  comp_tel: string;
  comp_fax: string;
}

// 전체 고객사 조회
export const getTotalCompanyList = async () => {
  try {
    const response = await client.get("/total_companies");
    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

// 업체 리스트
export const getCompanyList = async () => {
  try {
    const response = await client.get("/companies", {
      // params: { keyword, pageSize },
    });
    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

// 업체현황 리스트에 띄울 고객사 조회
// export const getCompanyList = async (searchParams: SearchParams) => {
//   try {
//     const response = await getData<CompaniesInfo>("/companies", {
//       params: searchParams,
//     });
//     const data: CompaniesInfo = {
//       total_count: response.data.total_count,
//       content: Array.isArray(response.data.content)
//         ? response.data.content
//         : [],
//     };
//     return data;
//   } catch (error: any) {
//     throw {
//       status: error.status,
//       message: error.message,
//     };
//   }
// };

// 고객사 개별 조회
export const getCompany = async (comp_id: number) => {
  try {
    const { data } = await client.get(`/company/${comp_id}`);
    return data;
  } catch (error: any) {
    throw {
      status: error.status,
      message: error.message,
    };
  }
};

// 고객사 등록
export const postCompany = async (company: RegisterCompany) => {
  try {
    const response = await client.post("/companies", company);
    console.log(response);
    return response;
  } catch (error: any) {
    throw {
      status: error.status,
      message: error.message,
    };
  }
};

// 고객사 수정
export const patchCompany = async (
  comp_id: number,
  updateCompanyInfo: RegisterCompany
) => {
  try {
    const response = await client.patch(
      `/company/${comp_id}`,
      updateCompanyInfo
    );
    console.log(response);
    return response.data;
  } catch (error: any) {
    throw {
      status: error.status,
      message: error.message,
    };
  }
};

// // 고객사 삭제
// export const deleteCompany = async (comp_id: number) => {
//   try {
//     const response = await deleteData<CompanyInfo>(`/company/${comp_id}`);
//     console.log(response);
//     if (response.status === 400) {
//       console.log("404 Not Found: 해당 고객사에 포함된 업무일지가 존재합니다.");
//     }
//     return response;
//   } catch (error: any) {
//     throw {
//       status: error.status,
//       message: error.message,
//     };
//   }
// };
