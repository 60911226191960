import { useEffect, useState } from "react";
import PhoneForm from "components/writeForm/PhoneForm";
import { CancelButton, SaveButton, successSave } from "components/Common";
import { ReactComponent as CompanyIcon } from "assets/writeIcon/company.svg";
import { ReactComponent as WorkOptionIcon } from "assets/writeIcon/work_option.svg";
import { ReactComponent as AddressIcon } from "assets/writeIcon/address.svg";
import { ReactComponent as CallIcon } from "assets/writeIcon/call.svg";
import { ReactComponent as FaxIcon } from "assets/writeIcon/fax.svg";
import { GuGun, regionDatas } from "components/RegionData";
import { Company, getCompany, patchCompany } from "api/company";
import { useErrorHandler } from "components/ErrorHandler";
import { useSetRecoilState } from "recoil";
import { isEditedState } from "recoil/stateStore";

interface EditCompFormProps {
  companyData: Company;
  setCompData: Function;
  onClose: (event?: any) => void;
  compId: number;
}

const EditCompanyForm: React.FC<EditCompFormProps> = ({
  companyData,
  setCompData,
  onClose,
  compId,
}) => {
  const [compName, setCompName] = useState<string>(companyData.comp_name);
  const [address, setAddress] = useState<string>(companyData.comp_address);
  const [sido, setSido] = useState<string>(companyData.comp_sido_nm);
  const [guguns, setGuguns] = useState<string>(companyData.comp_sgg_nm);
  const [sectors, setSectors] = useState<string>(companyData.sectors);
  const [compTel, setCompTel] = useState<string>(companyData.comp_tel);
  const [compFax, setCompFax] = useState<string>(companyData.comp_fax);
  const [selectedSiDo, setSelectedSiDo] = useState<string>("");
  const [selectedGuGun, setSelectedGuGun] = useState<string>("");
  const [guGuns, setGuGuns] = useState<GuGun[]>([]);
  const handleError = useErrorHandler();
  const setIsEdit = useSetRecoilState(isEditedState);

  const handlePhoneNumberChange = (
    phoneNumber: string,
    phoneNumberType: "tel" | "phone" | "fax"
  ) => {
    phoneNumberType === "tel"
      ? setCompTel(phoneNumber)
      : setCompFax(phoneNumber);
  };

  const handleSiDoChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const siDoFull = event.target.value;
    setSelectedSiDo(siDoFull);
    setSelectedGuGun("");
    const siDo = regionDatas.find((sd) => sd.siDoFull === siDoFull);
    if (siDo) {
      setGuGuns(siDo.guGuns);
      setSido(event.target.value);
      setGuguns("");
    } else {
      setGuGuns([]);
    }
  };

  useEffect(() => {
    console.log(compId, "idididi");
    // const siDoFull = sido;
    setSelectedSiDo(sido);
    const siDo = regionDatas.find((sd) => sd.siDoFull === sido);
    if (siDo) {
      setGuGuns(siDo.guGuns);
      setSelectedGuGun(companyData.comp_sgg_nm);
    } else {
      setGuGuns([]);
    }
  }, []);

  const handleGuGunChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedGuGun(event.target.value);
    setGuguns(event.target.value);
  };

  // 취소버튼
  const onCancel = () => {
    onClose();
  };

  // 저장버튼
  const handleSubmit = async () => {
    console.log("수정 저장");
    if (compName === "" || sido === "" || sectors === "") {
      alert("필수값이 입력되지 않았습니다.");
    } else if (compTel.length < 5) {
      alert("잘못된 번호 입력입니다.");
    } else {
      try {
        const data = {
          comp_name: compName,
          comp_sido_nm: sido,
          comp_sgg_nm: guguns,
          comp_address: address,
          sectors: sectors,
          comp_tel: compTel,
          comp_fax: compFax,
        };
        const updateCompData = await patchCompany(compId, data);
        setCompData(updateCompData.data);
        console.log(updateCompData, "수정 데이터");
        setIsEdit(false);
      } catch (error) {
        handleError(error); // 오류 처리 함수 호출
      }
    }
  };

  return (
    <div>
      <div className="company_write_wrapper">
        <div>
          <div className="div_icon">
            <CompanyIcon style={{ marginRight: "4px" }} />
            업체명
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <input
            type="text"
            placeholder="내용 입력"
            value={compName}
            onChange={({ target }) => setCompName(target.value)}
          />
        </div>
        <div>
          <div className="div_icon">
            <WorkOptionIcon style={{ marginRight: "4px" }} />
            업종
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <input
            type="text"
            placeholder="내용 입력"
            value={sectors}
            onChange={({ target }) => setSectors(target.value)}
          />
        </div>
        <div style={{ marginTop: "6px" }}>
          <div className="div_icon" style={{ height: "34px" }}>
            <AddressIcon style={{ marginRight: "4px" }} />
            지역
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <div className="region_select">
            <select value={selectedSiDo} onChange={handleSiDoChange}>
              <option value="">시/도 선택</option>
              {regionDatas.map((sd, index) => (
                <option key={index} value={sd.siDoFull}>
                  {sd.siDoFull}
                </option>
              ))}
            </select>
            <select
              value={selectedGuGun}
              onChange={handleGuGunChange}
              disabled={!selectedSiDo}
            >
              <option value="">시/구/군 선택</option>
              {guGuns.map((gg, index) => (
                <option key={index} value={gg.guGunName}>
                  {gg.guGunName}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="company_address" style={{ marginBottom: "6px" }}>
          <input
            style={{ width: "70%" }}
            type="text"
            placeholder="상세주소 입력 (선택)"
            value={address}
            onChange={({ target }) => setAddress(target.value)}
          />
        </div>
        <div>
          <div className="div_icon">
            <CallIcon style={{ marginRight: "4px" }} />
            전화번호
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <PhoneForm
            phoneNumberType="tel"
            phoneNumberData={compTel}
            onPhoneNumberChange={handlePhoneNumberChange}
          />
        </div>
        <div className="company_fax">
          <div className="div_icon">
            <FaxIcon style={{ marginRight: "4px" }} />
            팩스번호
          </div>
          <PhoneForm
            phoneNumberType="fax"
            phoneNumberData={compFax}
            onPhoneNumberChange={handlePhoneNumberChange}
          />
        </div>
        <div className="write_bottom">
          <CancelButton onClickHandler={onCancel} />
          <SaveButton onClickHandler={handleSubmit} />
        </div>
      </div>
    </div>
  );
};
export default EditCompanyForm;
