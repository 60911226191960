import React, { useState, useRef, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { ColDef } from "ag-grid-community";
import "styles/Chart.css";
import { useRecoilValue } from "recoil";
import { ResponseYearPlan } from "api/yearPlan";
import { isEditedState } from "recoil/stateStore";
import {
  calculateFirstHalf,
  calculateSecondHalf,
  calculateTotal,
  convertRegionName,
} from "./Common";
import { Member } from "api/member";

interface YearGridProps {
  columnDefs: ColDef[];
  rowData: any[];
  setOpen: (status: boolean) => void;
  setId: (type: string, id: number) => void;
  newData: ResponseYearPlan | undefined;
  deletedId: number;
}

const YearGrid: React.FC<YearGridProps> = ({
  columnDefs,
  rowData,
  setOpen,
  setId,
  newData,
  deletedId,
}) => {
  const gridRef = useRef<AgGridReact>(null);
  const [quickFilterText, setQuickFilterText] = useState<string>("");
  const [view, setView] = useState<number>(10);
  const filterInputRef = useRef<HTMLInputElement>(null);
  const [gridHeight, setGridHeight] = useState<number>(500);
  const isEdit = useRecoilValue(isEditedState);

  useEffect(() => {
    if (view === 10) {
      setGridHeight(480);
    } else if (view === 25) {
      setGridHeight(1100);
    } else {
      setGridHeight(500);
    }
  }, [view]);

  useEffect(() => {
    if (!isEdit) updateRow();
  }, [newData]);

  useEffect(() => {
    if (deletedId !== -1) {
      onRowDelete();
    }
  }, [deletedId]);

  // 필터 리셋
  const resetFilters = () => {
    const gridApi = gridRef.current?.api;
    if (gridApi) {
      gridApi.setFilterModel(null);
      gridApi.onFilterChanged();
      gridApi.applyColumnState({
        defaultState: { sort: null },
      });
    }
    setQuickFilterText("");
    if (filterInputRef.current) {
      filterInputRef.current.value = "";
    }
  };

  const localeText = {
    contains: "포함",
    notContains: "포함하지 않음",
    equals: "같음",
    dateFormatOoo: "yyyy-mm-dd",
  };

  const toggleDrawer = () => {
    setOpen(true);
  };

  const onRowClicked = async (event: any) => {
    const planId = Number(event.data.aplan_id);
    try {
      setId("planId", planId);
      toggleDrawer();
    } catch (error) {
      console.error("Error fetching yPlan data:", error);
    }
  };

  const gridOptions = {
    getRowId: (params: any) => {
      return String(params.data.aplan_id); // 고유 식별자로 aplan_id 사용
    },
  };

  const onRowDelete = () => {
    const id = String(deletedId);
    if (gridRef.current && gridRef.current.api) {
      const rowNode = gridRef.current.api.getRowNode(id);
      if (rowNode) {
        gridRef.current.api.applyTransaction({ remove: [rowNode.data] });
      }
    }
  };

  const updateRow = () => {
    if (gridRef.current && gridRef.current.api) {
      // newData가 존재하는지 및 aplan_id가 있는지 확인
      if (!newData || !newData.aplan_id) {
        console.log("Invalid data or missing aplan_id in newData.");
        return;
      }
      const id = String(newData.aplan_id);
      // 기존의 데이터를 가져와서 수정된 데이터와 병합
      const rowNode = gridRef.current.api.getRowNode(id);
      if (!rowNode) {
        console.error(`Row with aplan_id ${newData.aplan_id} not found.`);
        return;
      }
      const regionTotal = Number(
        rowNode.data.sumDataByRegion.replace(/,/g, "")
      );
      const sumData =
        regionTotal - Number(rowNode.data.total) + (newData.total ?? 0);
      const address =
        convertRegionName(newData.company?.comp_sido_nm || "") +
        " " +
        newData.company?.comp_sgg_nm;
      const updatedData = {
        ...newData,
        no: rowNode.data.no,
        company: newData.company?.comp_name,
        sector: newData.company?.sectors,
        address: address,
        sumDataByRegion: sumData.toLocaleString("ko-KR"),
        first_q: (newData.first_quarter | 0).toLocaleString("ko-KR"),
        second_q: (newData.second_quarter | 0).toLocaleString("ko-KR"),
        third_q: (newData.third_quarter | 0).toLocaleString("ko-KR"),
        fourth_q: (newData.fourth_quarter | 0).toLocaleString("ko-KR"),
        firstHalf: calculateFirstHalf(newData).toLocaleString("ko-KR"),
        secondHalf: calculateSecondHalf(newData).toLocaleString("ko-KR"),
        total_p: calculateTotal(newData).toLocaleString("ko-KR"),
        membersWithRoles: newData.comp_members
          .map(
            (member: Member) => `${member.member_name} ${member.member_role}`
          )
          .join(", "),
      };

      // 업데이트를 위한 트랜잭션 적용
      gridRef.current.api.applyTransaction({
        update: [updatedData],
      });
      updateSumDataByRegion(address, sumData);
      console.log(
        `Row with aplan_id ${newData.aplan_id} updated:`,
        updatedData
      );
    } else {
      console.log("Grid API is not initialized.");
    }
  };

  const updateSumDataByRegion = (address: string, sumData: number) => {
    if (gridRef.current && gridRef.current.api) {
      // 모든 행을 순회
      gridRef.current.api.forEachNode((rowNode) => {
        // 특정 address와 일치하는 행 찾기
        if (rowNode.data.address === address) {
          // sumDataByRegion이 문자열이 아닐 경우, 문자열로 변환
          const currentSumData =
            typeof rowNode.data.sumDataByRegion === "string"
              ? rowNode.data.sumDataByRegion
              : String(rowNode.data.sumDataByRegion);

          // sumDataByRegion 업데이트
          const updatedData = {
            ...rowNode.data,
            sumDataByRegion: sumData.toLocaleString("ko-KR"),
          };

          // 행 데이터 업데이트
          rowNode.setData(updatedData);
        }
      });
    } else {
      console.error("Grid API is not initialized.");
    }
  };

  return (
    <>
      <div className="grid-container">
        <div className="filter">
          <div>
            <div className="wordSearch_container">
              <label>검색 :</label>
              <input
                ref={filterInputRef}
                type="text"
                placeholder="검색어를 입력하세요"
                onChange={(e) => setQuickFilterText(e.target.value)}
              />
              <button className="resetBtn" onClick={resetFilters}>
                필터 리셋
              </button>
            </div>
            <div className="viewSize_btn_box">
              <label>View Size :</label>
              <button
                className={
                  view === 10 ? "viewSize_btn selected" : "viewSize_btn"
                }
                onClick={() => setView(10)}
              >
                10
              </button>
              <button
                className={
                  view === 25 ? "viewSize_btn selected" : "viewSize_btn"
                }
                onClick={() => setView(25)}
              >
                25
              </button>
            </div>
          </div>
        </div>
        <div
          className="ag-theme-alpine"
          style={{ height: `${gridHeight}px`, width: "100%" }}
        >
          <AgGridReact
            ref={gridRef}
            gridOptions={gridOptions}
            columnDefs={columnDefs}
            rowData={rowData}
            quickFilterText={quickFilterText}
            localeText={localeText}
            onRowClicked={onRowClicked}
            rowSelection="single"
            animateRows={true}
            overlayNoRowsTemplate={"데이터가 존재하지 않습니다."}
            popupParent={document.body} //필터 누르면 표하단 가려지는 문제 해결용
          />
        </div>
      </div>
    </>
  );
};

export default YearGrid;
