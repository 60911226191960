import { client, getErrorMessage } from "./client";

// 고객사 담당자
export interface Member {
  member_id: number;
  member_name: string;
  member_department: string;
  member_role: string;
  member_phone: string;
  member_email: string;
  member_note: string;
}

export interface MembersInfo {
  total_count: number;
  content: {
    company: SimpleCompany;
    member_list: Member[];
  };
}
// id와 회사명
export interface SimpleCompany {
  comp_id: number;
  comp_name: string;
}

// 신규 담당자 등록 요청
export interface NewMemberInfo {
  member_name: string;
  member_department: string;
  member_role: string;
  member_phone: string;
  member_email: string;
  member_note: string;
  is_deleted: boolean;
}

// 신규 담당자 등록 응답
export interface ResponseNewMember {
  company: SimpleCompany;
  member_id: number;
  member_name: string;
  member_department: string;
  member_role: string;
  member_phone: string;
  member_email: string;
  member_note: string;
  is_deleted: boolean;
}

// //고객사에 따른 고객사 담당자 전체 조회
// export const getMembers = async (comp_id: number) => {
//   try {
//     const response = await client.get(
//       `/company/${comp_id}/members`
//     );

//     const data: MembersInfo = {
//       total_count: response.data.total_count,
//       content: {
//         company: response.data.content.company,
//         member_list: Array.isArray(response.data.content.member_list)
//           ? response.data.content.member_list
//           : [],
//       },
//     };
//     return data;
//   } catch (error: any) {
//     throw {
//       status: error.status,
//       message: error.message,
//     };
//   }
// };

//고객사에 따른 고객사 담당자 전체 조회
export const getCompanyMembers = async (comp_id: number | null) => {
  try {
    const response = await client.get(`/company/${comp_id}/members?page=`);
    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

//고객사에 따른 고객사 담당자 개인정보 조회
export const getCompanyMember = async (comp_id: number, member_id: number) => {
  try {
    const token = localStorage.getItem("token");
    const response = await client.get(
      `/company/${comp_id}/member/${member_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.log(error);

    throw new Error(getErrorMessage(error));
  }
};

// 신규 업체 담당자 등록
export const postMember = async (comp_id: number, newMember: NewMemberInfo) => {
  try {
    const token = localStorage.getItem("token");

    const response = await client.post(
      `/company/${comp_id}/members`,
      newMember,
      {
        headers: {
          Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 포함
        },
      }
    );
    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

// 업체 담당자 수정
export const patchMember = async (
  comp_id: number,
  member_id: number,
  newMember: NewMemberInfo
) => {
  try {
    const token = localStorage.getItem("token");
    const response = await client.patch(
      `/company/${comp_id}/member/${member_id}`,
      newMember,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

// 업체 담당자 삭제
export const deleteMember = async (comp_id: number, member_id: number) => {
  try {
    // const token = localStorage.getItem("token");
    // const headers = {
    //   Authorization: `Bearer ${token}`,
    // };
    const response = await client.delete<ResponseNewMember>(
      `/company/${comp_id}/member/${member_id}`
    );
    return response;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};
