import { ReactComponent as CalendarIcon } from "assets/writeIcon/calendar.svg";
import { ReactComponent as CarIcon } from "assets/writeIcon/car.svg";
import { ReactComponent as PurposeIcon } from "assets/writeIcon/purpose.svg";
import { ReactComponent as UserIcon } from "assets/writeIcon/member.svg";
import { ReactComponent as StartIcon } from "assets/writeIcon/start.svg";
import { ReactComponent as EndIcon } from "assets/writeIcon/end.svg";
import { ReactComponent as DistanceIcon } from "assets/writeIcon/distance.svg";
import { ReactComponent as Approval } from "assets/writeIcon/approval.svg";
import { ReactComponent as NoteIcon } from "assets/writeIcon/note.svg";
import {
  DeleteButton,
  EditButton,
  emptyData,
  formatDate,
} from "components/Common";
import DialogDelete from "components/DialogDelete";
import { useState } from "react";
import { ResponseCarLog, deleteCarLog } from "api/carLog";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { isEditedState, loginUserInfoState } from "recoil/stateStore";

interface ViewCarFormProps {
  carLogData: ResponseCarLog;
  onClose: (event: any) => void;
  setDeletedId: Function;
}

const ViewCarLogForm = ({
  carLogData,
  onClose,
  setDeletedId,
}: ViewCarFormProps) => {
  const loginUserIdStr = localStorage.getItem("user_id");
  const loginUserId: string = loginUserIdStr ? loginUserIdStr : "";
  const [openDialog, setOpenDialog] = useState(false);
  const loginUserInfo = useRecoilValue(loginUserInfoState);
  const setIsEdit = useSetRecoilState(isEditedState);

  // 수정버튼
  const onEdit = async () => {
    setIsEdit(true);
    setOpenDialog(false);
  };

  const handleDelete = async () => {
    const response = await deleteCarLog(carLogData.carlog_id);
    setOpenDialog(false);
    setDeletedId(carLogData.carlog_id);
    onClose(false);
    console.log(response.data);
  };

  const formatPurpose = (data: string) => {
    if (data === "BUSINESS") {
      return "업무용";
    }
    if (data === "NON_BUSINESS") {
      return "비업무용";
    }
    if (data === "OTHER") {
      return "기타";
    }
  };

  return (
    <div className="write_list_wrapper">
      <div className="work_option">
        <div className="write_date">
          <div className="div_icon" style={{ marginRight: "15px" }}>
            <CalendarIcon style={{ marginRight: "4px" }} />
            일시
          </div>
          <text>{formatDate(carLogData.car_date)}</text>
        </div>
        <div>
          <div className="div_icon">
            <CarIcon style={{ marginRight: "4px" }} />
            차량
          </div>
          <span>{carLogData.car?.car_num}</span>
        </div>
        <div>
          <div className="div_icon">
            <PurposeIcon style={{ marginRight: "4px" }} />
            사용목적
          </div>
          <span>{formatPurpose(carLogData.purpose)}</span>
        </div>
        <div>
          <div className="div_icon"></div>
          <span>{emptyData(carLogData.purpose_detail)}</span>
        </div>
        <div>
          <div className="div_icon">
            <UserIcon style={{ marginRight: "4px" }} />
            운전자
          </div>
          <span>{carLogData.user?.user_name}</span>
        </div>
        <div>
          <div className="div_icon">
            <StartIcon style={{ marginRight: "4px" }} />
            출발지
          </div>
          <span>{carLogData.loc_start}</span>
        </div>
        <div>
          <div className="div_icon">
            <EndIcon style={{ marginRight: "4px" }} />
            도착지
          </div>
          <span>{carLogData.loc_end}</span>
        </div>
        <div>
          <div className="div_icon" style={{ color: "rgb(0,0,0,0.6)" }}>
            출발 전 주행거리
          </div>
          <span>
            {carLogData.acc_start}{" "}
            <span style={{ color: "rgb(0, 0, 0, 0.6)", marginLeft: "5px" }}>
              km
            </span>
          </span>
        </div>
        <div>
          <div className="div_icon" style={{ color: "rgb(0,0,0,0.6)" }}>
            도착 후 주행거리
          </div>
          <span>
            {carLogData.acc_end}{" "}
            <span style={{ color: "rgb(0, 0, 0, 0.6)", marginLeft: "5px" }}>
              km
            </span>
          </span>
        </div>
        <div>
          <div className="div_icon">
            <DistanceIcon style={{ marginRight: "4px" }} />
            주행거리
          </div>
          <span>
            {carLogData.acc_end - carLogData.acc_start}{" "}
            <span style={{ color: "rgb(0, 0, 0, 0.6)", marginLeft: "5px" }}>
              km
            </span>
          </span>
        </div>

        <div className="textarea_wrapper">
          <div className="div_icon">
            <NoteIcon style={{ marginRight: "4px" }} /> 비고
          </div>
          <textarea
            value={carLogData.carlog_note ? carLogData.carlog_note : ""}
            style={{ color: "#000", marginTop: "5px" }}
            name="cons_note"
            disabled={true}
          />
        </div>
        {loginUserInfo.is_admin && (
          <div
            className="approval_option"
            style={{
              display: "flex",
              lineHeight: "34px",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "12px",
            }}
          >
            <div className="div_icon">
              <Approval style={{ marginRight: "4px" }} />
              승인여부
            </div>
            <span>{carLogData.is_approval ? "승인 완료" : "미승인"}</span>
            {/* <select
              disabled={true}
              value={carLogData.is_approval ? "COMPLETE" : "NOTPROGRESS"}
              style={{ paddingLeft: "0 5px", width: "196px" }}
            >
              <option value="NOTPROGRESS">미승인</option>
              <option value="COMPLETE">승인</option>
            </select> */}
          </div>
        )}
      </div>
      <div className="write_bottom">
        <>
          {loginUserId === carLogData.user?.user_id ? (
            <DeleteButton onClickHandler={() => setOpenDialog(true)} />
          ) : (
            ""
          )}
          {loginUserId === carLogData.user?.user_id ? (
            <EditButton onClickHandler={onEdit} />
          ) : (
            ""
          )}
          <DialogDelete
            open={openDialog}
            onCancel={() => setOpenDialog(false)}
            onDelete={handleDelete}
          />
        </>
      </div>
    </div>
  );
};
export default ViewCarLogForm;
