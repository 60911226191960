import { useEffect, useState } from "react";
import { ColDef, ValueFormatterParams } from "ag-grid-community";
import Card from "@mui/material/Card";
import { ReactComponent as WriteIcon } from "assets/write.svg";
import { CounselingType, getCounselingList } from "api/counseling";
import {
  dateFilterParams,
  formatDate,
  formatProgress,
  textFilterParams,
} from "components/Common";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  autoSelectCompIdState,
  filterUserState,
  isEditedState,
  searchRangeDateState,
} from "recoil/stateStore";
import SelectUserInfo from "components/SelectUserInfo";
import CreateWorkDrawer from "components/drawer/CreateDrawer";
import Grid from "components/Grid";
import ViewConsDrawer from "components/drawer/ViewConsDrawer";
import DialogText from "components/DialogText";
import { useNavigate } from "react-router-dom";

interface RowData {
  cons_id: number;
  user_id: string;
  user_name: string;
  cons_date: Date;
  company: string;
  member: string;
  project: string;
  item: string;
  amount: number;
  price: number; //상담 예상액
  sum: number;
  result: string;
  cons_note: string;
}

export default function Counseling() {
  const navigate = useNavigate();
  const [rowData, setRowData] = useState<RowData[]>([]);
  const [consDrawerOpen, setConsDrawerOpen] = useState<boolean>(false);
  const [selectedConsId, setSelectedConsId] = useState<number>(-1);
  const [userDrawerOpen, setUserDrawerOpen] = useState<string>("");
  const setAutoSelectCompId = useSetRecoilState(autoSelectCompIdState);
  const [isEdit, setIsEdit] = useRecoilState(isEditedState);
  const [openDialog, setOpenDialog] = useState(false);
  const filterUser = useRecoilValue(filterUserState);
  const rangeDate = useRecoilValue(searchRangeDateState);
  const [updatedData, setUpdatedData] = useState<CounselingType>();
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [deletedId, setDeletedId] = useState<number>(-1);
  const [viewSize, setViewSize] = useState<number>(10);

  useEffect(() => {
    const callCounselingList = async () => {
      try {
        const { data } = await getCounselingList(
          filterUser,
          rangeDate[0],
          rangeDate[1]
        );
        const mappedData = data.content.map((item: CounselingType) => ({
          cons_id: item.cons_id,
          user_name: item.user_name,
          cons_date: formatDate(item.cons_date),
          comp_name: item.company.comp_name,
          comp_id: item.company?.comp_id,
          member_name: item.member.member_name + " " + item.member.member_role,
          member_phone: item.member.member_phone,
          project: item.project,
          item: item.item,
          amount: item.amount,
          price: item.price,
          sum: item.amount * item.price,
          result: formatProgress(item.result),
          cons_note: item.cons_note,
        }));
        setRowData(mappedData);
        console.log(data.content, "counselingList");
        setIsSaved(false);
      } catch (error) {
        console.error("Error fetching work logs:", error);
      }
    };
    callCounselingList();
  }, [filterUser, isSaved, rangeDate]);

  const columnDefs: ColDef[] = [
    {
      field: "cons_id",
      headerName: "ID",
      filter: "agDateColumnFilter",
      filterParams: textFilterParams,
      flex: 0.5,
    },
    {
      headerName: "일시",
      field: "cons_date",
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      minWidth: 140,
      flex: 0.9,
    },
    {
      headerName: "작성자",
      field: "user_name",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 0.6,
    },
    {
      headerName: "업체명",
      field: "comp_name",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 0.7,
      cellRenderer: (params: ValueFormatterParams) => {
        const compId = params.data.comp_id;
        console.log(params, compId, "compId");
        return (
          <p
            className="comp_name"
            onClick={() =>
              navigate(`/companies/${params.value}`, {
                state: { id: compId },
              })
            }
          >
            {params.value}
          </p>
        );
      },
    },
    {
      headerName: "업체 담당자",
      field: "member_name",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 0.8,
    },
    // {
    //   headerName: "연락처",
    //   field: "member_phone",
    //   filter: "agTextColumnFilter",
    //   filterParams: textFilterParams,
    //   flex: 1.4,
    // },
    {
      headerName: "프로젝트",
      field: "project",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 1.2,
    },
    {
      headerName: "품목",
      field: "item",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 1.2,
    },
    {
      headerName: "수량",
      field: "amount",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 0.6,
    },
    {
      headerName: "단가",
      field: "price",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 1,
    },
    {
      headerName: "합계",
      field: "sum",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 1,
    },
    {
      headerName: "비고",
      field: "cons_note",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 0.9,
    },
    {
      headerName: "결과",
      field: "result",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 0.6,
      cellStyle: (params: any) => {
        if (params.value === "완료") {
          return { backgroundColor: "#cbdca7" }; // 핀된 행의 글자 색상을 변경
        } else if (params.value === "진행중") {
          return { backgroundColor: "#f6e9a1" }; // 핀된 행의 글자 색상을 변경
        } else if (params.value === "예정") {
          return { backgroundColor: "#a1c0f6" }; // 핀된 행의 글자 색상을 변경
        } else if (params.value === "미진행") {
          return { backgroundColor: "#f6a1a1" }; // 핀된 행의 글자 색상을 변경
        }
        return null; // 기본 스타일
      },
    },
  ];

  const handleSetOpen = (status: boolean, type: string) => {
    if (type === "newCounseling") {
      setAutoSelectCompId(null);
      setConsDrawerOpen(true);
      setUserDrawerOpen("create");
    }
    if (type === "counseling") {
      setConsDrawerOpen(true);
      setUserDrawerOpen("view");
    }
  };

  const closeDrawer = () => {
    if (userDrawerOpen === "view" && !isEdit) {
      closeDialog();
    } else {
      setOpenDialog(true);
    }
  };

  const handleSubmitSuccess = () => {
    setIsSaved(true);
    closeDialog();
  };

  const closeDialog = () => {
    setConsDrawerOpen(false);
    setOpenDialog(false);
    setIsEdit(false);
  };

  const selectedId = (type: string, id: number) => {
    setSelectedConsId(id);
  };

  const pageSizeChange = (size: number) => {
    setViewSize(size);
  };

  return (
    <>
      <div className="mainTop_wrapper">
        <div className="title">
          <div className="menu">Work management</div>
          <div className="sub_menu">
            <div className="sub_menu__title">
              <h1>상담일지</h1>
              <SelectUserInfo selectUserId={filterUser || ""} />
            </div>
            <div>
              <button
                className="write_button"
                onClick={() => handleSetOpen(true, "newCounseling")}
              >
                <WriteIcon />
                신규 상담 등록
              </button>
            </div>
          </div>
        </div>

        <Card id={"main_card"}>
          <Grid
            columnDefs={columnDefs}
            rowData={rowData}
            type="counseling"
            setOpen={handleSetOpen}
            setId={selectedId}
            newData={updatedData}
            deletedId={deletedId}
          />
          <ViewConsDrawer
            open={userDrawerOpen === "view" && consDrawerOpen}
            onClose={closeDrawer}
            consId={selectedConsId}
            setUpdatedData={setUpdatedData}
            setDeletedId={setDeletedId}
          />
          <CreateWorkDrawer
            open={userDrawerOpen === "create" && consDrawerOpen}
            onClose={closeDrawer}
            onSubmitSuccess={handleSubmitSuccess}
            type={"Counseling"}
          />
          <DialogText
            open={openDialog}
            content="변경 사항이 저장되지 않을 수 있습니다. 계속하시겠습니까?"
            onCancel={() => setOpenDialog(false)}
            onClose={closeDialog}
          />
        </Card>
      </div>
    </>
  );
}
