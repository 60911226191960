import { ReactComponent as CalendarIcon } from "assets/writeIcon/calendar.svg";
import { ReactComponent as CarIcon } from "assets/writeIcon/car.svg";
import { ReactComponent as PurposeIcon } from "assets/writeIcon/purpose.svg";
import { ReactComponent as UserIcon } from "assets/writeIcon/member.svg";
import { ReactComponent as StartIcon } from "assets/writeIcon/start.svg";
import { ReactComponent as EndIcon } from "assets/writeIcon/end.svg";
import { ReactComponent as DistanceIcon } from "assets/writeIcon/distance.svg";
import { ReactComponent as Approval } from "assets/writeIcon/approval.svg";
import { ReactComponent as NoteIcon } from "assets/writeIcon/note.svg";
import { ResponseCarLog, updateCarLog, patchCarLogApproval } from "api/carLog";
import { useEffect, useState } from "react";
import { CancelButton, SaveButton } from "components/Common";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  checkAdminState,
  isEditedState,
  loginUserInfoState,
} from "recoil/stateStore";
import DialogText from "components/DialogText";
import { useErrorHandler } from "components/ErrorHandler";
import { ResponseCar, getCar, getCarList } from "api/car";

export const emptyData = (data: string | number) => {
  if (
    data === "" ||
    data === "-1" ||
    data === null ||
    data === undefined ||
    data === "null null"
  ) {
    return "-";
  } else {
    return String(data);
  }
};

interface EditCarLogFormProps {
  carLogData: ResponseCarLog;
  setCarLogData: Function;
}

const EditCarLogForm: React.FC<EditCarLogFormProps> = ({
  carLogData,
  setCarLogData,
}) => {
  const loginUsername = localStorage.getItem("user_name");
  const loginUserIdStr = localStorage.getItem("user_id");
  const loginUserId: string = loginUserIdStr ? loginUserIdStr : "";
  const [carList, setCarList] = useState<ResponseCar[]>([]);
  const [carDate, setCarDate] = useState<string>(
    carLogData.car_date.split("T")[0]
  );
  const [purpose, setPurpose] = useState<string>(carLogData.purpose);
  const [purposeDetail, setPurposeDetail] = useState<string>(
    carLogData.purpose_detail
  );
  const [carId, setCarId] = useState<number>(carLogData.car.car_id);
  const [locStart, setLocStart] = useState<string>(carLogData.loc_start);
  const [locEnd, setLocEnd] = useState<string>(carLogData.loc_end);
  const [accStart, setAccStart] = useState<number>(carLogData.acc_start);
  const [accEnd, setAccEnd] = useState<number>(carLogData.acc_end);
  const [note, setNote] = useState<string>(carLogData.carlog_note);
  const [distance, setDistance] = useState<number>(carLogData.drive_distance);
  const [workDis, setWorkDis] = useState<number>(
    carLogData.car.work_drive_distance
  );
  const [isApproval, setIsApproval] = useState<boolean>(carLogData.is_approval);
  const [approvalStatus, setApprovalStatus] = useState(
    carLogData.is_approval ? "COMPLETE" : "NOTPROGRESS"
  );
  const [checkAdmin, setCheckAdmin] = useRecoilState(checkAdminState);
  const [openDialog, setOpenDialog] = useState(false);
  const handleError = useErrorHandler();
  const loginUserInfo = useRecoilValue(loginUserInfoState);
  const setIsEdit = useSetRecoilState(isEditedState);

  useEffect(() => {
    async function fetchCars() {
      try {
        const carsData = await getCarList(false);
        setCarList(carsData.data.content);
      } catch (error) {
        handleError(error);
      }
    }
    fetchCars();
  }, []);

  useEffect(() => {
    async function fetchCarInfo() {
      try {
        if (carId === null || carId !== -1) {
          return;
        }
        const carData = await getCar(carId);
        console.log(carData, "cardata");
        setWorkDis(carData.data.work_drive_distance);
      } catch (error) {
        handleError(error);
      }
    }
    fetchCarInfo();
  }, [carId, handleError]);

  useEffect(() => {
    const isAdmin = localStorage.getItem("is_admin") || "";
    console.log(isAdmin);
    if (isAdmin !== null) {
      setCheckAdmin(isAdmin); // Only set if not null
    } else {
      setCheckAdmin("false"); // Default or fallback value
    }
  }, []);

  const handleApprovalChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    // 새로운 승인 상태를 결정합니다.
    const newApprovalStatus = event.target.value;
    setApprovalStatus(newApprovalStatus);
    const newApproval = newApprovalStatus === "COMPLETE";
    console.log(newApprovalStatus, newApproval, "newApprovalStatus");
    try {
      const data = {
        car_id: carId,
        car_date: carDate,
        purpose: purpose,
        purpose_detail: purposeDetail,
        user_id: loginUserId,
        user_name: carLogData.user.user_name,
        loc_start: locStart,
        acc_start: accStart,
        loc_end: locEnd,
        acc_end: accEnd,
        drive_distance: distance,
        work_drive_distance:
          purpose === "BUSINESS"
            ? workDis - carLogData.car.work_drive_distance + distance
            : workDis,
        carlog_note: note,
        is_approval: newApproval,
      };
      // PATCH 요청을 보냅니다.
      const response = await patchCarLogApproval(carLogData.carlog_id, {
        ...data,
        is_approval: newApproval,
      });
      setIsApproval(newApproval);
      console.log(response, "carlog updated");
    } catch (error: any) {
      // 오류 처리
      console.error("승인 상태 변경 중 오류 발생:", error);
      alert(`승인 상태 변경 실패: ${error.message || "서버 오류"}`);
    }
  };

  // 업무일지 날짜
  const handleCarLogDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setCarDate(value);
  };

  // 주행거리 자동계산
  const handleAccEnd = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setAccEnd(Number(value));
    setDistance(Number(value) - accStart);
  };

  // 도착거리 자동계산
  const handleDistance = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDistance(Number(value));
    setAccEnd(accStart + Number(value));
  };

  // 취소버튼
  const onCancel = () => {
    setOpenDialog(true);
  };

  // 저장버튼
  const handleSubmit = async () => {
    console.log("수정 저장");
    if (
      carId === null ||
      purpose === "" ||
      locStart === "" ||
      locEnd === "" ||
      distance === 0
    ) {
      alert("필수값이 입력되지 않았습니다.");
    } else if (distance < 0) {
      alert("주행거리가 옳지 않습니다. 주행거리는 0 이상 값입니다.");
    } else {
      try {
        const data = {
          car_id: carId,
          car_date: carDate,
          purpose: purpose,
          purpose_detail: purposeDetail,
          // dep_id: ,
          // dep_name: "",
          user_id: loginUserId,
          user_name: carLogData.user.user_name,
          loc_start: locStart,
          acc_start: accStart,
          loc_end: locEnd,
          acc_end: accEnd,
          drive_distance: distance,
          work_drive_distance:
            purpose === "BUSINESS"
              ? workDis - carLogData.car.work_drive_distance + distance
              : workDis,
          carlog_note: note,
          is_approval: isApproval,
        };
        const updateCarlog = await updateCarLog(carLogData.carlog_id, data);
        setCarLogData(updateCarlog);
        // setWorkList((prev) => [...prev, updateCarlog]);
        console.log(updateCarlog, "수정 데이터");
        // successSave();
        setIsEdit(false);
      } catch (error) {
        handleError(error); // 오류 처리 함수 호출
        console.log("edited worklog not saved");
      }
    }
  };

  return (
    <div className="write_list_wrapper">
      <div className="work_option">
        <div className="write_date">
          <div className="div_icon" style={{ marginRight: "15px" }}>
            <CalendarIcon style={{ marginRight: "4px" }} />
            일시
          </div>
          <input
            type="date"
            name="car_date"
            value={carDate}
            style={{ marginLeft: "15px", width: "fit-content" }}
            onChange={handleCarLogDate}
          />
        </div>
        <div>
          <div className="div_icon">
            <CarIcon style={{ marginRight: "4px" }} />
            차량
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <select
            value={carId}
            onChange={({ target }) => setCarId(parseInt(target.value))}
          >
            <option key="-1">선택하세요.</option>
            {carList.map((car) => (
              <option key={car.car_id} value={car.car_id}>
                ({car.car_model}) {car.car_num}
              </option>
            ))}
          </select>
        </div>
        <div>
          <div className="div_icon">
            <PurposeIcon style={{ marginRight: "4px" }} />
            사용목적
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <select
            value={purpose}
            onChange={({ target }) => setPurpose(target.value)}
          >
            <option value="">선택하세요.</option>
            <option value="BUSINESS">업무용</option>
            <option value="NON_BUSINESS">비업무용</option>
            <option value="OTHER">기타</option>
          </select>
        </div>
        <div>
          <div className="div_icon"></div>
          <input
            disabled={purpose === "OTHER" ? false : true}
            value={purposeDetail}
            placeholder="기타 입력"
            onChange={({ target }) => setPurposeDetail(target.value)}
          />
        </div>
        <div>
          <div className="div_icon">
            <UserIcon style={{ marginRight: "4px" }} />
            운전자
          </div>
          <span>{loginUsername}</span>
        </div>
        <div>
          <div className="div_icon">
            <StartIcon style={{ marginRight: "4px" }} />
            출발지
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <input
            type="text"
            value={locStart}
            onChange={({ target }) => setLocStart(target.value)}
          />
        </div>
        <div>
          <div className="div_icon">
            <EndIcon style={{ marginRight: "4px" }} />
            도착지
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <input
            type="text"
            value={locEnd}
            onChange={({ target }) => setLocEnd(target.value)}
            placeholder="도착지를 입력해주세요"
          />
        </div>
        <div>
          <div className="div_icon" style={{ color: "rgb(0,0,0,0.6)" }}>
            출발 전 주행거리
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <input
            type="number"
            value={accStart}
            onChange={({ target }) => setAccStart(parseInt(target.value))}
            onWheel={(e) => e.currentTarget.blur()}
            onFocus={(e) => {
              e.target.select();
            }}
          />
        </div>
        <div>
          <div className="div_icon" style={{ color: "rgb(0,0,0,0.6)" }}>
            도착 후 주행거리
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <input
            type="number"
            value={accEnd}
            placeholder="0"
            onChange={handleAccEnd}
            onWheel={(e) => e.currentTarget.blur()}
            onFocus={(e) => {
              e.target.select();
            }}
          />
        </div>
        <div>
          <div className="div_icon">
            <DistanceIcon style={{ marginRight: "4px" }} />
            주행거리
          </div>
          <input
            type="number"
            value={distance}
            onChange={handleDistance}
            onWheel={(e) => e.currentTarget.blur()}
            onFocus={(e) => {
              e.target.select();
            }}
          />
        </div>
        <div className="textarea_wrapper">
          <div className="div_icon">
            <NoteIcon style={{ marginRight: "4px" }} /> 비고
          </div>
          <textarea
            value={note}
            style={{ color: "#000", marginTop: "5px" }}
            name="cons_note"
            onChange={({ target }) => setNote(target.value)}
            disabled={false}
          />
        </div>
        {loginUserInfo.is_admin && (
          <div
            className="approval_option"
            style={{
              display: "flex",
              lineHeight: "34px",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "12px",
            }}
          >
            <div className="div_icon">
              <Approval style={{ marginRight: "4px" }} />
              승인여부
            </div>
            <select
              value={approvalStatus}
              onChange={handleApprovalChange}
              style={{ paddingLeft: "0 5px", width: "196px" }}
            >
              <option value="NOTPROGRESS">미승인</option>
              <option value="COMPLETE">승인</option>
            </select>
          </div>
        )}
      </div>

      <div className="write_bottom">
        <>
          <DialogText
            open={openDialog}
            content="변경 사항이 저장되지 않을 수 있습니다. 계속하시겠습니까?"
            onCancel={() => setOpenDialog(false)}
            onClose={() => setIsEdit(false)}
          />
          <CancelButton onClickHandler={onCancel} />
          <SaveButton onClickHandler={handleSubmit} />
        </>
      </div>
    </div>
  );
};
export default EditCarLogForm;
