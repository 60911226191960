import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import { ReactComponent as WriteIcon } from "assets/write.svg";
import "styles/WriteStyle.css";
import { getCarLogs, patchCarLogApproval, ResponseCarLog } from "api/carLog";
import { User } from "api/user";
import { ResponseCar, getCarList } from "api/car";
import {
  dateFilterParams,
  formatDate,
  formatPurpose,
  kmFormatter,
  textFilterParams,
} from "components/Common";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import SelectUserInfo from "components/SelectUserInfo";
import {
  autoSelectCompIdState,
  filterUserState,
  isEditedState,
  loginUserInfoState,
  searchRangeDateState,
} from "recoil/stateStore";
import Grid from "components/Grid";
import ViewCarLogDrawer from "components/drawer/ViewCarLogDrawer";
import CreateWorkDrawer from "components/drawer/CreateDrawer";
import DialogText from "components/DialogText";
import { useErrorHandler } from "components/ErrorHandler";
import { ValueFormatterParams } from "ag-grid-community";

interface RowData {
  carlog_id: number;
  car_date: Date;
  purpose: string;
  purpose_detail: string;
  car: ResponseCar;
  user: User;
  loc_start: string;
  acc_start: number;
  loc_end: string;
  acc_end: number;
  drive_distance: number;
  carlog_note: string;
  is_approval: boolean;
}

export default function CarLog() {
  const [carLogDrawerOpen, setCarLogDrawerOpen] = useState<boolean>(false);
  const [selectedCarLogId, setSelectedCarLogId] = useState<number>(-1);
  const [userDrawerOpen, setUserDrawerOpen] = useState<string>("");
  const setAutoSelectCompId = useSetRecoilState(autoSelectCompIdState);
  const [rowData, setRowData] = useState<RowData[]>([]);
  const filterUser = useRecoilValue(filterUserState);
  const loginUserInfo = useRecoilValue(loginUserInfoState);
  const handleError = useErrorHandler();
  const [carList, setCarList] = useState<ResponseCar[]>([]);
  const [carId, setCarId] = useState<number | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [isEdit, setIsEdit] = useRecoilState(isEditedState);
  const rangeDate = useRecoilValue(searchRangeDateState);
  const [updatedData, setUpdatedData] = useState<ResponseCarLog>();
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [deletedId, setDeletedId] = useState<number>(-1);
  const [viewSize, setViewSize] = useState<number>(10);

  useEffect(() => {
    async function fetchCars() {
      try {
        const carsData = await getCarList(false);
        setCarList(carsData.data.content);
        console.log(carsData.data.content, "carsData.content");
      } catch (error) {
        handleError(error);
      }
    }
    fetchCars();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callCarLogs = async () => {
    try {
      const data = await getCarLogs(
        filterUser,
        carId,
        rangeDate[0],
        rangeDate[1]
      );
      console.log(data, data.data, "data");
      const mappedData = data.data.content.map((item: ResponseCarLog) => ({
        carlog_id: item.carlog_id,
        carlog_date: formatDate(item.car_date),
        car: item.car.car_num + " (" + item.car.car_model + ")",
        purpose: formatPurpose(item.purpose),
        user: item.user.user_name,
        loc_start: item.loc_start,
        acc_start: item.acc_start,
        loc_end: item.loc_end,
        acc_end: item.acc_end,
        drive_distance: item.drive_distance,
        work_drive_distance: item.car.work_drive_distance,
        carlog_note: item.carlog_note,
        is_approval: item.is_approval,
      }));
      setRowData(mappedData);
      setIsSaved(false);
      console.log(data.data.content, "carlogs");
    } catch (error) {
      console.error("Error fetching work logs:", error);
    }
  };

  useEffect(() => {
    callCarLogs(); // 컴포넌트가 마운트될 때 데이터 불러오기
  }, [filterUser, isSaved, carId, rangeDate]);

  const baseColumnDefs = [
    // {
    //   field: "carlog_id",
    //   headerName: "ID",
    //   filter: "agDateColumnFilter",
    //   filterParams: textFilterParams,
    //   flex: 0.5,
    // },
    {
      headerName: "일시",
      field: "carlog_date",
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      flex: 1.1,
    },
    {
      headerName: "차량",
      field: "car",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 1.2,
    },
    // {
    //   headerName: "사용목적",
    //   field: "purpose",
    //   filter: "agTextColumnFilter",
    //   filterParams: textFilterParams,
    //   flex: 0.8,
    // },
    {
      headerName: "운전자",
      field: "user",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 0.7,
    },
    {
      headerName: "출발지",
      field: "loc_start",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 0.8,
    },
    {
      headerName: "출발 전 주행거리",
      field: "acc_start",
      flex: 1,
      valueFormatter: kmFormatter,
    },
    {
      headerName: "도착지",
      field: "loc_end",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 0.8,
    },
    {
      headerName: "도착 후 주행거리",
      field: "acc_end",
      flex: 1,
      valueFormatter: kmFormatter,
    },
    {
      headerName: "주행거리",
      field: "drive_distance",
      flex: 0.9,
      valueFormatter: kmFormatter,
    },
    // {
    //   headerName: "업무용 주행거리 누계",
    //   field: "work_drive_distance",
    //   flex: 0.9,
    //   // valueFormatter: kmFormatter,
    // },
    {
      headerName: "비고",
      field: "carlog_note",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 0.8,
    },
  ];

  const userManagementColumnDefs = [
    {
      headerName: "승인여부",
      field: "is_approval",
      flex: 0.6,
      filter: "agBooleanColumnFilter",
      cellRenderer: (params: ValueFormatterParams) => {
        const handleApprovalChange = async (
          event: React.ChangeEvent<HTMLInputElement>
        ) => {
          const updatedCarLog = { ...params.data, is_approval: !params.value }; // 승인 상태 반전
          try {
            await patchCarLogApproval(params.data.carlog_id, updatedCarLog);

            callCarLogs();
            // 성공적으로 업데이트된 후 추가 로직 (예: 알림 표시)
          } catch (error) {
            console.error("승인 상태 업데이트 실패:", error);
          }
        };

        return (
          <input
            type="checkbox"
            className="approval_checkbox"
            value={params.value}
            checked={params.value}
            onChange={handleApprovalChange}
            style={{ width: "20px", height: "20px" }}
          />
        );
      },
      cellStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  ];

  const columnDefs = [
    ...baseColumnDefs,
    ...(loginUserInfo.is_admin ? userManagementColumnDefs : []),
  ];

  const handleSetOpen = (status: boolean, type: string) => {
    if (type === "newCarLog") {
      setAutoSelectCompId(null);
      setCarLogDrawerOpen(true);
      setUserDrawerOpen("create");
    }
    if (type === "carLog") {
      setCarLogDrawerOpen(true);
      setUserDrawerOpen("view");
    }
  };

  const selectedId = (type: string, id: number) => {
    setSelectedCarLogId(id);
  };

  const closeDrawer = () => {
    if (userDrawerOpen === "view" && !isEdit) {
      closeDialog();
    } else {
      setOpenDialog(true);
    }
  };

  const handleSubmitSuccess = () => {
    setIsSaved(true);
    closeDialog();
  };

  const closeDialog = () => {
    setCarLogDrawerOpen(false);
    setOpenDialog(false);
    setIsEdit(false);
  };

  const pageSizeChange = (size: number) => {
    setViewSize(size);
  };

  return (
    <>
      <div className="mainTop_wrapper">
        <div className="title">
          <div className="menu">Work management</div>
          <div className="sub_menu">
            <div className="sub_menu__title">
              <h1>차량운행일지</h1>
              <SelectUserInfo selectUserId={filterUser} />
            </div>
            <div>
              <button
                className="write_button"
                onClick={() => handleSetOpen(true, "newCarLog")}
              >
                <WriteIcon />
                차량운행일지 작성
              </button>
            </div>
          </div>
        </div>

        <Card id={"main_card"}>
          <Grid
            columnDefs={columnDefs}
            rowData={rowData}
            type="carLog"
            setOpen={handleSetOpen}
            setId={selectedId}
            carList={carList}
            setCarId={setCarId}
            newData={updatedData}
            deletedId={deletedId}
            setRowData={setRowData}
          />
          <ViewCarLogDrawer
            open={userDrawerOpen === "view" && carLogDrawerOpen}
            onClose={closeDrawer}
            carLogId={selectedCarLogId}
            setUpdatedData={setUpdatedData}
            setDeletedId={setDeletedId}
          />
          <CreateWorkDrawer
            open={userDrawerOpen === "create" && carLogDrawerOpen}
            onClose={closeDrawer}
            onSubmitSuccess={handleSubmitSuccess}
            type={"CarLog"}
          />
          <DialogText
            open={openDialog}
            content="변경 사항이 저장되지 않을 수 있습니다. 계속하시겠습니까?"
            onCancel={() => setOpenDialog(false)}
            onClose={closeDialog}
          />
        </Card>
      </div>
    </>
  );
}
