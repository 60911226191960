import { useNavigate } from "react-router-dom";
import { useResetRecoilState, useRecoilState, useSetRecoilState } from "recoil";
import {
  selectedMenuState,
  userState,
  checkSaveIdState,
  isDrawerOpenState,
  searchOptionState,
  isDetailOpenState,
} from "recoil/stateStore";

export const useErrorHandler = () => {
  const navigate = useNavigate();
  const resetSelectMenu = useResetRecoilState(selectedMenuState);
  const resetUserInfo = useResetRecoilState(userState);
  const resetSearchOption = useResetRecoilState(searchOptionState);
  const [checkSaveId, setCheckSaveId] = useRecoilState(checkSaveIdState);
  const setIsDrawerOpen = useSetRecoilState(isDrawerOpenState);
  const setIsDetail = useSetRecoilState(isDetailOpenState);

  const handleError = (error: any) => {
    let count = 0;
    console.log("핸들러", error);

    if (
      error.status === 403 ||
      error.message ===
      "TypeError: Cannot read properties of undefined (reading 'error')"
    ) {
      count += 1;
      alert("유효하지 않은 토큰입니다. \n재로그인을 시도합니다.");
      navigate("/");

      const saveIdValue = window.localStorage.getItem("saveId");
      const serverIP = window.localStorage.getItem("serverIP");
      window.localStorage.clear();
      if (checkSaveId && saveIdValue) {
        window.localStorage.setItem("saveId", saveIdValue);
      }
      if (serverIP) {
        window.localStorage.setItem("serverIP", serverIP);
      }
      resetSelectMenu();
      resetSearchOption();
      resetUserInfo();
      setCheckSaveId(false);
      setIsDetail(false);
      setIsDrawerOpen(false);
      console.log(error);

      if (count > 1) {
        navigate("/");
        alert("유효하지 않은 토큰입니다. \n재로그인을 시도합니다.");
      }
    } else if (error.message === undefined || error.message === null) {
      alert("오류 발생: 알 수 없는 오류입니다."); // 오류 메시지가 없을 때 기본 경고
      console.log("오류 메시지가 없습니다.", error);
    } else if (error.status === 401) {
      alert(`인증 오류: ${error.message}`);
    } else if (error.status === 403) {
      alert(`인증 오류: ${error.message}`);
    } else if (error.status === 404) {
      alert(`찾을 수 없음: ${error.message}`);
    } else if (error.status === 409) {
      alert(`충돌 오류: ${error.message}`);
    } else if (error.status === 500) {
      alert("서버 오류가 발생했습니다.");
    } else {
      alert(`${error.message}`); // 기타 오류 처리
    }
  };
  return handleError;
};
