import { ReactComponent as DeleteIcon } from "assets/delete.svg";
import { ReactComponent as EditIcon } from "assets/edit.svg";
import { ReactComponent as SaveIcon } from "assets/save.svg";
import { ReactComponent as CancelIcon } from "assets/cancel.svg";
import "styles/WriteStyle.css";
import { ValueFormatterParams } from "ag-grid-community";
import { ITextFilterParams } from "ag-grid-enterprise";
import { ReactComponent as CompleteIcon } from "assets/writeIcon/complete.svg";
import { ReactComponent as InProgressIcon } from "assets/writeIcon/inprogress.svg";
import { ReactComponent as ExpectedIcon } from "assets/writeIcon/expected.svg";
import { ReactComponent as NotProgressIcon } from "assets/writeIcon/notprogress.svg";

export interface SearchParams {
  search_type: number;
  keyword: string;
  start_date: string;
  end_date: string;
  page: number;
  pageSize: number | null;
  sort: number;
  user_id?: string | null;
  car_id?: number | null;
  comp_id?: number | null;
  is_personal?: number | null;
  work_id?: number | null;
  progress?: string | "";
}

export const formatDate = (date: Date | string): string => {
  if (date !== undefined) {
    const transformDate = new Date(date); // 문자열을 Date 객체로 변환
    const year = transformDate.getFullYear();
    const month = ("0" + (transformDate.getMonth() + 1)).slice(-2);
    const day = ("0" + transformDate.getDate()).slice(-2);
    const dayOfWeek = ["일", "월", "화", "수", "목", "금", "토"][
      transformDate.getDay()
    ];
    return `${year}-${month}-${day} (${dayOfWeek})`;
  } else {
    return "1999-99-99(월)";
  }
};
export const formatDate_ = (date: Date | string): string => {
  if (typeof date === "string") {
    date = new Date(date); // 문자열을 Date 객체로 변환
  }
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const hour = ("0" + date.getHours()).slice(-2);
  const minute = ("0" + date.getMinutes()).slice(-2);
  // const dayOfWeek = ["일", "월", "화", "수", "목", "금", "토"][date.getDay()];
  return `${year}-${month}-${day} ${hour}:${minute}`;
};

export const emptyData = (data: string | number) => {
  if (
    data === "" ||
    data === "-1" ||
    data === null ||
    data === undefined ||
    data === "null null"
  ) {
    return "-";
  } else {
    return String(data);
  }
};

export const truncateString = (str: string, maxLength: number) => {
  if (str === null || str === undefined) {
    return "";
  }
  return str.length > maxLength ? str.substring(0, maxLength) + "..." : str;
};

export const formatPhone = (data: string) => {
  if (data === "-") {
    return "-";
  }
  if (data === "" || data === undefined) {
    return "-";
  }

  const phoneNumber = data.replace(/[^0-9]/g, ""); // 숫자만 추출

  let formattedNumber = "";
  if (phoneNumber.startsWith("010")) {
    // 첫 번째 번호가 010인 경우
    if (phoneNumber.length === 11) {
      // 11자리인 경우
      formattedNumber = `${phoneNumber.substring(0, 3)}-${phoneNumber.substring(
        3,
        7
      )}-${phoneNumber.substring(7, 11)}`;
    } else {
      // 10자리인 경우
      formattedNumber = `${phoneNumber.substring(0, 3)}-${phoneNumber.substring(
        3,
        6
      )}-${phoneNumber.substring(6, 10)}`;
    }
  } else {
    // 그 외의 경우
    formattedNumber = `${phoneNumber.substring(0, 3)}-${phoneNumber.substring(
      3,
      7
    )}-${phoneNumber.substring(7, 12)}`;
  }

  return formattedNumber;
};

export const formatPrice = (price: number) => {
  const priceStr = String(price);
  const reversed = priceStr.split("").reverse().join("");
  const withCommas = reversed.replace(/(\d{3})(?=\d)/g, "$1,");
  return withCommas.split("").reverse().join("") + "원";
};

export const successSave = () => {
  alert("성공적으로 저장되었습니다.");
};
export const successEdit = () => {
  alert("성공적으로 수정되었습니다.");
};
export const successDelete = () => {
  alert("성공적으로 삭제되었습니다.");
};
export const successDisabled = () => {
  alert("성공적으로 비활성화되었습니다.");
};

export const errorDisabled = () => {
  alert("비활성화에 실패하였습니다.");
};
export const errorDelete = () => {
  alert("삭제를 실패하였습니다.");
};
export const errorSave = () => {
  alert("등록을 실패하였습니다.");
};

interface ButtonProps {
  onClickHandler: () => void;
}

export const SaveButton: React.FC<ButtonProps> = ({ onClickHandler }) => {
  return (
    <button
      onClick={onClickHandler}
      style={{ backgroundColor: "#023373", color: "#fff", border: "none" }}
    >
      <div className="div_button_icon">
        <SaveIcon />
        저장
      </div>
    </button>
  );
};

export const CancelButton: React.FC<ButtonProps> = ({ onClickHandler }) => {
  return (
    <button
      onClick={onClickHandler}
      style={{ backgroundColor: "#fff", cursor: "pointer" }}
    >
      <div className="div_button_icon">
        <CancelIcon />
        취소
      </div>
    </button>
  );
};

export const DeleteButton: React.FC<ButtonProps> = ({ onClickHandler }) => {
  return (
    <button
      onClick={onClickHandler}
      style={{ backgroundColor: "#fff", color: "tomato" }}
    >
      <div className="div_button_icon">
        <DeleteIcon />
        삭제
      </div>
    </button>
  );
};

export const EditButton: React.FC<ButtonProps> = ({ onClickHandler }) => {
  return (
    <button
      onClick={onClickHandler}
      style={{ backgroundColor: "#023373", color: "#fff", border: "none" }}
    >
      <div className="div_button_icon">
        <EditIcon />
        수정
      </div>
    </button>
  );
};

export const getDayOfWeek = (dateString: Date) => {
  const date = new Date(dateString);
  const dayNames = ["일", "월", "화", "수", "목", "금", "토"];
  return dayNames[date.getDay()];
};

const regionMapping: { [key: string]: string } = {
  경기도: "경기",
  경상남도: "경남",
  경상북도: "경북",
  서울특별시: "서울",
  부산광역시: "부산",
  대구광역시: "대구",
  인천광역시: "인천",
  광주광역시: "광주",
  대전광역시: "대전",
  울산광역시: "울산",
  세종특별자치시: "세종",
  충청남도: "충남",
  충청북도: "충북",
  전라남도: "전남",
  전라북도: "전북",
  제주특별자치도: "제주",
};

export const convertRegionName = (regionName: string) => {
  if (!regionName) {
    return regionName;
  }
  const keys = Object.keys(regionMapping);
  for (let i = 0; i < keys.length; i++) {
    if (regionName.startsWith(keys[i])) {
      const newRegionName = regionName.replace(keys[i], regionMapping[keys[i]]);
      const parts = newRegionName.split(" ");
      return parts.length > 1 ? parts[1] : newRegionName;
    }
  }
  return regionName;
};

export const removeSuffix = (regionName: string): string => {
  if (!regionName) return ""; // null 또는 undefined 확인
  return regionName.replace(/(시|구|군)$/, "");
};

export const handleWorkOptionText = (data: string) => {
  if (data === "TECHNICAL_EXTERNAL") {
    return "현장대응";
  }
  if (data === "TECHNICAL_INTERNAL") {
    return "전화대응";
  }
  if (data === "INCOMPANY") {
    return "사내업무";
  }
  if (data === "DELIVERY") {
    return "납품";
  }
  if (data === "MEETING") {
    return "미팅";
  }
  if (data === "EDUCATION") {
    return "교육";
  }
  if (data === "OTHER") {
    return "기타";
  }
};

export const handleMeetOptionText = (data: string) => {
  if (data === "NONE") {
    return "-";
  }
  if (data === "INTERNAL") {
    return "내근";
  }
  if (data === "CALL") {
    return "전화";
  }
  if (data === "INPERSON") {
    return "대면";
  }
  if (data === "OTHER") {
    return "기타";
  }
};

export const formatProgress = (str: string) => {
  if (str === "COMPLETE") {
    return "완료";
  }
  if (str === "INPROGRESS") {
    return "진행중";
  }
  if (str === "EXPECTED" || str === "NONE") {
    return "예정";
  }
  if (str === "NOTPROGRESS" || str === "DROP") {
    return "미진행";
  }
};

export const kmFormatter = (params: ValueFormatterParams) => {
  return formatNumber(params.value) + " km";
};

export const formatNumber = (number: number) => {
  if (number === undefined || number === null) {
    return; // 또는 적절한 기본값을 반환
  }
  return Math.floor(number).toLocaleString();
};

/* Grid 공통 */
export const dateFilterParams = {
  comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
    const cellDate = new Date(cellValue);

    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    } else {
      return 0;
    }
  },
  maxNumConditions: 1,
};

export const textFilterParams: ITextFilterParams = {
  filterOptions: ["contains", "notContains", "equals"],
  maxNumConditions: 1,
};

export const formatProgressWithIcon = (str: string): React.ReactNode => {
  switch (str) {
    case "COMPLETE":
      return (
        <>
          완료 <CompleteIcon />
        </>
      );
    case "INPROGRESS":
      return (
        <>
          진행중 <InProgressIcon />
        </>
      );
    case "NONE":
    case "EXPECTED":
      return (
        <>
          예정 <ExpectedIcon />
        </>
      );
    case "NOTPROGRESS":
      return (
        <>
          미진행 <NotProgressIcon />
        </>
      );
    default:
      return str;
  }
};

export const formatPurpose = (data: string) => {
  let returnText = "";
  switch (data) {
    case "BUSINESS":
      returnText = "업무용";
      break;
    case "NON_BUSINESS":
      returnText = "비업무용";
      break;
    case "OTHER":
      returnText = "기타";
      break;
  }
  return returnText;
};

// 상반기 매출을 계산하는 함수
export const calculateFirstHalf = (data: any) => {
  return (data.first_quarter || 0) + (data.second_quarter || 0);
};

// 하반기 매출을 계산하는 함수
export const calculateSecondHalf = (data: any) => {
  return (data.third_quarter || 0) + (data.fourth_quarter || 0);
};

// 전체 매출을 계산하는 함수
export const calculateTotal = (data: any) => {
  const first = data.first_half ? data.first_half : calculateFirstHalf(data);
  const second = data.second_half
    ? data.second_half
    : calculateSecondHalf(data);
  return first + second;
};
