import { client, getErrorMessage } from "./client";
import { User } from "./user";
import { Company } from "./company";
import { Member } from "./member";

export interface ResponseYearPlan {
  aplan_id?: number;
  user?: User;
  company?: Company;
  aplan_year: string;
  first_quarter: number;
  second_quarter: number;
  third_quarter: number;
  fourth_quarter: number;
  first_half: number;
  second_half: number;
  comp_progress: string;
  sales_plan: string;
  comp_members: Member[];
  aplan_note: string;
  member_ids: number[];
  total?: number;
}

// 응답: 특정 년도의 연간계획서 조회
export interface YearPlanList {
  total_count: number;
  content: ResponseYearPlan[];
}

//연간계획서 목록
export const getAnnualPlans = async (
  user_id: string,
  year: string,
  start_date: string,
  end_date: string
) => {
  try {
    // const token = localStorage.getItem("token");
    const response = await client.get("/annual_plans", {
      headers: {
        // Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 포함
      },
      params: { user_id, year, start_date, end_date },
    });
    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

// 연간계획서 상세보기
export const getAnnualPlan = async (aplan_id: number) => {
  try {
    // const token = localStorage.getItem("token");
    const response = await client.get(`/annual_plan/${aplan_id}`, {
      headers: {
        // Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 포함
      },
    });
    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

export const addAnnualPlan = async (
  year: string,
  writeYearPlan: ResponseYearPlan
) => {
  try {
    const token = localStorage.getItem("token");
    const response = await client.post(
      `/annual_plans?year=${year}`,
      writeYearPlan,
      {
        headers: {
          Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 포함
        },
      }
    );
    return response;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

export const updateAnnualPlan = async (
  aplan_id: number,
  writeYearPlan: ResponseYearPlan
) => {
  try {
    const token = localStorage.getItem("token");
    const response = await client.patch(
      `/annual_plan/${aplan_id}`,
      writeYearPlan,
      {
        headers: {
          Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 포함
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

export const deleteAnnualPlan = async (aplan_id: number) => {
  try {
    const token = localStorage.getItem("token");
    const response = await client.delete(`/annual_plan/${aplan_id}`, {
      headers: {
        Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 포함
      },
    });

    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};
