import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import WorkLog from "pages/works/WorkLog";
import MainPage from "pages/MainPage";
import WorkStatus from "pages/works/WorkStatus";
import Counseling from "pages/works/Counseling";
import CarLog from "pages/works/CarLog";
import MonthPlan from "pages/works/MonthPlan";
import YearPlan from "pages/works/YearPlan";
import CarInfo from "pages/info/CarInfo";
import UserInfo from "pages/info/UserInfo";
import UserSetting from "pages/Settings/UserSetting";
import LoginPage from "pages/LoginPage";
import CompanyDetail from "pages/works/CompanyDetail";
import SalesSummary from "pages/works/SalesSummary";

export default function Routers() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<MainPage />}>
            <Route index element={<WorkLog />} />
            <Route path="companies" element={<WorkStatus />} />
            <Route path="companies/:companyName" element={<CompanyDetail />} />
            <Route path="counseling" element={<Counseling />} />
            <Route path="carlog" element={<CarLog />} />
            <Route path="monthplan" element={<MonthPlan />} />
            <Route path="yearplan" element={<YearPlan />} />
            <Route path="salesSummary" element={<SalesSummary />} />
            <Route path="carinfo" element={<CarInfo />} />
            <Route path="userinfo" element={<UserInfo />} />
            <Route path="carmanage" element={<CarInfo />} />
            <Route path="usermanage" element={<UserInfo />} />
            <Route path="usersetting" element={<UserSetting />} />
          </Route>
          <Route path="/login" element={<LoginPage />} />
        </Routes>
      </Router>
    </>
  );
}
