import { useEffect, useState } from "react";
import { CancelButton, SaveButton } from "components/Common";
import { useRecoilValue } from "recoil";
import { autoSelectCompIdState } from "recoil/stateStore";
import { ReactComponent as CalendarIcon } from "assets/writeIcon/calendar.svg";
import { ReactComponent as UserIcon } from "assets/writeIcon/user.svg";
import { ReactComponent as WorkOptionIcon } from "assets/writeIcon/work_option.svg";
import { ReactComponent as MeetOptionIcon } from "assets/writeIcon/part.svg";
import { ReactComponent as CompanyIcon } from "assets/writeIcon/company.svg";
import { ReactComponent as PartIcon } from "assets/writeIcon/part.svg";
import { ReactComponent as MemberIcon } from "assets/writeIcon/member.svg";
import { ReactComponent as PlanIcon } from "assets/writeIcon/plan.svg";
import { ReactComponent as ResultIcon } from "assets/writeIcon/content.svg";
import { ReactComponent as ProgressIcon } from "assets/writeIcon/progress.svg";
import { useErrorHandler } from "components/ErrorHandler";
import AutoCompleteCompany from "components/AutoCompleteCompany";
import { Member, getCompanyMember, getCompanyMembers } from "api/member";
import { SelectInput } from "components/SelectInput";
import { postWorkLog } from "api/workLog";
import { getCompany } from "api/company";

interface WorkLogWriteProps {
  onClose: (event?: any) => void;
  onSubmitSuccess: (e?: any) => void;
}

const WorkLogWrite = ({ onClose, onSubmitSuccess }: WorkLogWriteProps) => {
  const today = new Date();
  const loginUsername = localStorage.getItem("user_name");
  const loginUserIdStr = localStorage.getItem("user_id");
  const loginUserId: string = loginUserIdStr ? loginUserIdStr : "";
  const [members, setMembers] = useState<Member[]>([]);
  const [memberPart, setMemberPart] = useState<string>("-");
  const [regionItem, setRegionItem] = useState<string>("고객사 위치");
  const handleError = useErrorHandler();
  const autoSelectCompId = useRecoilValue(autoSelectCompIdState);
  const [date, setDate] = useState<string>(today.toISOString().split("T")[0]);
  const [compId, setCompId] = useState<number | null>(-1);
  const [memberId, setMemberId] = useState<number | null>(null);
  const [workOption, setWorkOption] = useState<string>("");
  const [meeting, setMeetingOption] = useState<string>("NONE");
  const [content, setContent] = useState<string>("");
  const [plan, setPlan] = useState<string>("");
  const [progress, setProgress] = useState<string>("EXPECTED");
  const [time, setTime] = useState<string>("12:00");

  useEffect(() => {
    setCompId(autoSelectCompId);
    if (autoSelectCompId) {
      const callMembers = async () => {
        try {
          const response = await getCompanyMembers(autoSelectCompId);
          const memberData = response.data.content;
          setMembers(memberData.member_list);
          const compData = await getCompany(autoSelectCompId);
          setRegionItem(
            compData.data.comp_sido_nm + " " + compData.data.comp_sgg_nm
          );
        } catch (error) {
          console.log("no members");
        }
      };
      callMembers();
    }
  }, [autoSelectCompId]);

  // 취소버튼
  const onCancel = () => {
    onClose();
  };

  // 저장버튼
  const handleSubmit = async () => {
    console.log("업무일지 등록");

    // 필수 항목 체크
    if (workOption === "" || compId === null || compId === -1) {
      alert("모든 필수 항목을 채워주세요."); // 필수 항목이 누락된 경우 경고
      return;
    }

    try {
      const [hours, minutes] = time.split(":").map(Number);
      const localDate = new Date(date);
      localDate.setHours(hours + 9, minutes);

      const data = {
        work_date: localDate,
        user_id: loginUserId,
        comp_id: compId,
        member_id: memberId,
        work_option: workOption,
        work_content: content,
        meeting: meeting,
        plan: plan,
        progress: progress,
      };

      await postWorkLog(data);
      onSubmitSuccess();
    } catch (error) {
      handleError(error); // 오류 처리 함수 호출
    }
  };

  // 담당자 선택 - 부서값 자동 채워짐
  // 고객사 담당자에 대한 부서값 조회
  const handleSelectMember = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedMemberId = parseInt(event.target.value);
    setMemberId(selectedMemberId);

    if (compId !== null) {
      if (!isNaN(selectedMemberId) && selectedMemberId !== -1) {
        try {
          const memberInfo = await getCompanyMember(compId, selectedMemberId);
          console.log(memberInfo, "memberInfo");
          setMemberPart(memberInfo.member_department);
        } catch (error) {
          console.error(error);
        }
      } else {
        setMemberId(null);
        setMemberPart("-");
      }
    }
  };

  // 업무일지 날짜
  const handleWorkDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDate(value);
  };

  // 시간 선택 핸들러 추가
  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTime(event.target.value);
  };

  const workOptionOptions = [
    { id: "TECHNICAL_EXTERNAL", name: "현장대응" },
    { id: "TECHNICAL_INTERNAL", name: "전화대응" },
    { id: "INCOMPANY", name: "사내업무" },
    { id: "DELIVERY", name: "납품" },
    { id: "MEETING", name: "미팅" },
    { id: "EDUCATION", name: "교육" },
    { id: "OTHER", name: "기타" },
  ];

  const meetingOptions = [
    { id: "CALL", name: "전화" },
    { id: "INPERSON", name: "대면" },
    { id: "INTERNAL", name: "내근" },
    { id: "OTHER", name: "기타" },
  ];

  return (
    <div className="write_list_wrapper">
      <div className="write_content">
        <div className="write_date">
          <div className="div_icon" style={{ marginRight: "15px" }}>
            <CalendarIcon style={{ marginRight: "4px" }} />
            일시
          </div>
          <div className="date_time_input__box">
            <input type="date" value={date} onChange={handleWorkDate} />
            <input type="time" value={time} onChange={handleTimeChange} />
          </div>
        </div>
        <div>
          <div className="div_icon">
            <UserIcon style={{ marginRight: "4px" }} /> 담당자
          </div>
          <span>
            {loginUsername} ({loginUserId})
          </span>
        </div>
        <div>
          <div className="div_icon">
            <WorkOptionIcon style={{ marginRight: "4px" }} /> 업무구분
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <SelectInput
            id="work_option"
            value={workOption}
            onChange={({ target }) => setWorkOption(target.value)}
            options={workOptionOptions}
          />
        </div>
        <div>
          <div className="div_icon">
            <MeetOptionIcon style={{ marginRight: "4px" }} /> 미팅방식
          </div>
          <SelectInput
            id="meeting"
            value={meeting}
            onChange={({ target }) => setMeetingOption(target.value)}
            options={meetingOptions}
          />
        </div>
        <div>
          <div className="div_icon">
            <PartIcon style={{ marginRight: "4px" }} /> 지역
          </div>
          <span>{regionItem}</span>
        </div>
        <div>
          <div className="div_icon">
            <CompanyIcon style={{ marginRight: "4px" }} /> 고객사명
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <AutoCompleteCompany />
        </div>
        <div>
          <div className="div_icon">
            <MemberIcon style={{ marginRight: "4px" }} /> 고객사 담당자
          </div>
          <select
            disabled={compId === null || compId === -1}
            value={memberId != null ? memberId : -1}
            onChange={handleSelectMember}
          >
            {compId === null || compId === -1 ? (
              <option value="-1">고객사를 먼저 선택하세요</option>
            ) : (
              <>
                <option value="-1">선택하세요</option>
                {members.map((member) => (
                  <option key={member.member_id} value={member.member_id}>
                    {member.member_name} {member.member_role}
                  </option>
                ))}
              </>
            )}
          </select>
        </div>
        <div>
          <div className="div_icon">
            <PartIcon style={{ marginRight: "4px" }} />
            고객사 부서
          </div>
          <span>{memberPart}</span>
        </div>

        <div className="textarea_wrapper">
          <div className="div_icon">
            <PlanIcon style={{ marginRight: "4px" }} /> 계획일정
          </div>
          <textarea
            value={plan}
            name="plan"
            onChange={({ target }) => setPlan(target.value)}
            disabled={false}
          />
        </div>
        <div className="textarea_wrapper">
          <div className="div_icon">
            <ResultIcon style={{ marginRight: "4px" }} /> 대응내용 및 결과
          </div>
          <textarea
            value={content}
            name="work_content"
            onChange={({ target }) => setContent(target.value)}
            disabled={false}
          />
        </div>
        <div className="work_process">
          <div className="div_icon">
            <ProgressIcon style={{ marginRight: "4px" }} />
            진행상황
          </div>
          <select
            value={progress}
            onChange={({ target }) => setProgress(target.value)}
          >
            <option value="EXPECTED">예정</option>
            <option value="INPROGRESS">진행중</option>
            <option value="NOTPROGRESS">미진행</option>
            <option value="COMPLETE">완료</option>
          </select>
        </div>
      </div>
      <div className="write_bottom">
        <>
          <CancelButton onClickHandler={onCancel} />
          <SaveButton onClickHandler={handleSubmit} />
        </>
      </div>
    </div>
  );
};
export default WorkLogWrite;
