import { useEffect, useRef, useState } from "react";

import { ReactComponent as Arrow } from "assets/arrow.svg";
import "styles/PlanStyle.css";
interface PlanDateProps {
  selectedMDate?: string;
  selectedYDate?: string;
  setDate: (year: string, month: string) => void;
}

const PlanDate = ({ setDate, selectedMDate, selectedYDate }: PlanDateProps) => {
  console.log(selectedYDate, "selectedYDate");
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const years = Array.from(
    { length: currentYear - 2004 },
    (_, index) => currentYear - index
  );
  const months = Array.from({ length: 12 }, (_, index) => index + 1);
  const [selectedYear, setSelectedYear] = useState<string>(
    currentYear.toString()
  );
  const [selectedMonth, setSelectedMonth] = useState<string>(
    currentMonth.toString()
  );
  const [isListYearVisible, setIsListYearVisible] = useState(false);
  const [isListMonthVisible, setIsListMonthVisible] = useState(false);
  const selectedMonthRef = useRef<HTMLDivElement | null>(null);
  const selectedYearRef = useRef<HTMLDivElement | null>(null);

  const onYearChange = async (year: string) => {
    if (selectedYDate === year) {
      setSelectedYear(year);
    } else {
      setSelectedYear(year);
    }
  };
  const onMonthChange = async (month: string) => {
    // setSelectedMonth(month);
    setSelectedMonth(month);
  };

  const toggleListYearVisibility = () => {
    setIsListYearVisible(!isListYearVisible);
    setIsListMonthVisible(false);
  };

  const toggleListMonthVisibility = () => {
    setIsListMonthVisible(!isListMonthVisible);
    setIsListYearVisible(false);
  };

  useEffect(() => {
    setDate(selectedYear, selectedMonth);
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    if (selectedYDate && selectedMDate) {
      setSelectedYear(selectedYDate);
      setSelectedMonth(selectedMDate);
    }
  }, []);

  useEffect(() => {
    if (selectedMonthRef.current) {
      selectedMonthRef.current.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
    }
  }, [selectedMonth, isListMonthVisible]);

  useEffect(() => {
    if (selectedYearRef.current) {
      selectedYearRef.current.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
    }
  }, [selectedYear, isListYearVisible]);

  useEffect(() => {
    if (selectedYDate) {
      setSelectedYear(selectedYDate);
    }
  }, [selectedYDate]);

  return (
    <>
      <div style={{ display: "flex", width: "200px" }}>
        <div
          className="custom-select"
          tabIndex={0}
          style={{ width: "130px", marginRight: "6px" }}
          onClick={toggleListYearVisibility}
        >
          <div className="selected-value">
            {selectedYear}년
            <span>
              <Arrow />
            </span>
          </div>
          {isListYearVisible && (
            <div className="custom-select-list">
              {years.map((year) => (
                <div
                  key={year}
                  onClick={() => onYearChange(year.toString())}
                  className={`option ${
                    selectedYear === year.toString() ? "selected" : ""
                  }`}
                  ref={
                    selectedYear === year.toString() ? selectedYearRef : null
                  }
                >
                  {year}년
                </div>
              ))}
            </div>
          )}
        </div>
        {selectedMDate === "No" ? (
          ""
        ) : (
          <div
            className="custom-select"
            tabIndex={0}
            style={{ width: "130px" }}
            onClick={toggleListMonthVisibility}
          >
            <div className="selected-value">
              {selectedMonth}월
              <span>
                <Arrow />
              </span>
            </div>
            {isListMonthVisible && (
              <div className="custom-select-list">
                {months.map((month) => (
                  <div
                    key={month}
                    onClick={() => onMonthChange(month.toString())}
                    className={`option ${
                      selectedMonth === month.toString() ? "selected" : ""
                    }`}
                    ref={
                      selectedMonth === month.toString()
                        ? selectedMonthRef
                        : null
                    }
                  >
                    {month}월
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default PlanDate;
