import { atom } from "recoil";

export const sidebarTitleState = atom<string>({
  key: "sidebarTitleState",
  default: localStorage.getItem("sidebarTitle") || "",
});

export const selectedRowIdState = atom<number>({
  key: "selectedRowIdState",
  default: -1,
});

export const anchorElementState = atom<null | HTMLElement>({
  key: "anchorEl",
  default: null,
});
