import { useEffect, useState } from "react";
import { ColDef, ValueFormatterParams } from "ag-grid-community";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { ReactComponent as WriteIcon } from "assets/write.svg";
import PlanDate from "components/PlanDate";
import YearGrid from "components/YearGrid";
import { User } from "api/user";
import { getAnnualPlans, ResponseYearPlan } from "api/yearPlan";
import { Company } from "api/company";
import {
  calculateFirstHalf,
  calculateSecondHalf,
  calculateTotal,
  convertRegionName,
  textFilterParams,
} from "components/Common";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  autoSelectCompIdState,
  filterUserState,
  isEditedState,
  searchRangeDateState,
} from "recoil/stateStore";
import SelectUserInfo from "components/SelectUserInfo";
import ViewYPlanDrawer from "components/drawer/ViewYPlanDrawer";
import CreateWorkDrawer from "components/drawer/CreateDrawer";
import { Member } from "api/member";
import DialogText from "components/DialogText";
import { useNavigate } from "react-router-dom";

interface RowData {
  no: number;
  aplan_id: number;
  user: User;
  company: Company;
  sumDataByRegion: string;
  aplan_year: string;
  first_quarter: number;
  second_quarter: number;
  third_quarter: number;
  fourth_quarter: number;
  first_q: string;
  second_q: string;
  third_q: string;
  fourth_q: string;
  firstHalf: string;
  secondHalf: string;
  total_p: string;
  comp_progress: string;
  sales_plan: string;
  comp_sido: string;
}

export default function YearPlan() {
  const currentYear = new Date().getFullYear();
  const [rowData, setRowData] = useState<RowData[]>([]);
  const [year, setYear] = useState<string>(String(currentYear));
  const [planDrawerOpen, setPlanDrawerOpen] = useState<boolean>(false);
  const [selectedPlanId, setSelectedPlanId] = useState<number>(-1);
  const [userDrawerOpen, setUserDrawerOpen] = useState<string>("");
  const setAutoSelectCompId = useSetRecoilState(autoSelectCompIdState);
  const [isEdit, setIsEdit] = useRecoilState(isEditedState);
  const [openDialog, setOpenDialog] = useState(false);
  const filterUser = useRecoilValue(filterUserState);
  const rangeDate = useRecoilValue(searchRangeDateState);

  const navigate = useNavigate();
  const [updatedData, setUpdatedData] = useState<ResponseYearPlan>();
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [deletedId, setDeletedId] = useState<number>(-1);

  const setDate = (year: string, month: string) => {
    setYear(year);
  };

  const handleSetOpen = (status: boolean, type?: string) => {
    if (type === "newYearPlan") {
      setAutoSelectCompId(null);
      setPlanDrawerOpen(true);
      setUserDrawerOpen("create");
    } else {
      setPlanDrawerOpen(true);
      setUserDrawerOpen("view");
    }
  };

  const closeDrawer = () => {
    if (userDrawerOpen === "view" && !isEdit) {
      closeDialog();
    } else {
      setOpenDialog(true);
    }
  };

  const handleSubmitSuccess = () => {
    setIsSaved(true);
    closeDialog();
  };

  const closeDialog = () => {
    setPlanDrawerOpen(false);
    setOpenDialog(false);
    setIsEdit(false);
  };

  const selectedId = (type: string, id: number) => {
    setSelectedPlanId(id);
  };

  const pageSizeChange = (size: number) => {
    // setViewSize(size);
  };

  useEffect(() => {
    const callYearPlanList = async () => {
      try {
        const { data } = await getAnnualPlans(filterUser, year, "", "");
        const totalByAddress: Record<string, number> = {};

        data.content.forEach((item: ResponseYearPlan) => {
          const address =
            convertRegionName(item.company?.comp_sido_nm || "") +
            " " +
            item.company?.comp_sgg_nm;

          const totalValue = calculateTotal(item);

          if (!totalByAddress[address]) {
            totalByAddress[address] = totalValue;
          } else {
            totalByAddress[address] += totalValue;
          }
        });

        const mappedData = data.content.map(
          (item: ResponseYearPlan, index: number) => {
            const address =
              convertRegionName(item.company?.comp_sido_nm || "") +
              " " +
              item.company?.comp_sgg_nm;

            return {
              no: index + 1,
              aplan_id: item.aplan_id,
              aplan_year: item.aplan_year,
              address: address,
              sumDataByRegion:
                totalByAddress[address]?.toLocaleString("ko-KR") || "0", // 해당 지역의 매출 총합
              company: item.company?.comp_name,
              comp_id: item.company?.comp_id ?? -1,
              comp_sido: item.company?.comp_sido_nm,
              sector: item.company?.sectors,
              user: item.user?.user_name,
              first_quarter: item.first_quarter | 0,
              first_q: (item.first_quarter | 0).toLocaleString("ko-KR"),
              second_quarter: item.second_quarter | 0,
              second_q: (item.second_quarter | 0).toLocaleString("ko-KR"),
              third_quarter: item.third_quarter | 0,
              third_q: (item.third_quarter | 0).toLocaleString("ko-KR"),
              fourth_quarter: item.fourth_quarter | 0,
              fourth_q: (item.fourth_quarter | 0).toLocaleString("ko-KR"),
              firstHalf: calculateFirstHalf(item).toLocaleString("ko-KR"),
              secondHalf: calculateSecondHalf(item).toLocaleString("ko-KR"),
              total: calculateTotal(item),
              total_p: calculateTotal(item).toLocaleString("ko-KR"),
              comp_progress: item.comp_progress,
              sales_plan: item.sales_plan,
              membersWithRoles: item.comp_members
                .map(
                  (member: Member) =>
                    `${member.member_name} ${member.member_role}`
                )
                .join(", "),
            };
          }
        );

        setRowData(mappedData);
        setIsSaved(false);
        setDeletedId(-1);
        console.log(mappedData, "AnnualPlanData");
      } catch (error) {
        console.error("Error fetching year logs:", error);
      }
    };
    callYearPlanList();
  }, [isSaved, filterUser, year, rangeDate]);

  const [columnDefs] = useState<ColDef[]>([
    { headerName: "No", field: "no", flex: 0.6 },
    {
      headerName: "지역",
      field: "address",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 1.2,
    },
    {
      headerName: "시/도별 매출액",
      field: "sumDataByRegion",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 1.2,
    },
    {
      headerName: "업체명",
      field: "company",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      cellRenderer: (params: ValueFormatterParams) => {
        const compId = params.data.comp_id;
        console.log(params, compId, "compId");
        return (
          <p
            className="comp_name"
            onClick={() =>
              navigate(`/companies/${params.value}`, {
                state: { id: compId },
              })
            }
          >
            {params.value}
          </p>
        );
      },
    },
    {
      headerName: "업종",
      field: "sector",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 1,
    },
    {
      headerName: "1분기",
      field: "first_q",
      flex: 1,
      cellClass: "quarterColor_odd",
    },
    {
      headerName: "2분기",
      field: "second_q",
      flex: 1,
      cellClass: "quarterColor",
    },
    {
      headerName: "3분기",
      field: "third_q",
      flex: 1,
      cellClass: "quarterColor_odd",
    },
    {
      headerName: "4분기",
      field: "fourth_q",
      flex: 1,
      cellClass: "quarterColor",
    },
    {
      headerName: "상반기",
      field: "firstHalf",
      flex: 1.1,
      cellClass: "halfColor",
    },
    {
      headerName: "하반기",
      field: "secondHalf",
      flex: 1.1,
      cellClass: "halfColor",
    },
    {
      headerName: "전체 (단위:만원)",
      field: "total_p",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 1.4,
      cellClass: "yearTotalColor",
    },
    {
      headerName: "업체별 진행상황",
      field: "comp_progress",
      // filter: "agTextColumnFilter",
      // filterParams: textFilterParams,
      flex: 1.5,
    },
    {
      headerName: "영업계획",
      field: "sales_plan",
      // filter: "agTextColumnFilter",
      // filterParams: textFilterParams,
      flex: 1.4,
    },
    {
      headerName: "주요 담당자",
      field: "membersWithRoles",
      // filter: "agTextColumnFilter",
      // filterParams: textFilterParams,
      flex: 1.2,
    },
  ]);

  return (
    <>
      <div className="mainTop_wrapper">
        <div className="title">
          <div className="menu">Work management</div>
          <div className="sub_menu">
            <div className="sub_menu__title">
              <h1>연간계획서</h1>
              <SelectUserInfo selectUserId={filterUser} />
            </div>
            <div>
              <button
                className="write_button"
                onClick={() => handleSetOpen(true, "newYearPlan")}
              >
                <WriteIcon />
                연간계획서 작성
              </button>
            </div>
          </div>
        </div>
        <Box
          id={"month_plan_card"}
          style={{ height: "fit-content", marginBottom: "20px" }}
        >
          <PlanDate setDate={setDate} selectedYDate={year} selectedMDate="No" />
        </Box>
        <Card id={"main_card"}>
          <YearGrid
            columnDefs={columnDefs}
            rowData={rowData}
            setOpen={handleSetOpen}
            setId={selectedId}
            newData={updatedData}
            deletedId={deletedId}
          />
        </Card>
        <ViewYPlanDrawer
          open={userDrawerOpen === "view" && planDrawerOpen}
          onClose={closeDrawer}
          planId={selectedPlanId}
          setUpdatedData={setUpdatedData}
          setDeletedId={setDeletedId}
        />
        <CreateWorkDrawer
          open={userDrawerOpen === "create" && planDrawerOpen}
          onClose={closeDrawer}
          onSubmitSuccess={handleSubmitSuccess}
          type={"YearPlan"}
        />
        <DialogText
          open={openDialog}
          content="변경 사항이 저장되지 않을 수 있습니다. 계속하시겠습니까?"
          onCancel={() => setOpenDialog(false)}
          onClose={closeDialog}
        />
      </div>
    </>
  );
}
