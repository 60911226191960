import axios, { Axios } from "axios";
import { client, getErrorMessage } from "./client";
import { User } from "./user";
import { ResponseCar } from "./car";
import { Company } from "./company";
import { Member } from "./member";

export interface ResponseMonthPlan {
  mplan_id?: number | null;
  mplan_year: string;
  mplan_month: string;
  user?: User;
  is_newCompany: boolean;
  company?: Company;
  expected_price: number | null;
  confirmed_price: number | null;
  delivery_date: string;
  expected_order_rate: number;
  order_rate: number;
  mplan_note: string;
  mplan_detail: string;
  mplan_progress: string;
}

// 응답: 특정 년도의 연간계획서 조회
export interface MonthPlanList {
  total_count: number;
  content: ResponseMonthPlan[];
}

//연간계획서 목록
export const getMonthPlanList = async (
  user_id: string,
  year: string,
  month: string
) => {
  try {
    // const token = localStorage.getItem("token");
    const response = await client.get("/monthly_plans", {
      headers: {
        // Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 포함
      },
      params: { user_id, year, month },
    });
    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

// // 연간계획서 상세보기
// export const getMonthPlan = async (id: string) => {
//   try {
//     // const token = localStorage.getItem("token");
//     const response = await client.get(`/monthly_plans/${id}`, {
//       headers: {
//         // Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 포함
//       },
//     });
//     return response.data;
//   } catch (error: any) {
//     throw new Error(getErrorMessage(error));
//   }
// };

//월간계획서 등록
export const postMonthlyPlan = async (
  year: string,
  month: string,
  writeMonthly: ResponseMonthPlan
) => {
  try {
    const token = localStorage.getItem("token");
    const response = await client.post(
      `/monthly_plans?year=${year}&month=${month}`,
      writeMonthly,
      {
        headers: {
          Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 포함
        },
      }
    );
    return response;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

// 월간계획서 단일 조회
export const getMonthlyPlan = async (mplan_id: number) => {
  try {
    const response = await client.get(`/monthly_plan/${mplan_id}`);
    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

//월간계획서 수정
export const patchMonthlyPlan = async (
  mplan_id: number,
  writeMonthly: ResponseMonthPlan
) => {
  try {
    const token = localStorage.getItem("token");
    const response = await client.patch(
      `/monthly_plan/${mplan_id}`,
      writeMonthly,
      {
        headers: {
          Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 포함
        },
      }
    );
    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

//월간계획서 삭제
export const deleteMonthlyPlan = async (mplan_id: number) => {
  try {
    const token = localStorage.getItem("token");
    const response = await client.delete(`/monthly_plan/${mplan_id}`, {
      headers: {
        Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 포함
      },
    });
    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};
