import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useRef, useState } from "react";

interface SalesGridProps {
  columnDefs: ColDef[];
  rowData: any[];
}

const SalesSummaryGrid: React.FC<SalesGridProps> = ({
  columnDefs,
  rowData,
}) => {
  const gridRef = useRef<AgGridReact>(null);
  const filterInputRef = useRef<HTMLInputElement>(null);
  const [quickFilterText, setQuickFilterText] = useState<string>("");
  const [pinnedBottomData, setPinnedBottomData] = useState<any[]>([]);
  const [gridHeight, setGridHeight] = useState<number>(500);
  const [view, setView] = useState<number>(10);

  useEffect(() => {
    if (view === 15) {
      setGridHeight(500);
    } else if (view === 25) {
      setGridHeight(1100);
    } else {
      setGridHeight(500);
    }
  }, [view]);

  useEffect(() => {
    const columnSums = columnDefs
      .filter((col, index) => index >= 3) // 앞의 세 개 열을 제외
      .map((col) => {
        return {
          field: col.field,
          sum: rowData.reduce(
            (sum, row) => sum + (row[col.field as keyof typeof row] || 0),
            0
          ), // 각 열의 총합 계산
        };
      });

    const newPinnedBottomData = [
      {
        product: "총합: ",
        user_name: "-",
        comp_name: "-",
        ...Object.fromEntries(columnSums.map((col) => [col.field, col.sum])), // 각 열의 총합을 추가
      },
    ];
    setPinnedBottomData(newPinnedBottomData);
  }, [rowData, columnDefs]);

  // 필터 리셋
  const resetFilters = () => {
    const gridApi = gridRef.current?.api;
    if (gridApi) {
      gridApi.setFilterModel(null);
      gridApi.onFilterChanged();
      gridApi.applyColumnState({
        defaultState: { sort: null },
      });
    }
    setQuickFilterText("");
    if (filterInputRef.current) {
      filterInputRef.current.value = "";
    }
  };

  const localeText = {
    contains: "포함",
    notContains: "포함하지 않음",
    dateFormatOoo: "yyyy-mm-dd",
  };

  return (
    <>
      <div className="grid-container">
        <div className="filter">
          <div>
            <div className="wordSearch_container">
              <label>검색 :</label>
              <input
                ref={filterInputRef}
                type="text"
                placeholder="검색어를 입력하세요"
                onChange={(e) => setQuickFilterText(e.target.value)}
              />
              <button className="resetBtn" onClick={resetFilters}>
                필터 리셋
              </button>
            </div>
            <div className="viewSize_btn_box">
              <label>View Size :</label>
              <button
                className={
                  view === 10 ? "viewSize_btn selected" : "viewSize_btn"
                }
                onClick={() => setView(10)}
              >
                10
              </button>
              <button
                className={
                  view === 25 ? "viewSize_btn selected" : "viewSize_btn"
                }
                onClick={() => setView(25)}
              >
                25
              </button>
            </div>
          </div>
        </div>
        <div
          className="ag-theme-alpine"
          style={{ height: `${gridHeight}px`, width: "100%" }}
        >
          <AgGridReact
            ref={gridRef}
            columnDefs={columnDefs}
            rowData={rowData}
            pinnedBottomRowData={pinnedBottomData}
            quickFilterText={quickFilterText}
            localeText={localeText}
            animateRows={true}
            overlayNoRowsTemplate={"데이터가 존재하지 않습니다."} // 데이터 없는 경우 뜨는 메세지
            popupParent={document.body} //필터 누르면 표하단 가려지는 문제 해결용
          />
        </div>
      </div>
    </>
  );
};

export default SalesSummaryGrid;
