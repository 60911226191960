import { getCar } from "api/car";
import { ResponseCarLog, getCarLog, getCarLogs } from "api/carLog";
import WorkLogDrawer from "components/WorkLogDrawer";
import EditCarLogForm from "components/editForm/EditCarLogForm";
import ViewCarLogForm from "components/viewForm/ViewCarLogForm";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { isEditedState } from "recoil/stateStore";

interface FormDrawerProps {
  open: boolean;
  onClose: (event: any) => void;
  carLogId: number;
  setUpdatedData: Function;
  setDeletedId: Function;
}
const ViewCarLogDrawer: React.FC<FormDrawerProps> = ({
  open,
  onClose,
  carLogId,
  setUpdatedData,
  setDeletedId,
}) => {
  const [isEdit, setIsEdit] = useRecoilState(isEditedState);
  const [carLogData, setCarLogData] = useState<ResponseCarLog>(
    {} as ResponseCarLog
  );

  useEffect(() => {
    const callCarLog = async () => {
      const carData = await getCarLog(carLogId);
      const resCar = await getCar(carData.data.car.car_id);
      const carModel = resCar.data.car_model;
      setCarLogData(carData.data);
      setUpdatedData({ ...carData.data, carModel });
    };
    if (carLogId !== -1 && !isEdit) {
      callCarLog();
    }
  }, [carLogId, isEdit]);

  return (
    <WorkLogDrawer open={open} onClose={onClose}>
      {isEdit ? (
        <div className="write_head">차량운행일지 수정</div>
      ) : (
        <div className="write_head">차량운행일지 상세보기</div>
      )}
      {isEdit ? (
        <EditCarLogForm carLogData={carLogData} setCarLogData={setCarLogData} />
      ) : (
        <ViewCarLogForm
          carLogData={carLogData}
          onClose={onClose}
          setDeletedId={setDeletedId}
        />
      )}
    </WorkLogDrawer>
  );
};
export default ViewCarLogDrawer;
