import { ReactComponent as CartypeIcon } from "assets/writeIcon/cartype.svg";
import { ReactComponent as CarnumIcon } from "assets/writeIcon/carnum.svg";
import { ReactComponent as UserIcon } from "assets/writeIcon/name.svg";
import { ReactComponent as HomeIcon } from "assets/writeIcon/home.svg";
import { ReactComponent as CompanyIcon } from "assets/writeIcon/company.svg";
import { ReactComponent as SwapIcon } from "assets/writeIcon/swap.svg";
import { ReactComponent as NoteIcon } from "assets/writeIcon/note.svg";
import { CancelButton, SaveButton } from "components/Common";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { isEditedState } from "recoil/stateStore";
import { ResponseCar, patchCar } from "api/car";
import DialogText from "components/DialogText";
import { useErrorHandler } from "components/ErrorHandler";
import { User, getUserList } from "api/user";

interface EditCarFormProps {
  carData: ResponseCar;
  setCarData: Function;
  carId: number;
}

const EditCarForm = ({ carData, setCarData, carId }: EditCarFormProps) => {
  const handleError = useErrorHandler();
  const loginUserIdStr = localStorage.getItem("user_id");
  const [openDialog, setOpenDialog] = useState(false);
  const [userList, setUserList] = useState<User[]>([]);
  const [carModel, setCarModel] = useState<string>(carData.car_model);
  const [carNum, setCarNum] = useState<string>(carData.car_num);
  const [homeAdd, setHomeAdd] = useState<string>(carData.home_address);
  const [workAdd, setWorkAdd] = useState<string>(carData.work_address);
  const [dis, setDis] = useState<number>(carData.distance);
  const [note, setNote] = useState<string>(carData.car_note);
  const [isDeleted, setIsDeleted] = useState<boolean>(carData.is_deleted);
  const [isPersonal, setIsPersonal] = useState<boolean>(carData.is_personal);
  const [selectedUserId, setSelectedUserId] = useState<string>(
    carData.user_id || ""
  );
  const setIsEdit = useSetRecoilState(isEditedState);

  const onCancel = () => {
    setOpenDialog(true);
  };

  // 저장버튼
  const handleSubmit = async () => {
    if (carModel === null || carNum === null) {
      alert("필수값이 입력되지 않았습니다. ");
    } else {
      console.log("수정 저장");
      try {
        const data = {
          ...carData,
          car_id: 1,
          car_model: carModel,
          car_num: carNum,
          is_personal: isPersonal,
          home_address: homeAdd,
          work_address: workAdd,
          distance: dis,
          car_note: note,
          user_id: selectedUserId,
          is_deleted: isDeleted,
        };

        const updateCar = await patchCar(carId, data);
        setCarData(updateCar);
        // setWorkList((prev) => [...prev, updateCarlog]);
        // console.log(workLogData.work_id, data, updateWorkLog, "수정 데이터");
        // successSave();
        setIsEdit(false);
      } catch (error) {
        handleError(error); // 오류 처리 함수 호출
        console.log("edited worklog not saved");
      }
    }
  };

  // 전체 유저 정보
  useEffect(() => {
    async function fetchUsers() {
      try {
        const allUserData = await getUserList(false);
        if (allUserData) {
          console.log(allUserData.data.content, "allUserData.content");
          setUserList(allUserData.data.content);
        }
      } catch (error) {
        console.log("유저 정보 불러오기 실패!");
      }
    }
    fetchUsers();
  }, []);

  return (
    <div
      className="write_list_wrapper"
      // style={{ height: "863px", padding: "20px 24px" }}
    >
      <div className="work_option">
        <div>
          <div className="div_icon" style={{ marginRight: "15px" }}>
            <CompanyIcon style={{ marginRight: "4px" }} />
            법인 유무
          </div>
          <div className="check_radio">
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="radio"
                id="confirm_car"
                name="is_personal"
                value="work"
                checked={!isPersonal}
                style={{
                  marginRight: "4px",
                }}
                onChange={({ target }) =>
                  setIsPersonal(target.value === "personal")
                }
                disabled={false}
              />
              <label htmlFor="confirm_car">법인차량</label>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="radio"
                id="personal_car"
                name="is_personal"
                value="personal"
                checked={isPersonal}
                onChange={({ target }) =>
                  setIsPersonal(target.value === "personal")
                }
                style={{
                  marginRight: "4px",
                }}
                disabled={false}
              />
              <label htmlFor="personal_car">개인차량</label>
            </div>
          </div>
        </div>
        <div>
          <div className="div_icon">
            <CartypeIcon style={{ marginRight: "4px" }} />
            차종
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <input
            type="text"
            name="car_model"
            value={carModel}
            onChange={({ target }) => setCarModel(target.value)}
          />
        </div>
        <div>
          <div className="div_icon">
            <CarnumIcon style={{ marginRight: "4px" }} />
            차량등록번호
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <input
            type="text"
            name="car_num"
            value={carNum}
            onChange={({ target }) => setCarNum(target.value)}
          />
        </div>
        <div>
          <div className="div_icon">
            <UserIcon style={{ marginRight: "4px" }} />
            사용자
          </div>
          <select
            value={selectedUserId !== null ? selectedUserId : ""}
            onChange={({ target }) => setSelectedUserId(target.value)}
          >
            <option value="">선택하세요</option>
            {userList.map((user) => (
              <option key={user.user_id} value={user.user_id}>
                {user.user_name} {user.role.role_name} ({user.user_id})
              </option>
            ))}
          </select>
        </div>
        <div>
          <div className="div_icon">
            <HomeIcon style={{ marginRight: "4px" }} />
            자택
          </div>
          <input
            type="text"
            name="home_address"
            value={homeAdd}
            onChange={({ target }) => setHomeAdd(target.value)}
          />
        </div>
        <div>
          <div className="div_icon">
            <CompanyIcon style={{ marginRight: "4px" }} />
            근무지
          </div>
          <input
            type="text"
            name="work_address"
            value={workAdd}
            onChange={({ target }) => setWorkAdd(target.value)}
          />
        </div>
        <div>
          <div className="div_icon">
            <SwapIcon style={{ marginRight: "4px" }} />
            출퇴근 거리
          </div>
          <input
            type="number"
            className="input_distance"
            name="distance"
            value={dis}
            onChange={({ target }) => setDis(parseInt(target.value))}
          />
        </div>
        <div className="textarea_wrapper">
          <div className="div_icon">
            <NoteIcon style={{ marginRight: "4px" }} />
            비고
          </div>
          <textarea
            value={note}
            style={{ height: "140px", marginTop: "4px", color: "#000" }}
            name="car_note"
            onChange={({ target }) => setNote(target.value)}
            disabled={false}
          />
        </div>
      </div>
      <div className="write_bottom">
        <>
          <DialogText
            open={openDialog}
            content="변경 사항이 저장되지 않을 수 있습니다. 계속하시겠습니까?"
            onCancel={() => setOpenDialog(false)}
            onClose={() => setIsEdit(false)}
          />
          <CancelButton onClickHandler={onCancel} />
          <SaveButton onClickHandler={handleSubmit} />
        </>
      </div>
    </div>
  );
};
export default EditCarForm;
