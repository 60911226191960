import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import SubItemUserList from "./SubItemUserList";
import { sidebarTitleState } from "recoil/commonState";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";

interface SidebarAccordionProps {
  title: string;
  icon: JSX.Element;
  panel: string;
  expanded: string;
  onChange: (event: React.SyntheticEvent, expanded: boolean) => void;
  page: string;
  hasDetails: boolean;
  open: boolean;
}

export const SidebarAccordion: React.FC<SidebarAccordionProps> = ({
  title,
  icon,
  panel,
  expanded,
  onChange,
  page,
  hasDetails,
  open,
}) => {
  const [sidebarTitle, setSidebarTitle] = useRecoilState(sidebarTitleState);
  const navigate = useNavigate();
  const handleAccordionClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // 클릭 시, 현재 패널이 확장된 상태라면 닫고, 그렇지 않다면 열도록 설정
    const isExpanded = expanded === panel;
    if (!hasDetails) {
      onChange(event, !isExpanded);
      setSidebarTitle(title);
      navigate(page);
    } else {
      onChange(event, !isExpanded);
    }
  };

  return (
    <Accordion
      disableGutters
      elevation={0}
      square
      expanded={expanded === panel}
      sx={{
        "&::before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={open && hasDetails ? <ExpandMore /> : null}
        aria-controls={`${panel}-content`}
        id="panel1bh-header"
        className={`listHover accordionSummary ${
          expanded === panel ? "expanded" : ""
        }`}
        onClick={handleAccordionClick}
      >
        <ListItemIcon
          id="listIcon"
          sx={{
            minHeight: "30.5px",
            justifyContent: open ? "flex-start" : "center",
            alignItems: "center",
          }}
        >
          {icon}
        </ListItemIcon>
        {open && <ListItemText primary={title} sx={{ flexShrink: 0 }} />}
      </AccordionSummary>
      {open && hasDetails && expanded === panel && (
        <AccordionDetails id="accordionList">
          <SubItemUserList
            panel={panel}
            page={page}
            setSidebarTitle={setSidebarTitle}
            title={title}
          />
        </AccordionDetails>
      )}
    </Accordion>
  );
};
