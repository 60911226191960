import { ChangeEvent, useEffect, useRef, useState } from "react";

type PhoneType = "tel" | "phone" | "fax";

interface PhoneFormProps {
  phoneNumberType: PhoneType;
  phoneNumberData: string;
  onPhoneNumberChange: (
    phoneNumber: string,
    phoneNumberType: PhoneType
  ) => void;
}

export default function PhoneForm({
  phoneNumberType,
  phoneNumberData,
  onPhoneNumberChange,
}: PhoneFormProps) {
  const [firstPart, setFirstPart] = useState("");
  const [secondPart, setSecondPart] = useState("");
  const [thirdPart, setThirdPart] = useState("");
  const firstNumber = useRef<HTMLInputElement>(null);
  const secondNumber = useRef<HTMLInputElement>(null);
  const thirdNumber = useRef<HTMLInputElement>(null);

  // console.log(firstNumber, secondNumber, thirdNumber);

  const handleChangeFirstPart = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setFirstPart(value);
      updatePhoneNumber(value, secondPart, thirdPart);
    }
  };

  const handleChangeSecondPart = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setSecondPart(value);
      updatePhoneNumber(firstPart, value, thirdPart);
    }
  };

  const handleChangeThirdPart = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setThirdPart(value);
      updatePhoneNumber(firstPart, secondPart, value);
    }
  };

  const updatePhoneNumber = (first: string, second: string, third: string) => {
    // const combinedNumber = first + second + third;
    const combinedNumber = `${first}-${second}-${third}`;
    onPhoneNumberChange(combinedNumber, phoneNumberType);
  };

  useEffect(() => {
    if (phoneNumberData !== null) {
      // console.log(phoneNumberData, "phoneNumberData")
      const phoneNumSplit = phoneNumberData?.split("-");
      setFirstPart(phoneNumSplit[0]);
      setSecondPart(phoneNumSplit[1]);
      setThirdPart(phoneNumSplit[2]);
    }
  }, [phoneNumberData]);

  useEffect(() => {
    if (firstPart?.length >= 3 && secondNumber.current) {
      secondNumber.current.focus();
    }
  }, [firstPart]);

  useEffect(() => {
    if (secondPart?.length >= 4 && thirdNumber.current) {
      thirdNumber.current.focus();
    }
  }, [secondPart]);

  const handleInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.currentTarget.select(); // input 클릭 시 모든 텍스트를 선택
  };
  return (
    <>
      <div className="phone_input">
        <input
          type="text"
          ref={firstNumber}
          maxLength={3}
          value={firstPart}
          onClick={handleInputClick}
          onChange={handleChangeFirstPart}
          placeholder="000"
        />
        <span>-</span>
        <input
          type="text"
          ref={secondNumber}
          maxLength={4}
          value={secondPart}
          onClick={handleInputClick}
          onChange={handleChangeSecondPart}
          placeholder="0000"
        />
        <span>-</span>
        <input
          type="text"
          ref={thirdNumber}
          maxLength={4}
          value={thirdPart}
          onClick={handleInputClick}
          onChange={handleChangeThirdPart}
          placeholder="0000"
        />
      </div>
    </>
  );
}
