import { BaseResponse, client } from "./client";
import axios, { AxiosRequestConfig } from "axios";
import { getErrorMessage } from "./client";

export interface Department {
  dep_id: number;
  dep_name: string;
}

export interface PartList {
  total_count: number;
  content: Department[];
}

export interface Role {
  role_id: number;
  role_name: string;
}

export interface RoleList {
  total_count: number;
  content: Role[];
}

export interface UserTypes {
  user_id: string;
  password: string;
}

export interface LoginUserInfo {
  user_id: string;
  user_name: string;
  is_admin: boolean;
  user_role: string;
  token: string;
}

export interface User {
  user_id: string;
  user_name: string;
  password?: string;
  department: Department;
  role: Role;
  email: string;
  work_phone_number: string;
  phone_number: string;
  is_car: boolean;
  is_admin: boolean;
  user_note: string;
  is_deleted: boolean;
  token: string;
}

export interface RequestUser {
  user_id: string;
  user_name: string;
  dep_id: number;
  role_id: number;
  email: string;
  work_phone_number: string;
  phone_number: string;
  password: string;
  is_car: boolean;
  is_admin: boolean;
  user_note: string;
  is_deleted: boolean;
}

export interface UserList {
  total_count: number;
  page: number;
  pageSize: number;
  content: User[];
}

// 비밀번호 변경
export interface ChangePwdParams {
  password: string;
  new_password: string;
  check_password: string;
}

interface changePwdResponse {
  user_id: string;
}
// 개인 유저 정보 조회 응답
export interface UserInfo {
  user_id: string;
  user_name: string;
  department: {
    dep_id: number;
    dep_name: string;
  };
  role: {
    role_id: number;
    role_name: string;
  };
  email: string;
  work_phone_number: string;
  phone_number: string;
  is_car: boolean;
  is_admin: boolean;
  user_note: string;
  is_deleted: boolean;
}

//유저 목록
export const getUserList = async (is_deleted: boolean | null) => {
  try {
    const token = localStorage.getItem("token");
    const response = await client.get("/users", {
      headers: {
        Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 포함
      },
      params: {
        is_deleted,
      },
    });
    console.log(response);
    return response.data;
  } catch (error: any) {
    console.log(getErrorMessage(error));
  }
};

//유저 상세보기
export const getUser = async (id: string) => {
  try {
    const response = await client.get(`/user/${id}`);
    console.log(response.data, "response.data")
    return response.data;
  } catch (error: any) {
    console.log(getErrorMessage(error));
  }
};

// 로그인
export const loginUser = async (user: UserTypes) => {
  try {
    const response = await client.post("/login", user);

    if (response.data.data.token) {
      localStorage.setItem("token", response.data.data.token);
    }
    localStorage.setItem("user_name", response.data.data.user_name);
    localStorage.setItem("user_id", response.data.data.user_id);
    localStorage.setItem("is_admin", response.data.data.is_admin.toString());
    localStorage.setItem("user_role", response.data.data.user_role);

    console.log(response, "res");
    return response;
  } catch (error: any) {
    alert(error.message);
  }
};

// 사용자 정보 수정
export const updateUserByAdmin = async (
  id: string,
  user: Partial<RequestUser>
) => {
  try {
    const token = localStorage.getItem("token"); // 로컬 스토리지에서 토큰 가져오기

    const response = await client.patch(`/admin/user/${id}`, user, {
      headers: {
        Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 포함
      },
    });

    console.log(response.data);
    return response.data;
  } catch (error: any) {
    console.log(getErrorMessage(error));
  }
};

// 사용자 정보 업데이트
export const updateUserBySelf = async (
  id: string,
  user: Partial<RequestUser>
) => {
  try {
    const token = localStorage.getItem("token"); // 로컬 스토리지에서 토큰 가져오기

    const response = await client.patch(`/user/${id}`, user, {
      headers: {
        Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 포함
      },
    });

    console.log(response.data);
    return response.data;
  } catch (error: any) {
    alert(getErrorMessage(error));
  }
};

// 사용자 비밀번호 변경
export const updatePassword = async (
  id: string,
  changePassword: ChangePwdParams
) => {
  try {
    const token = localStorage.getItem("token");

    const response = await client.patch(
      `/password/user/${id}`,
      changePassword,
      {
        headers: {
          Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 포함
        },
      }
    );
    return response;
  } catch (error: any) {
    alert(getErrorMessage(error));
  }
};

// 사용자 등록
export const createUser = async (user: Partial<RequestUser>) => {
  try {
    const token = localStorage.getItem("token");
    const response = await client.post(`/users`, user, {
      headers: {
        Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 포함
      },
    });

    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

// 사용자 삭제
export const deleteUser = async (id: string) => {
  try {
    const token = localStorage.getItem("token");

    const response = await client.delete(`/user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 포함
      },
    });

    return response.data;
  } catch (error: any) {
    alert(getErrorMessage(error));
  }
};

// 부서 목록
export const getPartList = async () => {
  try {
    const response = await client.get("/parts");
    return response.data;
  } catch (error: any) {
    alert(getErrorMessage(error));
  }
};

// 부서 등록
export const createPart = async (part: Partial<Department>) => {
  try {
    const token = localStorage.getItem("token");
    const response = await client.post(`/parts`, part, {
      headers: {
        Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 포함
      },
    });

    return response.data;
  } catch (error: any) {
    alert(getErrorMessage(error));
  }
};

// 부서 수정
export const updatePart = async (id: number, part: Partial<Department>) => {
  try {
    const token = localStorage.getItem("token"); // 로컬 스토리지에서 토큰 가져오기

    const response = await client.patch(`/part/${id}`, part, {
      headers: {
        Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 포함
      },
    });
    return response.data;
  } catch (error: any) {
    alert(getErrorMessage(error));
  }
};

// 부서 삭제
export const deletePart = async (id: number) => {
  try {
    const token = localStorage.getItem("token");

    const response = await client.delete(`/part/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 포함
      },
    });

    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

// 직책 목록
export const getRoleList = async () => {
  try {
    const response = await client.get("/roles");
    return response.data;
  } catch (error: any) {
    alert(getErrorMessage(error));
  }
};

// 직책 등록
export const createRole = async (role: Partial<Role>) => {
  try {
    const token = localStorage.getItem("token");
    const response = await client.post(`/roles`, role, {
      headers: {
        Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 포함
      },
    });

    return response.data;
  } catch (error: any) {
    alert(getErrorMessage(error));
  }
};

// 직책 수정
export const updateRole = async (id: number, role: Partial<Role>) => {
  try {
    const token = localStorage.getItem("token"); // 로컬 스토리지에서 토큰 가져오기

    const response = await client.patch(`/role/${id}`, role, {
      headers: {
        Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 포함
      },
    });
    return response.data;
  } catch (error: any) {
    alert(getErrorMessage(error));
  }
};

// 직책 삭제
export const deleteRole = async (id: number) => {
  try {
    const token = localStorage.getItem("token");

    const response = await client.delete(`/role/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 포함
      },
    });

    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};
