import { client, getErrorMessage } from "./client";

export interface RevenueBody {
  data: Revenue[];
  year: number;
}

export interface Revenue {
  year: number;
  product: string;
  user_name: string;
  comp_name: string;
  first_q: number;
  second_q: number;
  third_q: number;
  fourth_q: number;
  jan: number;
  feb: number;
  mar: number;
  apr: number;
  may: number;
  jun: number;
  jul: number;
  aug: number;
  sep: number;
  oct: number;
  nov: number;
  dec: number;
  summary: number;
}

export interface RevenueList {
  total_count: number;
  content: Revenue[];
}

export const getRevenues = async (year: number, user_id: string | null) => {
  try {
    const response = await client.get("/revenues", {
      params: { year, user_id },
    });
    console.log(response.data, "response");
    return response.data.revenues;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

export const uploadRevenues = async (data: RevenueBody) => {
  try {
    const token = localStorage.getItem("token");
    const response = await client.post("/revenues", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log(response.data, "response");
    return response.data;
  } catch (error) {
    console.error("매출 데이터 업로드 실패:", error);
    throw error;
  }
};
