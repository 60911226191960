import { CancelButton, SaveButton } from "components/Common";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { checkAdminState, searchRangeDateState } from "recoil/stateStore";
import { ReactComponent as CalendarIcon } from "assets/writeIcon/calendar.svg";
import { ReactComponent as CarIcon } from "assets/writeIcon/car.svg";
import { ReactComponent as PurposeIcon } from "assets/writeIcon/purpose.svg";
import { ReactComponent as UserIcon } from "assets/writeIcon/member.svg";
import { ReactComponent as StartIcon } from "assets/writeIcon/start.svg";
import { ReactComponent as EndIcon } from "assets/writeIcon/end.svg";
import { ReactComponent as DistanceIcon } from "assets/writeIcon/distance.svg";
import { ReactComponent as NoteIcon } from "assets/writeIcon/note.svg";
import { DialogText } from "components/DialogText";
import { CarLogList, ResponseCarLog, getCarLogs, postCarLog } from "api/carLog";
import { useErrorHandler } from "components/ErrorHandler";
import { ResponseCar, getCar, getCarList } from "api/car";

interface CarLogWriteProps {
  onClose: (event?: any) => void;
  onSubmitSuccess: (e?: any) => void;
}

const CarLogWrite = ({ onClose, onSubmitSuccess }: CarLogWriteProps) => {
  const today = new Date();
  const [carList, setCarList] = useState<ResponseCar[]>([]);
  const loginUsername = localStorage.getItem("user_name");
  const loginUserIdStr = localStorage.getItem("user_id");
  const loginUserId: string = loginUserIdStr ? loginUserIdStr : "";
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [carDate, setCarDate] = useState<string>(
    today.toISOString().split("T")[0]
  );
  const [purpose, setPurpose] = useState<string>("");
  const [purposeDetail, setPurposeDetail] = useState<string>("");
  const [carId, setCarId] = useState<number>(-1);
  const [locStart, setLocStart] = useState<string>("");
  const [locEnd, setLocEnd] = useState<string>("");
  const [accStart, setAccStart] = useState<number>(0);
  const [accEnd, setAccEnd] = useState<number>(0);
  const [note, setNote] = useState<string>("");
  const [distance, setDistance] = useState<number>(0);
  const [checkAdmin, setCheckAdmin] = useRecoilState(checkAdminState);
  const handleError = useErrorHandler();
  const [CarLogList, setCarLogList] = useState<CarLogList[]>([]);
  const rangeDate = useRecoilValue(searchRangeDateState);
  const [workDis, setWorkDis] = useState<number>(0);

  useEffect(() => {
    async function fetchCars() {
      try {
        const carsData = await getCarList(false);
        setCarList(carsData.data.content);
      } catch (error) {
        handleError(error);
      }
    }
    fetchCars();
  }, []);

  useEffect(() => {
    const isAdmin = localStorage.getItem("is_admin") || "";
    console.log(isAdmin);
    if (isAdmin !== null) {
      setCheckAdmin(isAdmin); // Only set if not null
    } else {
      setCheckAdmin("false"); // Default or fallback value
    }
  }, []);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(e.target.value);
    console.log(value, "carId");
    setCarId(value);
  };

  useEffect(() => {
    async function fetchCarInfo() {
      if (carId === null || carId !== -1) {
        return;
      }
      try {
        const carData = await getCar(carId);
        console.log(carData, "cardata");
        setWorkDis(carData.data.work_drive_distance);
      } catch (error) {
        handleError(error);
      }
    }
    if (carId !== -1) fetchCarInfo();
  });

  useEffect(() => {
    const callCarLogs = async () => {
      try {
        const response = await getCarLogs(null, carId, "", "");
        console.log(response, "res");
        if (!response || response.data.total_count === 0) {
          setAccStart(0);
          setLocStart("");
          setAccEnd(0);
          setLocEnd("");
        } else {
          console.log(response.data.content, "d234");
          const latestLog = response.data.content.sort(
            (a: ResponseCarLog, b: ResponseCarLog) =>
              new Date(b.car_date).getTime() - new Date(a.car_date).getTime()
          )[0];
          // setLocEnd(latestLog.loc_end);
          // setAccEnd(latestLog.acc_end);
          setLocStart(response.data.content[0].loc_end);
          setAccStart(response.data.content[0].acc_end);
        }
      } catch (error) {
        handleError(error);
      }
    };
    callCarLogs();
  }, [setCarLogList, carId, rangeDate]);

  // 주행거리 자동계산
  const handleAccEnd = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setAccEnd(Number(value));
    setDistance(Number(value) - accStart);
  };

  // 도착거리 자동계산
  const handleDistance = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDistance(Number(value));
    setAccEnd(accStart + Number(value));
  };

  // 취소버튼
  const onCancel = () => {
    // setOpenDialog(true);
    onClose();
  };

  // 저장버튼
  const handleSubmit = async () => {
    console.log("차량운행일지 등록");
    if (
      carId === null ||
      purpose === "" ||
      locStart === "" ||
      locEnd === "" ||
      distance === 0
    ) {
      alert("필수값이 입력되지 않았습니다.");
    } else if (distance < 0) {
      alert("주행거리가 옳지 않습니다. 주행거리는 0 이상 값입니다.");
    } else {
      try {
        console.log(carDate, "carDate");
        const data = {
          car_id: carId,
          car_date: carDate,
          purpose: purpose,
          purpose_detail: purposeDetail,
          // dep_id: ,
          // dep_name: "",
          user_id: loginUserId,
          user_name: loginUsername,
          loc_start: locStart,
          acc_start: accStart,
          loc_end: locEnd,
          acc_end: accEnd,
          drive_distance: distance,
          work_drive_distance:
            purpose === "BUSINESS" ? workDis + distance : workDis,
          carlog_note: note,
          is_approval: false,
        };
        const newCarLog = await postCarLog(data);
        console.log(newCarLog, "newCarLog");
        onSubmitSuccess();
      } catch (error) {
        handleError(error); // 오류 처리 함수 호출
      }
    }
  };

  const handleConsDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setCarDate(value);
  };

  return (
    <div className="write_list_wrapper">
      <div className="work_option">
        <div className="write_date">
          <div className="div_icon" style={{ marginRight: "15px" }}>
            <CalendarIcon style={{ marginRight: "4px" }} />
            일시
          </div>
          <input
            type="date"
            name="car_date"
            value={carDate}
            style={{ marginLeft: "15px", width: "fit-content" }}
            onChange={handleConsDate}
          />
        </div>
        <div>
          <div className="div_icon">
            <CarIcon style={{ marginRight: "4px" }} />
            차량
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <select
            value={carId}
            // onChange={({ target }) => setCarId(parseInt(target.value))}
            onChange={handleSelectChange}
          >
            <option key="-1">선택하세요.</option>
            {carList?.map((car) => (
              <option key={car.car_id} value={car.car_id}>
                ({car.car_model}) {car.car_num}
              </option>
            ))}
          </select>
        </div>
        <div>
          <div className="div_icon">
            <PurposeIcon style={{ marginRight: "4px" }} />
            사용목적
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <select
            value={purpose}
            onChange={({ target }) => setPurpose(target.value)}
          >
            <option value="">선택하세요.</option>
            <option value="BUSINESS">업무용</option>
            <option value="NON_BUSINESS">비업무용</option>
            <option value="OTHER">기타</option>
          </select>
        </div>
        <div>
          <div className="div_icon"></div>
          <input
            type="text"
            disabled={purpose === "OTHER" ? false : true}
            value={purposeDetail}
            placeholder="기타 입력"
            onChange={({ target }) => setPurposeDetail(target.value)}
          />
        </div>

        <div>
          <div className="div_icon">
            <UserIcon style={{ marginRight: "4px" }} />
            운전자
          </div>
          <span>{loginUsername}</span>
        </div>
        <div>
          <div className="div_icon">
            <StartIcon style={{ marginRight: "4px" }} />
            출발지
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <input
            type="text"
            value={locStart}
            onChange={({ target }) => setLocStart(target.value)}
            placeholder="출발지를 입력해주세요"
          />
        </div>
        <div>
          <div className="div_icon">
            <EndIcon style={{ marginRight: "4px" }} />
            도착지
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <input
            type="text"
            value={locEnd}
            onChange={({ target }) => setLocEnd(target.value)}
            placeholder="도착지를 입력해주세요"
          />
        </div>
        <div>
          <div className="div_icon" style={{ color: "rgb(0,0,0,0.6)" }}>
            출발 전 주행거리
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <input
            type="number"
            value={accStart}
            onChange={({ target }) => setAccStart(parseInt(target.value))}
            onWheel={(e) => e.currentTarget.blur()}
            onFocus={(e) => {
              e.target.select();
            }}
          />
        </div>
        <div>
          <div className="div_icon" style={{ color: "rgb(0,0,0,0.6)" }}>
            도착 후 주행거리
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <input
            type="number"
            value={accEnd}
            placeholder="0"
            onChange={handleAccEnd}
            onWheel={(e) => e.currentTarget.blur()}
            onFocus={(e) => {
              e.target.select();
            }}
          />
        </div>
        <div>
          <div className="div_icon">
            <DistanceIcon style={{ marginRight: "4px" }} />
            주행거리
          </div>
          <input
            type="number"
            value={distance}
            placeholder="0"
            onChange={handleDistance}
            onWheel={(e) => e.currentTarget.blur()}
            onFocus={(e) => {
              e.target.select();
            }}
          />
        </div>
        <div className="textarea_wrapper">
          <div className="div_icon">
            <NoteIcon style={{ marginRight: "4px" }} /> 비고
          </div>
          <textarea
            value={note}
            style={{ color: "#000", marginTop: "5px" }}
            name="carlog_note"
            onChange={({ target }) => setNote(target.value)}
            disabled={false}
          />
        </div>
      </div>
      <div className="write_bottom">
        <>
          <CancelButton onClickHandler={onCancel} />
          <DialogText
            open={openDialog}
            content="변경 사항이 저장되지 않을 수 있습니다. 계속하시겠습니까?"
            onCancel={() => setOpenDialog(false)}
            onClose={onClose}
          />
          <SaveButton onClickHandler={handleSubmit} />
        </>
      </div>
    </div>
  );
};
export default CarLogWrite;
