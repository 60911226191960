import { SvgIcon } from "@mui/material";

const ExcelIcon: React.FC<{ fill: string }> = ({ fill }) => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 40 40"
        width="80px"
        height="80px"
      >
        <path
          fill="#fff"
          d="M38.5,5.5H22v29.1h16.5c0.6,0,1-0.4,1-1V6.5C39.5,5.9,39.1,5.5,38.5,5.5z"
        />
        <path fill="#98ccfd" d="M24.5 38L2.5 34 2.5 6 24.5 2z" />
        <path
          fill="#fff"
          d="M16.1,27l-2.4-4.6c-0.1-0.3-0.2-0.6-0.3-0.9l0,0c-0.1,0.3-0.2,0.7-0.3,1L10.6,27H6.9l4.5-7l-4.1-7h3.8l2,4.2 c0.2,0.4,0.3,0.8,0.4,1.2l0,0c0.1-0.3,0.2-0.7,0.4-1.2l2.3-4.2h3.5l-4.2,6.9l4.3,7.1H16.1z"
        />
        <path
          fill="#dff0fe"
          d="M37 15h-8v-4h8V15zM30 14h6v-2h-6V14zM28 15L24.6 15 24.6 14 27 14 27 12 24.6 12 24.6 11 28 11zM37 20h-8v-4h8V20zM30 19h6v-2h-6V19zM37 25h-8v-4h8V25zM30 24h6v-2h-6V24zM37 30h-8v-4h8V30zM30 29h6v-2h-6V29zM28 20L24.6 20 24.6 19 27 19 27 17 24.6 17 24.6 16 28 16zM28 25L24.6 25 24.6 24 27 24 27 22 24.6 22 24.6 21 28 21zM28 30L24.6 30 24.6 29 27 29 27 27 24.6 27 24.6 26 28 26z"
        />
        <path
          fill="#4788c7"
          d="M19.8 28h-3.7c-.2 0-.4-.1-.5-.3L13.4 23l-2.3 4.7C11 27.9 10.9 28 10.7 28H6.9c-.2 0-.3-.1-.4-.3s-.1-.3 0-.5l4.3-7.3-4-7.2c-.1-.2-.1-.3 0-.5C6.9 12.1 7.1 12 7.3 12h3.8c.2 0 .4.1.5.3l2 4.6 2.2-4.6c.1-.2.3-.3.5-.3h3.5c.2 0 .3.1.4.2.1.2.1.3 0 .5l-4.1 7.2 4.2 7.3c.1.2.1.3 0 .5C20.2 27.9 20 28 19.8 28zM16.4 27H19l-3.9-6.8c-.1-.2-.1-.3 0-.5l3.8-6.7h-2.3l-2.1 4.4c-.2.5-.3.9-.4 1.1-.1.2-.2.4-.5.4-.2 0-.4-.2-.5-.4-.1-.4-.2-.7-.4-1.1L10.8 13H8.1l3.7 6.8c.1.2.1.3 0 .5l-4 6.7h2.6l2.3-4.7c.1-.3.2-.6.3-.9.1-.2.3-.4.5-.4.2 0 .4.2.5.4.1.3.1.6.3.8L16.4 27zM38.5 35H24.4v-1h14.1c.3 0 .5-.2.5-.5v-27C39 6.2 38.8 6 38.5 6H24V5h14.5C39.3 5 40 5.7 40 6.5v27C40 34.3 39.3 35 38.5 35z"
        />
        <path
          fill="#4788c7"
          d="M25,38.6L2,34.4V5.6l23-4.2V38.6z M3,33.6l21,3.8V2.6L3,6.4V33.6z"
        />
      </svg>
    </SvgIcon>
  );
};

export default ExcelIcon;
