import { ChangeEvent } from "react";

interface Option {
  id: number | string;
  name: string;
}

interface SelectInputProps {
  label?: string;
  labelIcon?: React.ReactNode;
  options: Option[];
  value: number | string;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  id: string;
}

export const SelectInput: React.FC<SelectInputProps> = ({
  label,
  labelIcon,
  options,
  value,
  onChange,
  id,
}) => {
  const isInvalid = value === "";
  return (
    <>
      {label && (
        <label htmlFor={id} className="div_icon" style={{ marginRight: "4px" }}>
          {labelIcon && (
            <span style={{ marginRight: "4px", width: "24px", height: "24px" }}>
              {labelIcon}
            </span>
          )}
          {label}
        </label>
      )}
      <select
        id={id}
        value={value}
        onChange={onChange}
        className={`custom-select ${isInvalid ? "invalid" : ""}`}
      >
        <option value="">선택하세요</option>
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
    </>
  );
};
