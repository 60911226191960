import { ResponseWorkLog, getWorkLog } from "api/workLog";
import WorkLogDrawer from "components/WorkLogDrawer";
import EditWorkForm from "components/editForm/EditWorkForm";
import ViewSalesForm from "components/viewForm/ViewSalesForm";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { isEditedState } from "recoil/stateStore";

interface FormDrawerProps {
  open: boolean;
  onClose: (event: any) => void;
  workId: number;
  setUpdatedData: Function;
  setDeletedId: Function;
}

const ViewSalesDrawer: React.FC<FormDrawerProps> = ({
  open,
  onClose,
  workId,
  setUpdatedData,
  setDeletedId,
}) => {
  const isEdit = useRecoilValue(isEditedState);
  const [workLogData, setWorkLogData] = useState<ResponseWorkLog>(
    {} as ResponseWorkLog
  );

  useEffect(() => {
    const callWorkLog = async () => {
      const workData = await getWorkLog(workId);
      setWorkLogData(workData.data);
      setUpdatedData(workData.data);
      console.log(workData.data, "workData");
    };
    if (workId !== -1 && !isEdit) {
      callWorkLog();
    }
  }, [workId, isEdit, setUpdatedData]);

  return (
    <WorkLogDrawer open={open} onClose={onClose}>
      {isEdit ? (
        <div className="write_head">영업일지 수정</div>
      ) : (
        <div className="write_head">영업일지 상세보기</div>
      )}
      {isEdit ? (
        <EditWorkForm
          workLogData={workLogData}
          setWorkLogData={setWorkLogData}
        />
      ) : (
        <ViewSalesForm
          workLogData={workLogData}
          onClose={onClose}
          setDeletedId={setDeletedId}
        />
      )}
    </WorkLogDrawer>
  );
};

export default ViewSalesDrawer;
