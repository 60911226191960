import { useRecoilValue } from "recoil";
import { autoSelectCompIdState } from "recoil/stateStore";
import { useEffect, useState } from "react";
import { CancelButton, SaveButton } from "components/Common";
import { ReactComponent as ProgressIcon } from "assets/writeIcon/progress.svg";
import { ReactComponent as CalendarIcon } from "assets/writeIcon/calendar.svg";
import { ReactComponent as CompanyIcon } from "assets/writeIcon/company.svg";
import { ReactComponent as MemberIcon } from "assets/writeIcon/user.svg";
import { ReactComponent as PhoneIcon } from "assets/writeIcon/phone.svg";
import { ReactComponent as ProjectIcon } from "assets/writeIcon/project.svg";
import { ReactComponent as ItemIcon } from "assets/writeIcon/item.svg";
import { ReactComponent as CountIcon } from "assets/writeIcon/count.svg";
import { ReactComponent as PriceIcon } from "assets/writeIcon/price.svg";
import { ReactComponent as SumIcon } from "assets/writeIcon/sum.svg";
import { ReactComponent as UserIcon } from "assets/writeIcon/user.svg";
import { ReactComponent as ResultIcon } from "assets/writeIcon/result.svg";
import { Member, getCompanyMember, getCompanyMembers } from "api/member";
import AutoCompleteCompany from "components/AutoCompleteCompany";
import { useErrorHandler } from "components/ErrorHandler";
import { createCons } from "api/counseling";

interface WorkLogWriteProps {
  onClose: (event?: any) => void;
  onSubmitSuccess: (e?: any) => void;
}

const CounselingWrite = ({ onClose, onSubmitSuccess }: WorkLogWriteProps) => {
  const today = new Date();
  const [members, setMembers] = useState<Member[]>([]);
  const [memberPhone, setMemeberPhone] = useState<string>("");
  const autoSelectCompId = useRecoilValue(autoSelectCompIdState);
  const handleError = useErrorHandler();
  const loginUserIdStr = localStorage.getItem("user_id");
  const loginUserId: string = loginUserIdStr ? loginUserIdStr : "";
  const loginUsernameStr = localStorage.getItem("user_name");
  const loginUsername: string = loginUsernameStr ? loginUsernameStr : "";
  const [date, setDate] = useState<string>(today.toISOString().split("T")[0]);
  const [compId, setCompId] = useState<number>(-1);
  const [memberId, setMemberId] = useState<number>(-1);
  const [project, setProject] = useState<string>("");
  const [item, setItem] = useState<string>("");
  const [amount, setAmount] = useState<number>(0);
  const [price, setPrice] = useState<number>(0);
  const [result, setResult] = useState<string>("NONE");
  const [note, setNote] = useState<string>("");

  useEffect(() => {
    if (autoSelectCompId) {
      setCompId(autoSelectCompId);
      const callMembers = async () => {
        try {
          const response = await getCompanyMembers(autoSelectCompId);
          const memberData = response.data.content;
          setMembers(memberData.member_list);
        } catch (error) {
          console.log("no members");
        }
      };
      callMembers();
    }
  }, [autoSelectCompId]);

  useEffect(() => {
    const callMember = async () => {
      const response = await getCompanyMember(compId, memberId);
      const memberData = response.data;
      setMemeberPhone(memberData.member_phone);
    };
    if (memberId !== -1) {
      callMember();
    }
  }, [memberId]);

  // 취소버튼
  const onCancel = () => {
    onClose();
  };

  // 저장버튼
  const handleSubmit = async () => {
    console.log("상담일지 등록");
    if (
      compId === null ||
      compId === -1 ||
      project === "" ||
      item === "" ||
      amount === 0 ||
      price === 0 ||
      result === ""
    ) {
      alert("필수값이 입력되지 않았습니다.");
    } else if (memberId === -1) {
      alert("업체 담당자가 선택되지 않았습니다.");
    } else {
      try {
        const data = {
          cons_date: date,
          user_id: loginUserId,
          user_name: loginUsername,
          comp_id: compId,
          member_id: memberId,
          project: project,
          item: item,
          amount: amount,
          price: price,
          result: result,
          cons_note: note,
        };
        await createCons(data);
        // setCounselingList((prev) => [...prev, data]);
        // successSave();
        onSubmitSuccess();
      } catch (error) {
        handleError(error); // 오류 처리 함수 호출
      }
    }
  };

  const handleConsDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDate(value);
  };

  return (
    <div className="write_list_wrapper">
      <div className="work_option">
        <div className="write_date">
          <div className="div_icon" style={{ marginRight: "15px" }}>
            <CalendarIcon style={{ marginRight: "4px" }} />
            일시
          </div>
          <input
            type="date"
            name="cons_date"
            value={date}
            style={{ marginLeft: "15px", width: "fit-content" }}
            onChange={handleConsDate}
          />
        </div>
        <div>
          <div className="div_icon">
            <UserIcon style={{ marginRight: "4px" }} />
            작성자
          </div>
          <span style={{ paddingLeft: "5px" }}>
            {loginUsername} ({loginUserIdStr})
          </span>
        </div>
        <div>
          <div className="div_icon">
            <CompanyIcon style={{ marginRight: "4px" }} />
            업체명
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <AutoCompleteCompany />
        </div>
        <div>
          <div className="div_icon">
            <MemberIcon style={{ marginRight: "4px" }} />
            업체 담당자
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <select
            disabled={compId === null || compId === -1}
            value={memberId != null && memberId != -1 ? memberId : -1}
            onChange={({ target }) => setMemberId(parseInt(target.value))}
            style={{ paddingLeft: "5px" }}
          >
            {compId === null || compId === -1 ? (
              <option value="-1">업체명을 먼저 선택하세요</option>
            ) : (
              <>
                <option value="-1">선택하세요</option>
                {members.map((member) => (
                  <option key={member.member_id} value={member.member_id}>
                    {member.member_name} {member.member_role}
                  </option>
                ))}
              </>
            )}
          </select>
        </div>
        <div className="write_member_phone">
          <div className="div_icon">
            <PhoneIcon style={{ marginRight: "4px" }} />
            연락처
          </div>
          <div className="member_phone">
            <>
              <input
                type="text"
                readOnly
                placeholder="010"
                style={{ textAlign: "center" }}
                value={memberPhone.substring(0, 3)}
              />
              <span>-</span>
              <input
                type="text"
                readOnly
                placeholder="1234"
                style={{ textAlign: "center" }}
                value={memberPhone.substring(4, 8)}
              />
              <span>-</span>
              <input
                type="text"
                readOnly
                placeholder="5678"
                style={{ textAlign: "center" }}
                value={memberPhone.substring(9, 13)}
              />
            </>
          </div>
        </div>
        <div className="textarea_wrapper">
          <div className="div_icon">
            <ProjectIcon style={{ marginRight: "4px" }} />
            프로젝트
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <textarea
            name="project"
            value={project}
            style={{ color: "#000", marginTop: "5px", height: "80px" }}
            onChange={({ target }) => setProject(target.value)}
            disabled={false}
          />
        </div>
        <div className="textarea_wrapper">
          <div className="div_icon">
            <ItemIcon style={{ marginRight: "4px" }} />
            품목
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <textarea
            value={item}
            name="text"
            style={{ color: "#000", marginTop: "5px", height: "80px" }}
            onChange={({ target }) => setItem(target.value)}
            disabled={false}
          />
        </div>
        <div>
          <div className="div_icon">
            <CountIcon style={{ marginRight: "4px" }} />
            수량
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <input
            type="number"
            value={amount}
            onChange={({ target }) => setAmount(parseInt(target.value))}
          />
        </div>
        <div>
          <div className="div_icon">
            <PriceIcon style={{ marginRight: "4px" }} />
            단가
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <input
            type="number"
            className="input_price"
            value={price}
            onChange={({ target }) => setPrice(parseInt(target.value))}
          />
        </div>
        <div className="write_cons_sum">
          <div className="div_icon">
            <SumIcon style={{ marginRight: "4px" }} />
            합계
          </div>
          <input
            type="number"
            value={amount !== 0 && price !== 0 ? Number(amount * price) : 0}
          />
        </div>
        <div className="textarea_wrapper">
          <div className="div_icon">
            <ResultIcon style={{ marginRight: "4px" }} /> 비고
          </div>
          <textarea
            value={note}
            style={{ color: "#000", marginTop: "5px", height: "80px" }}
            name="cons_note"
            onChange={({ target }) => setNote(target.value)}
            disabled={false}
          />
        </div>
        <div className="work_process">
          <div className="div_icon">
            <ProgressIcon style={{ marginRight: "4px" }} />
            진행상황
          </div>
          <select
            value={result}
            onChange={({ target }) => setResult(target.value)}
          >
            <option value="NONE">예정</option>
            <option value="INPROGRESS">진행중</option>
            <option value="DROP">미진행</option>
            <option value="COMPLETE">완료</option>
          </select>
        </div>
      </div>
      <div className="write_bottom">
        <>
          <CancelButton onClickHandler={onCancel} />
          <SaveButton onClickHandler={handleSubmit} />
        </>
      </div>
    </div>
  );
};
export default CounselingWrite;
