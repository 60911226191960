import axios, { Axios } from "axios";
import { client, getErrorMessage } from "./client";
import { User } from "./user";
import { response } from "express";

export interface ResponseCar {
  car_id: number;
  car_model: string;
  car_num: string;
  is_personal: boolean;
  home_address: string;
  work_address: string;
  distance: number;
  work_drive_distance: number;
  car_note: string;
  user?: User;
  user_id: string;
  is_deleted: boolean;
}

// 응답: 업무일지 조회
export interface CarList {
  total_count: number;
  content: ResponseCar[];
}

//자동차 목록
export const getCarList = async (is_deleted: boolean | null) => {
  try {
    const response = await client.get("/cars", {
      params: { is_deleted },
    });

    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

//자동차 조회
export const getCar = async (car_id: number) => {
  try {
    const response = await client.get(`/car/${car_id}`);
    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

// 자동차 등록
export const postCar = async (newCarData: Partial<ResponseCar>) => {
  try {
    const token = localStorage.getItem("token");
    const response = await client.post("/cars", newCarData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

// 자동차 업데이트
export const patchCar = async (car_id: number, updatrCar: ResponseCar) => {
  try {
    const token = localStorage.getItem("token");
    const response = await client.patch(`/car/${car_id}`, updatrCar, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

// 자동차 삭제
export const deleteCar = async (car_id: number) => {
  try {
    const token = localStorage.getItem("token");
    const response = await client.delete(`/car/${car_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
    return response;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

//자동차 목록
export const getActiveCar = async (car_id: number) => {
  try {
    const response = await client.get(`/car/${car_id}/active`);
    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};
