import { useEffect, useState } from "react";
import { getUserList, User } from "api/user";
import { useRecoilState, useRecoilValue } from "recoil";
import { sidebarTitleState } from "recoil/commonState";
import Card from "@mui/material/Card";
import { textFilterParams } from "components/Common";
import "styles/AddUserWrite.css";

//Icon
import { ReactComponent as UserIcon } from "assets/user.svg";
import { ReactComponent as GroupIcon } from "assets/groups.svg";
import { isEditedState, loginUserInfoState } from "recoil/stateStore";
import Grid from "components/Grid";
import CreateWorkDrawer from "components/drawer/CreateDrawer";
import ViewUserDrawer from "components/drawer/ViewUserDrawer";
import DialogText from "components/DialogText";

interface RowData {
  user_id: string;
  user_name: string;
  department: string;
  role: string;
  email: string;
  work_phone_number: string;
  phone_number: string;
  is_car: boolean;
  is_admin: boolean;
  user_note: string;
  is_deleted: boolean;
}

export default function UserInfo() {
  const [rowData, setRowData] = useState<RowData[]>([]);
  const sidebarTitle = useRecoilValue(sidebarTitleState);
  const loginUserInfo = useRecoilValue(loginUserInfoState);
  const [userDrawerOpen, setUserDrawerOpen] = useState<string>("");
  const [userInfoDrawerOpen, setUserInfoDrawerOpen] = useState<boolean>(false);
  const [partRoleDrawerOpen, setPartRoleDrawerOpen] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<number>(-1);
  const [isEdit, setIsEdit] = useRecoilState(isEditedState);
  const [openDialog, setOpenDialog] = useState(false);
  const [updatedData, setUpdatedData] = useState<User>();
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [deletedId, setDeletedId] = useState<number>(-1);
  const [viewSize, setViewSize] = useState<number>(10);

  useEffect(() => {
    const callUserList = async () => {
      try {
        const { data } = await getUserList(null);
        const mappedData = data.content.map((item: User) => ({
          user_id: parseInt(item.user_id),
          user_name: item.user_name,
          department: item.department?.dep_name,
          role: item.role?.role_name,
          email: item.email,
          work_phone_number: item.work_phone_number,
          phone_number: item.phone_number,
          is_car: item.is_car, // 변환된 필드
          is_admin: item.is_admin, // 변환된 필드
          user_note: item.user_note,
          is_deleted: item.is_deleted,
        }));
        setRowData(mappedData);
        setIsSaved(false);
        console.log(data.content, "UserList");
      } catch (error) {
        console.error("Error fetching work logs:", error);
      }
    };
    callUserList();
  }, [setRowData, userInfoDrawerOpen]);

  const closeDrawer = () => {
    if (userDrawerOpen === "view" && !isEdit) {
      closeDialog();
    } else if (partRoleDrawerOpen) {
      closeDialog();
    } else {
      setOpenDialog(true);
    }
  };

  const handleSubmitSuccess = () => {
    setIsSaved(true);
    closeDialog();
  };

  const handleSetOpen = (status: boolean, type: string) => {
    console.log(type, "type");
    if (type === "newUser") {
      setUserInfoDrawerOpen(true);
      setUserDrawerOpen(type);
      setSelectedUserId(-1);
    } else if (type === "partRole") {
      setPartRoleDrawerOpen(true);
      setUserDrawerOpen(type);
    } else if (type === "user") {
      setUserInfoDrawerOpen(true);
      setUserDrawerOpen("view");
    }
  };

  const closeDialog = () => {
    setUserInfoDrawerOpen(false);
    setPartRoleDrawerOpen(false);
    setOpenDialog(false);
    setIsEdit(false);
  };

  const selectedId = (type: string, id: number) => {
    setSelectedUserId(id);
    setUserInfoDrawerOpen(true);
    console.log(userDrawerOpen, selectedUserId, "selectedUserId");
  };

  const baseColumnDefs = [
    {
      headerName: "사번",
      field: "user_id",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 0.7,
    },
    {
      headerName: "성명",
      field: "user_name",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 0.8,
    },
    {
      headerName: "부서",
      field: "department",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 1,
    },
    {
      headerName: "직책",
      field: "role",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 0.8,
    },
    {
      headerName: "연락처(회사)",
      field: "work_phone_number",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 1,
    },
    {
      headerName: "연락처(개인)",
      field: "phone_number",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 1,
    },
    {
      headerName: "차량등록여부",
      field: "is_car",
      filter: "agBooleanColumnFilter",
      flex: 0.8,
      cellRenderer: (params: any) => (params.value ? "O" : "X"),
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "삭제여부",
      field: "is_deleted",
      filter: "agBooleanColumnFilter",
      flex: 0.8,
      cellRenderer: (params: any) => (params.value ? "삭제됨" : ""),
      cellStyle: { textAlign: "center" },
    },
  ];

  // 사용자 관리일 때 추가할 열 정의
  const userManagementColumnDefs = [
    {
      headerName: "관리자 여부",
      field: "is_admin",
      filter: "agBooleanColumnFilter",
      cellRenderer: (params: any) => (params.value ? "O" : "X"),
      flex: 0.8,
      minWidth: 40,
      cellStyle: { textAlign: "center" },
    },
  ];

  const columnDefs = [
    ...baseColumnDefs,
    ...(loginUserInfo.is_admin ? userManagementColumnDefs : []),
  ];
  const pageSizeChange = (size: number) => {
    setViewSize(size);
  };

  return (
    <>
      <div className="mainTop_wrapper">
        <div className="title">
          {sidebarTitle === "사용자정보" ? (
            <div className="menu">Company information</div>
          ) : (
            <div className="menu">Manage</div>
          )}
          <div className="sub_menu">
            <div className="sub_menu__title">
              <h1>사용자정보</h1>
            </div>
            {loginUserInfo.is_admin && (
              <div className="write_button__wrapper">
                <button
                  className="write_button"
                  onClick={() => handleSetOpen(true, "partRole")}
                >
                  <GroupIcon />
                  부서/직책 관리
                </button>
                <button
                  className="write_button"
                  onClick={() => handleSetOpen(true, "newUser")}
                >
                  <UserIcon />
                  신규 사용자 등록
                </button>
              </div>
            )}
          </div>
        </div>

        <Card id={"main_card"}>
          <Grid
            columnDefs={columnDefs}
            rowData={rowData}
            type="user"
            setOpen={handleSetOpen}
            setId={selectedId}
            newData={updatedData}
            deletedId={deletedId}
          />
        </Card>
        <CreateWorkDrawer
          open={userDrawerOpen === "newUser" && userInfoDrawerOpen}
          onClose={closeDrawer}
          onSubmitSuccess={handleSubmitSuccess}
          type={"UserInfo"}
        />
        <CreateWorkDrawer
          open={userDrawerOpen === "partRole" && partRoleDrawerOpen}
          onClose={closeDrawer}
          onSubmitSuccess={handleSubmitSuccess}
          type={"PartRole"}
        />
        <ViewUserDrawer
          open={userDrawerOpen === "view" && userInfoDrawerOpen}
          onClose={closeDrawer}
          userId={selectedUserId}
          setUpdatedData={setUpdatedData}
          setDeletedId={setDeletedId}
        />
        <DialogText
          open={openDialog}
          content="변경 사항이 저장되지 않을 수 있습니다. 계속하시겠습니까?"
          onCancel={() => setOpenDialog(false)}
          onClose={closeDialog}
        />
      </div>
    </>
  );
}
