import WorkLogDrawer from "components/WorkLogDrawer";
import WorkLogWrite from "components/writeForm/WorkLogWrite";
import CounselingWrite from "components/writeForm/CounselingWrite";
import MonthPlanWrite from "components/writeForm/MonthPlanWrite";
import CarLogWrite from "components/writeForm/CarLogWrite";
import CompanyWrite from "components/writeForm/CompanyWrite";
import YearPlanWrite from "components/writeForm/YearPlanWrite";
import MemberWrite from "components/writeForm/MemberWrite";
import NewCarWrite from "components/writeForm/NewCarWrite";
import NewUserWrite from "components/writeForm/NewUserWrite";
import NewPartRole from "components/writeForm/NewPartRole";

interface FormDrawerProps {
  open: boolean;
  onClose: (event: any) => void;
  type: string;
  onSubmitSuccess: (event: any) => void;
}

const CreateWorkDrawer: React.FC<FormDrawerProps> = ({
  open,
  onClose,
  type,
  onSubmitSuccess,
}) => {
  let content;
  switch (type) {
    case "WorkLog":
      content = (
        <WorkLogWrite onClose={onClose} onSubmitSuccess={onSubmitSuccess} />
      );
      break;
    case "Company":
      content = (
        <CompanyWrite onClose={onClose} onSubmitSuccess={onSubmitSuccess} />
      );
      break;
    case "Member":
      content = (
        <MemberWrite onClose={onClose} onSubmitSuccess={onSubmitSuccess} />
      );
      break;
    case "Counseling":
      content = (
        <CounselingWrite onClose={onClose} onSubmitSuccess={onSubmitSuccess} />
      );
      break;
    case "CarLog":
      content = (
        <CarLogWrite onClose={onClose} onSubmitSuccess={onSubmitSuccess} />
      );
      break;
    case "MonthPlan":
      content = (
        <MonthPlanWrite onClose={onClose} onSubmitSuccess={onSubmitSuccess} />
      );
      break;
    case "YearPlan":
      content = (
        <YearPlanWrite onClose={onClose} onSubmitSuccess={onSubmitSuccess} />
      );
      break;
    case "CarInfo":
      content = (
        <NewCarWrite onClose={onClose} onSubmitSuccess={onSubmitSuccess} />
      );
      break;
    case "UserInfo":
      content = (
        <NewUserWrite onClose={onClose} onSubmitSuccess={onSubmitSuccess} />
      );
      break;
    case "PartRole":
      content = <NewPartRole onClose={onClose} />;
      break;
    default:
      content = null;
      break;
  }

  return (
    <WorkLogDrawer open={open} onClose={onClose}>
      <div className="write_head">
        {type === "WorkLog" && "업무일지 등록"}
        {type === "Company" && "신규업체 등록"}
        {type === "Member" && "업체 담당자 등록"}
        {type === "Counseling" && "신규 상담 등록"}
        {type === "CarLog" && "차량운행일지 등록"}
        {type === "MonthPlan" && "월간계획서 작성"}
        {type === "YearPlan" && "연간계획서 작성"}
        {type === "CarInfo" && "차량 등록"}
        {type === "UserInfo" && "사용자 등록"}
        {type === "PartRole" && "부서/직책 관리"}
      </div>
      {content}
    </WorkLogDrawer>
  );
};

export default CreateWorkDrawer;
