import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import { getCarList, ResponseCar } from "api/car";
import { kmFormatter, textFilterParams } from "components/Common";
import { sidebarTitleState } from "recoil/commonState";
import { useRecoilState, useRecoilValue } from "recoil";
import { ReactComponent as CarIcon } from "assets/car.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { isEditedState, loginUserInfoState } from "recoil/stateStore";
import Grid from "components/Grid";
import CreateWorkDrawer from "components/drawer/CreateDrawer";
import ViewCarDrawer from "components/drawer/ViewCarDrawer";
import DialogText from "components/DialogText";

interface RowData {
  car_id: number;
  car_model: string;
  car_num: string;
  is_personal: string;
  home_address: string;
  work_address: string;
  distance: number;
  car_note: string;
  user: string;
  is_deleted: boolean;
}

export default function CarInfo() {
  const [rowData, setRowData] = useState<RowData[]>([]);
  const sidebarTitle = useRecoilValue(sidebarTitleState);
  const [carDrawerOpen, setCarDrawerOpen] = useState<boolean>(false);
  const [selectedCarId, setSelectedCarId] = useState<number>(-1);
  const [userDrawerOpen, setUserDrawerOpen] = useState<string>("");
  const loginUserInfo = useRecoilValue(loginUserInfoState);
  const [isEdit, setIsEdit] = useRecoilState(isEditedState);
  const [openDialog, setOpenDialog] = useState(false);
  const [updatedData, setUpdatedData] = useState<ResponseCar>();
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [deletedId, setDeletedId] = useState<number>(-1);

  useEffect(() => {
    const callCarList = async () => {
      try {
        const { data } = await getCarList(null);
        const mappedData = data.content.map((item: ResponseCar) => ({
          car_id: item.car_id,
          car_model: item.car_model,
          car_num: item.car_num,
          user: item.user?.user_name + " " + item.user?.role?.role_name,
          home_address: item.home_address || "-",
          work_address: item.work_address || "-",
          distance: item.distance || 0,
          is_personal: item.is_personal, // 변환된 필드
          is_deleted: item.is_deleted,
        }));
        setRowData(mappedData);
        setIsSaved(false);
        console.log(data.content, "CarList");
      } catch (error) {
        console.error("Error fetching CarInfo:", error);
      }
    };
    callCarList();
  }, [isSaved]);

  const baseColumnDefs = [
    {
      headerName: "차종",
      field: "car_model",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 0.7,
    },
    {
      headerName: "차량등록번호",
      field: "car_num",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 1,
    },
    {
      headerName: "사용자",
      field: "user",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 1,
    },
    {
      headerName: "자택",
      field: "home_address",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 1.5,
    },
    {
      headerName: "근무지",
      field: "work_address",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 1.5,
    },
    {
      headerName: "출퇴근 거리",
      field: "distance",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 1,
      valueFormatter: kmFormatter,
    },
    {
      headerName: "법인유무",
      field: "is_personal",
      filter: "agBooleanColumnFilter",
      cellRenderer: (params: any) => (params.value ? "개인" : "법인"),
      flex: 0.7,
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "삭제여부",
      field: "is_deleted",
      filter: "agBooleanColumnFilter",
      cellRenderer: (params: any) => (params.value ? "삭제됨" : ""),
      flex: 0.7,
      cellStyle: { textAlign: "center" },
    },
  ];

  const carManagementColumnDefs = [
    {
      headerName: "",
      field: "manage",
      cellRenderer: (params: any) => <MoreVertIcon />,
      flex: 0.3,
      minWidth: 40,
      cellClass: "custom__manage-menu",
    },
  ];

  const columnDefs = [
    ...baseColumnDefs,
    ...(sidebarTitle === "차량관리" ? carManagementColumnDefs : []),
  ];

  const handleSetOpen = (status: boolean, type: string) => {
    if (type === "newCar") {
      setCarDrawerOpen(true);
      setUserDrawerOpen("create");
    }
    if (type === "car") {
      setCarDrawerOpen(true);
      setUserDrawerOpen("view");
    }
  };

  const selectedId = (type: string, id: number) => {
    setSelectedCarId(id);
  };

  const closeDrawer = () => {
    if (userDrawerOpen === "view" && !isEdit) {
      closeDialog();
    } else {
      setOpenDialog(true);
    }
  };

  const handleSubmitSuccess = () => {
    setIsSaved(true);
    closeDialog();
  };

  const closeDialog = () => {
    setCarDrawerOpen(false);
    setOpenDialog(false);
    setIsEdit(false);
  };

  const pageSizeChange = (size: number) => {
    // setViewSize(size);
  };

  return (
    <>
      <div className="mainTop_wrapper">
        <div className="title">
          <div className="menu">Company information</div>
          <div className="sub_menu">
            <div className="sub_menu__title">
              <h1>차량정보</h1>
            </div>
            <div>
              {loginUserInfo.is_admin && (
                <button
                  className="write_button"
                  onClick={() => handleSetOpen(true, "newCar")}
                >
                  <CarIcon />
                  신규 차량 등록
                </button>
              )}
            </div>
          </div>
        </div>
        <Card id={"main_card"}>
          <Grid
            columnDefs={columnDefs}
            rowData={rowData}
            type="car"
            setOpen={handleSetOpen}
            setId={selectedId}
            newData={updatedData}
            deletedId={deletedId}
          />
          <ViewCarDrawer
            open={userDrawerOpen === "view" && carDrawerOpen}
            onClose={closeDrawer}
            carId={selectedCarId}
            setUpdatedData={setUpdatedData}
            setDeletedId={setDeletedId}
          />
          <CreateWorkDrawer
            open={userDrawerOpen === "create" && carDrawerOpen}
            onClose={closeDrawer}
            onSubmitSuccess={handleSubmitSuccess}
            type={"CarInfo"}
          />
          <DialogText
            open={openDialog}
            content="변경 사항이 저장되지 않을 수 있습니다. 계속하시겠습니까?"
            onCancel={() => setOpenDialog(false)}
            onClose={closeDialog}
          />
        </Card>
      </div>
    </>
  );
}
