import { useRecoilState, useRecoilValue } from "recoil";
import { isModalPwdOpenState, loginUserInfoState } from "recoil/stateStore";
import Modal from "react-modal";
import { useEffect, useState } from "react";
import { useErrorHandler } from "./ErrorHandler";
import { ReactComponent as SaveIcon } from "assets/save.svg";
import { ReactComponent as CancelIcon } from "assets/cancel.svg";
import { ChangePwdParams, updatePassword } from "api/user";

const customModalStyles: ReactModal.Styles = {
  overlay: {
    backgroundColor: " rgba(0, 0, 0, 0.4)",
    width: "100%",
    height: "100vh",
    zIndex: "1401",
    position: "fixed",
    top: "0",
    left: "0",
  },
  content: {
    width: "470px",
    // height: "305px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "8px",
    boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.25)",
    backgroundColor: "white",
    justifyContent: "center",
    overflow: "auto",
  },
};

export default function ModalPassword() {
  const [modalPwdOpen, setModalPwdOpen] = useRecoilState(isModalPwdOpenState);
  const [passwordData, setPasswordData] = useState<ChangePwdParams>({
    password: "",
    new_password: "",
    check_password: "",
  });

  const [resultMessage, setResultMessage] = useState("");
  const [isPasswordMatch, setIsPasswordMatch] = useState(false);
  const loggingUserInfo = useRecoilValue(loginUserInfoState);
  const handleError = useErrorHandler();

  useEffect(() => {
    console.log("password 변경 모달");
    console.log(loggingUserInfo, "loggingUserInfo");
  }, []);

  useEffect(() => {
    if (passwordData.new_password === passwordData.check_password) {
      setIsPasswordMatch(true);
      setResultMessage("");
    } else if (
      passwordData.new_password !== passwordData.check_password ||
      passwordData.new_password === "" ||
      passwordData.check_password === ""
    ) {
      setIsPasswordMatch(false);
      setResultMessage("신규 비밀번호와 확인값이 일치하지 않습니다.");
    }
  }, [passwordData.new_password, passwordData.check_password]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "password") {
      setPasswordData({ ...passwordData, password: value });
    } else if (name === "newPassword") {
      setPasswordData({ ...passwordData, new_password: value });
    } else if (name === "confirmNewPassword") {
      setPasswordData({ ...passwordData, check_password: value });
    }
    console.log(passwordData);
  };

  const handleCancelModal = () => {
    setModalPwdOpen(false);
    setPasswordData({ password: "", new_password: "", check_password: "" });
  };

  const handleSave = async () => {
    if (isPasswordMatch) {
      // 현재 비밀번호가 맞을때
      try {
        const response = await updatePassword(
          loggingUserInfo.user_id,
          passwordData
        );
        console.log(response);
        alert("성공적으로 비밀번호를 변경하였습니다.");

        setModalPwdOpen(false);
        setPasswordData({
          password: "",
          new_password: "",
          check_password: "",
        });
        setResultMessage("");
        alert("비밀번호 변경이 완료되었습니다");
      } catch (error) {
        handleError(error);
      }
    } else {
      console.log("비밀번호 확인값이 일치하지 않습니다.");
    }
  };

  return (
    <Modal
      className="modal_change_pwd"
      isOpen={modalPwdOpen}
      onRequestClose={() => setModalPwdOpen(false)}
      style={customModalStyles}
      ariaHideApp={false}
      contentLabel="Pop up Message"
      shouldCloseOnOverlayClick={false}
    >
      <div className="change_pwd_wrapper">
        <div className="title_change_pwd">비밀번호 변경</div>
        <div className="input_change_pwd">
          <div>
            <label>현재 비밀번호</label>
            <input
              type="password"
              name="password"
              value={passwordData.password}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label>신규 비밀번호</label>
            <input
              type="password"
              name="newPassword"
              value={passwordData.new_password}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label>신규 비밀번호 확인</label>
            <input
              type="password"
              name="confirmNewPassword"
              value={passwordData.check_password}
              onChange={handleInputChange}
            />
          </div>
          <div>
            {isPasswordMatch === true ? (
              <span style={{ fontSize: "12px", color: "green" }}>
                {resultMessage}
              </span>
            ) : (
              <span style={{ fontSize: "12px", color: "red" }}>
                {resultMessage}
              </span>
            )}
          </div>
        </div>
        <div className="btn_change_pwd">
          <button className="cancel_button" onClick={handleCancelModal}>
            <CancelIcon />
            변경 취소
          </button>
          <button
            className="write_button"
            onClick={handleSave}
            disabled={
              !isPasswordMatch ||
              passwordData.password === "" ||
              passwordData.new_password === "" ||
              passwordData.check_password === ""
            }
          >
            <SaveIcon />
            변경 확인
          </button>
        </div>
      </div>
    </Modal>
  );
}
