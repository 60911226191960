import { useEffect, useState } from "react";
import { getUser, User } from "api/user";
import { useRecoilState } from "recoil";
import { isEditedState } from "recoil/stateStore";
import WorkLogDrawer from "components/WorkLogDrawer";
import EditUserForm from "components/editForm/EditUserForm";
import UserViewForm from "components/viewForm/UserViewForm";

interface FormDrawerProps {
  open: boolean;
  onClose: (event: any) => void;
  userId: number;
  setUpdatedData: Function;
  setDeletedId: Function;
}

const ViewUserDrawer: React.FC<FormDrawerProps> = ({
  open,
  onClose,
  userId,
  setUpdatedData,
  setDeletedId,
}) => {
  const [isEdit, setIsEdit] = useRecoilState(isEditedState);
  const [userInfo, setUserInfo] = useState<User>({} as User);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);

  useEffect(() => {
    const callUserInfo = async () => {
      const userData = await getUser(String(userId));
      console.log(userData, "user");
      setUserInfo(userData.data);
      setUpdatedData(userData.data);
      setIsDeleted(false);
    };
    if (userId !== -1 && !isEdit) {
      callUserInfo();
    }
  }, [userId, isEdit, isDeleted]);

  return (
    <WorkLogDrawer open={open} onClose={onClose}>
      {isEdit ? (
        <div className="write_head">사용자 정보 수정</div>
      ) : (
        <div className="write_head">사용자 정보 상세보기</div>
      )}
      {isEdit ? (
        <EditUserForm userInfo={userInfo} setUserInfo={setUserInfo} />
      ) : (
        <UserViewForm
          userInfo={userInfo}
          onClose={onClose}
          setIsDeleted={setIsDeleted}
        />
      )}
    </WorkLogDrawer>
  );
};

export default ViewUserDrawer;
