import { useState } from "react";
import PhoneForm from "./PhoneForm";
import { CancelButton, SaveButton } from "components/Common";
import { ReactComponent as CompanyIcon } from "assets/writeIcon/company.svg";
import { ReactComponent as WorkOptionIcon } from "assets/writeIcon/work_option.svg";
import { ReactComponent as AddressIcon } from "assets/writeIcon/address.svg";
import { ReactComponent as CallIcon } from "assets/writeIcon/call.svg";
import { ReactComponent as FaxIcon } from "assets/writeIcon/fax.svg";
import { GuGun, regionDatas } from "components/RegionData";
import { postCompany } from "api/company";
import { useErrorHandler } from "components/ErrorHandler";

interface WorkLogWriteProps {
  onClose: (event?: any) => void;
  onSubmitSuccess: (e?: any) => void;
}

const CompanyWrite = ({ onClose, onSubmitSuccess }: WorkLogWriteProps) => {
  const [compName, setCompName] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [sido, setSido] = useState<string>("");
  const [guguns, setGuguns] = useState<string>("");
  const [sectors, setSectors] = useState<string>("");
  const [compTel, setCompTel] = useState<string>("--");
  const [compFax, setCompFax] = useState<string>("--");

  const [selectedSiDo, setSelectedSiDo] = useState<string>("");
  const [selectedGuGun, setSelectedGuGun] = useState<string>("");
  const [guGuns, setGuGuns] = useState<GuGun[]>([]);
  const handleError = useErrorHandler();

  const handlePhoneNumberChange = (
    phoneNumber: string,
    phoneNumberType: "tel" | "phone" | "fax"
  ) => {
    phoneNumberType === "tel"
      ? setCompTel(phoneNumber)
      : setCompFax(phoneNumber);
  };

  const handleSiDoChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const siDoFull = event.target.value;
    setSelectedSiDo(siDoFull);
    setSelectedGuGun("");
    const siDo = regionDatas.find((sd) => sd.siDoFull === siDoFull);
    if (siDo) {
      setGuGuns(siDo.guGuns);
      setSido(event.target.value);
      setGuguns("");
    } else {
      setGuGuns([]);
    }
  };

  const handleGuGunChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedGuGun(event.target.value);
    setGuguns(event.target.value);
  };

  // 취소버튼
  const onCancel = () => {
    onClose();
  };

  // 저장버튼
  const handleSubmit = async () => {
    console.log("신규업체 등록");
    if (compName === "" || sido === "" || sectors === "") {
      alert("필수값이 입력되지 않았습니다.");
    } else if (compTel.length < 6) {
      alert("잘못된 번호 입력입니다.");
    } else {
      try {
        const data = {
          comp_name: compName,
          comp_sido_nm: sido,
          comp_sgg_nm: guguns ?? "",
          comp_address: address ?? "",
          sectors: sectors,
          comp_tel: compTel ?? "000-000-0000",
          comp_fax: compFax,
        };
        const newCompanyData = await postCompany(data);
        console.log(newCompanyData, "newCompanyData");
        // successSave();
        onSubmitSuccess();
      } catch (error) {
        handleError(error); // 오류 처리 함수 호출
      }
    }
  };

  return (
    <div>
      <div className="company_write_wrapper">
        <div>
          <div className="div_icon">
            <CompanyIcon style={{ marginRight: "4px" }} />
            업체명
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <input
            type="text"
            placeholder="내용 입력"
            value={compName}
            onChange={({ target }) => setCompName(target.value)}
          />
        </div>
        <div>
          <div className="div_icon">
            <WorkOptionIcon style={{ marginRight: "4px" }} />
            업종
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <input
            type="text"
            placeholder="내용 입력"
            value={sectors}
            onChange={({ target }) => setSectors(target.value)}
          />
        </div>
        <div style={{ marginTop: "6px" }}>
          <div className="div_icon" style={{ height: "34px" }}>
            <AddressIcon style={{ marginRight: "4px" }} />
            지역
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <div className="region_select">
            <select value={selectedSiDo} onChange={handleSiDoChange}>
              <option value="">시/도 선택</option>
              {regionDatas.map((sd, index) => (
                <option key={index} value={sd.siDoFull}>
                  {sd.siDoFull}
                </option>
              ))}
            </select>
            <select
              value={selectedGuGun}
              onChange={handleGuGunChange}
              disabled={!selectedSiDo}
            >
              <option value="">시/구/군 선택</option>
              {guGuns.map((gg, index) => (
                <option key={index} value={gg.guGunName}>
                  {gg.guGunName}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="company_address" style={{ marginBottom: "6px" }}>
          <div className="div_icon">
            상세주소
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <input
            style={{ width: "70%" }}
            type="text"
            placeholder="상세주소 입력 (선택)"
            value={address}
            onChange={({ target }) => setAddress(target.value)}
          />
        </div>
        <div>
          <div className="div_icon">
            <CallIcon style={{ marginRight: "4px" }} />
            전화번호
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <PhoneForm
            phoneNumberType="tel"
            phoneNumberData={compTel}
            onPhoneNumberChange={handlePhoneNumberChange}
          />
        </div>
        <div className="company_fax">
          <div className="div_icon">
            <FaxIcon style={{ marginRight: "4px" }} />
            팩스번호
          </div>
          <PhoneForm
            phoneNumberType="fax"
            phoneNumberData={compFax}
            onPhoneNumberChange={handlePhoneNumberChange}
          />
        </div>
        <div className="write_bottom">
          <CancelButton onClickHandler={onCancel} />
          <SaveButton onClickHandler={handleSubmit} />
        </div>
      </div>
    </div>
  );
};
export default CompanyWrite;
