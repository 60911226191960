import { useEffect, useState } from "react";
import { ColDef, ValueFormatterParams } from "ag-grid-community";
import Card from "@mui/material/Card";
import Grid from "components/Grid";
import { ReactComponent as WriteIcon } from "assets/write.svg";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { ResponseWorkLog, getWorkLogs } from "api/workLog";
import {
  convertRegionName,
  dateFilterParams,
  formatProgressWithIcon,
  textFilterParams,
} from "components/Common";
import {
  autoSelectCompIdState,
  filterUserState,
  isEditedState,
  searchRangeDateState,
} from "recoil/stateStore";
import SelectUserInfo from "components/SelectUserInfo";
import ViewWorkDrawer from "components/drawer/ViewWorkDrawer";
import CreateWorkDrawer from "components/drawer/CreateDrawer";
import { useLocation, useNavigate } from "react-router-dom";
import DialogText from "components/DialogText";
import { serializeWorkLogs } from "util/serializer";

interface RowData {
  work_id: number;
  work_date: string;
  user_name: string;
  work_option: string;
  meeting: string;
  comp_address: string;
  company_name: string;
  member_name: string;
  plan: string;
  work_content: string;
  progress: string;
}

export default function WorkLog() {
  const location = useLocation();
  const [rowData, setRowData] = useState<RowData[]>([]);
  const filterUser = useRecoilValue(filterUserState);
  const [workLogDrawerOpen, setWorkLogDrawerOpen] = useState<boolean>(false);
  const [selectedWorkId, setSelectedWorkId] = useState<number>(-1);
  const [userDrawerOpen, setUserDrawerOpen] = useState<string>("");
  const setAutoSelectCompId = useSetRecoilState(autoSelectCompIdState);
  const [openDialog, setOpenDialog] = useState(false);
  const [isEdit, setIsEdit] = useRecoilState(isEditedState);
  const rangeDate = useRecoilValue(searchRangeDateState);
  const [updatedData, setUpdatedData] = useState<ResponseWorkLog>();
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [deletedId, setDeletedId] = useState<number>(-1);
  const [viewSize, setViewSize] = useState<number>(10);
  const navigate = useNavigate();

  useEffect(() => {
    const callWorkLogs = async () => {
      try {
        const compId = location.state?.id ? location.state.id : null;
        const { data } = await getWorkLogs(
          filterUser,
          compId,
          rangeDate[0],
          rangeDate[1]
        );
        const mappedData = serializeWorkLogs(data.content);
        console.log(data, "worklogData");
        console.log(mappedData, "mappedData");
        setRowData(mappedData);
        setIsSaved(false);
      } catch (error) {
        console.error("Error fetching work logs:", error);
      }
    };
    callWorkLogs();
  }, [isSaved, filterUser, rangeDate, isEdit]);

  const columnDefs: ColDef[] = [
    {
      field: "work_id",
      headerName: "ID",
      filter: "agDateColumnFilter",
      filterParams: textFilterParams,
      minWidth: 60,
      maxWidth: 100,
      flex: 0.5,
    },
    {
      field: "work_date",
      headerName: "일자",
      filter: "agTextColumnFilter",
      filterParams: dateFilterParams,
      minWidth: 145,
      flex: 1,
    },
    {
      field: "work_hour",
      headerName: "시간",
      minWidth: 75,
      maxWidth: 75,
      flex: 0.4,
      valueGetter: (params) => {
        if (!params.data?.work_hour || !params.data?.work_minute) {
          return "-";
        }
        return `${params.data.work_hour}:${params.data.work_minute}`;
      },
    },
    {
      field: "user_name",
      headerName: "작성자",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      minWidth: 75,
      maxWidth: 100,
    },
    {
      field: "work_option",
      headerName: "업무 구분",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      minWidth: 90,
      maxWidth: 130,
      flex: 1,
    },
    // {
    //   field: "meeting",
    //   headerName: "미팅 방식",
    //   filter: "agTextColumnFilter",
    //   filterParams: textFilterParams,
    //   //   width: 130,
    //   flex: 0.8,
    // },
    {
      field: "comp_address",
      headerName: "지역",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      maxWidth: 120,
      flex: 1.2,
      cellRenderer: (params: ValueFormatterParams) => {
        return params.value ? convertRegionName(params.value) : "-";
      },
    },
    {
      field: "company_name",
      headerName: "업체명",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      minWidth: 130,
      flex: 1,
      cellRenderer: (params: ValueFormatterParams) => {
        const compId = params.data.comp_id;
        return (
          <p
            className="comp_name"
            onClick={() =>
              navigate(`/companies/${params.value}`, {
                state: { id: compId },
              })
            }
          >
            {params.value}
          </p>
        );
      },
    },
    {
      field: "member_name",
      headerName: "고객사 담당자",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      //   width: 140,
      flex: 1,
    },
    {
      field: "plan",
      headerName: "계획 일정",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      //   minWidth: 220,
      flex: 2,
    },
    {
      field: "work_content",
      headerName: "대응 내용 및 결과",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      //   minWidth: 220,
      flex: 2,
    },
    {
      field: "progress",
      headerName: "진행도",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      minWidth: 90,
      maxWidth: 90,
      cellStyle: (params: ValueFormatterParams) => {
        // 진행 상황에 따른 배경색 설정
        switch (params.value) {
          case "COMPLETE":
            return {
              backgroundColor: "#cbdca7",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            };
          case "INPROGRESS":
            return {
              backgroundColor: "#f6e9a1",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            };
          case "EXPECTED":
            return {
              backgroundColor: "#a1c0f6",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            };
          case "NOTPROGRESS":
            return {
              backgroundColor: "#f6a1a1",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            };
          default:
            return {
              backgroundColor: "#ffffff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            };
        }
      },
      cellRenderer: (params: ValueFormatterParams) => {
        return formatProgressWithIcon(params.value);
      },
    },
  ];

  const handleSetOpen = (status: boolean, type: string) => {
    if (type === "newWorkLog") {
      setAutoSelectCompId(null);
      setWorkLogDrawerOpen(true);
      setUserDrawerOpen("create");
    }
    if (type === "workLog") {
      setWorkLogDrawerOpen(true);
      setUserDrawerOpen("view");
    }
  };

  const selectedId = (type: string, id: number) => {
    setSelectedWorkId(id);
  };

  const closeDrawer = () => {
    if (userDrawerOpen === "view" && !isEdit) {
      closeDialog();
    } else {
      setOpenDialog(true);
    }
  };

  const handleSubmitSuccess = () => {
    setIsSaved(true);
    closeDialog();
  };

  const closeDialog = () => {
    setWorkLogDrawerOpen(false);
    setOpenDialog(false);
    setIsEdit(false);
  };

  const pageSizeChange = (size: number) => {
    setViewSize(size);
  };

  return (
    <>
      <div className="mainTop_wrapper">
        <div className="title">
          <div className="menu">Work management</div>
          <div className="sub_menu">
            <div className="sub_menu__title">
              <h1>업무일지</h1>
              <SelectUserInfo selectUserId={filterUser || ""} />
            </div>
            <div>
              <button
                className="write_button"
                onClick={() => handleSetOpen(true, "newWorkLog")}
              >
                <WriteIcon />
                업무일지 작성
              </button>
            </div>
          </div>
        </div>
        <Card id={"main_card"}>
          <Grid
            columnDefs={columnDefs}
            rowData={rowData}
            type="workLog"
            setOpen={handleSetOpen}
            setId={selectedId}
            newData={updatedData}
            deletedId={deletedId}
          />
          <ViewWorkDrawer
            open={userDrawerOpen === "view" && workLogDrawerOpen}
            onClose={closeDrawer}
            workId={selectedWorkId}
            setUpdatedData={setUpdatedData}
            setDeletedId={setDeletedId}
          />
          <CreateWorkDrawer
            open={userDrawerOpen === "create" && workLogDrawerOpen}
            onClose={closeDrawer}
            onSubmitSuccess={handleSubmitSuccess}
            type={"WorkLog"}
          />
          <DialogText
            open={openDialog}
            content="변경 사항이 저장되지 않을 수 있습니다. 계속하시겠습니까?"
            onCancel={() => setOpenDialog(false)}
            onClose={closeDialog}
          />
        </Card>
      </div>
    </>
  );
}
