import { useEffect, useState } from "react";
import { ColDef, ValueFormatterParams } from "ag-grid-community";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import MonthGrid from "components/MonthGrid";
import { ReactComponent as WriteIcon } from "assets/write.svg";
import PlanDate from "components/PlanDate";
import { getMonthPlanList, ResponseMonthPlan } from "api/monthPlan";
import { textFilterParams } from "components/Common";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import SelectUserInfo from "components/SelectUserInfo";
import {
  autoSelectCompIdState,
  filterUserState,
  isEditedState,
  searchRangeDateState,
} from "recoil/stateStore";
import ViewPlanDrawer from "components/drawer/ViewPlanDrawer";
import CreateWorkDrawer from "components/drawer/CreateDrawer";
import DialogText from "components/DialogText";
import { useNavigate } from "react-router-dom";

export interface RowData {
  no: string;
  mplan_id: number;
  mplan_year: string;
  mplan_month: string;
  user: string;
  company: string;
  comp_id: number;
  expected_price: number;
  ex_price: string;
  confirmed_price: number;
  con_price: string;
  delivery_date: string;
  expected_order_rate: number;
  ex_order_rate: string;
  order_rate: number;
  con_order_rate: string;
  mplan_note: string;
  mplan_detail: string;
  mplan_progress: string;
}

export default function MonthPlan() {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const [existingCompanyData, setExistingCompanyData] = useState<RowData[]>([]);
  const [newCompanyData, setNewCompanyData] = useState<RowData[]>([]);
  const filterUser = useRecoilValue(filterUserState);
  const [year, setYear] = useState<string>(String(currentYear));
  const [month, setMonth] = useState<string>(String(currentMonth));
  const [planDrawerOpen, setPlanDrawerOpen] = useState<boolean>(false);
  const [selectedPlanId, setSelectedPlanId] = useState<number>(-1);
  const [userDrawerOpen, setUserDrawerOpen] = useState<string>("");
  const setAutoSelectCompId = useSetRecoilState(autoSelectCompIdState);
  const [openDialog, setOpenDialog] = useState(false);
  const [isEdit, setIsEdit] = useRecoilState(isEditedState);
  const rangeDate = useRecoilValue(searchRangeDateState);
  const [updatedData, setUpdatedData] = useState<ResponseMonthPlan>();
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [deletedId, setDeletedId] = useState<number>(-1);

  const navigate = useNavigate();

  const setDate = (year: string, month: string) => {
    setYear(year);
    setMonth(month);
  };

  useEffect(() => {
    const callMonthPlanList = async () => {
      try {
        const { data } = await getMonthPlanList(filterUser, year, month);
        const existingData: RowData[] = [];
        const newData: RowData[] = [];
        data.content.forEach((item: ResponseMonthPlan, index: number) => {
          const rowData: RowData = {
            no: String(index + 1),
            mplan_id: item.mplan_id ?? -1,
            mplan_year: item.mplan_year,
            mplan_month: item.mplan_month,
            user: item.user?.user_name ?? "",
            company: item.company?.comp_name ?? "",
            comp_id: item.company?.comp_id ?? -1,
            expected_price: item.expected_price ?? 0,
            ex_price: (item.expected_price ?? 0).toLocaleString("ko-KR"),
            confirmed_price: item.confirmed_price ?? 0,
            con_price: (item.confirmed_price ?? 0).toLocaleString("ko-KR"),
            expected_order_rate: item.expected_order_rate ?? 0,
            ex_order_rate: (item.expected_order_rate ?? 0) + " %",
            order_rate: item.order_rate ?? 0,
            con_order_rate: (item.order_rate ?? 0) + " %",
            delivery_date: item.delivery_date || "-",
            mplan_note: item.mplan_note || "-",
            mplan_detail: item.mplan_detail || "-",
            mplan_progress: item.mplan_progress,
          };
          const newRowData = {
            mplan_id: item.mplan_id ?? -1,
            company: item.company?.comp_name ?? "",
            mplan_detail: item.mplan_detail ?? "",
          };

          if (item.is_newCompany) {
            newData.push(rowData);
          } else {
            existingData.push(rowData);
          }
        });
        setExistingCompanyData(existingData);
        setNewCompanyData(newData);
        setIsSaved(false);
      } catch (error) {
        console.error("Error fetching month plans:", error);
      }
    };
    callMonthPlanList();
  }, [isSaved, filterUser, year, month, rangeDate]);

  const handleSetOpen = (status: boolean, type?: string) => {
    if (type === "newMonthPlan") {
      setAutoSelectCompId(null);
      setPlanDrawerOpen(true);
      setUserDrawerOpen("create");
    } else {
      setPlanDrawerOpen(true);
      setUserDrawerOpen("view");
    }
  };

  const closeDrawer = () => {
    if (userDrawerOpen === "view" && !isEdit) {
      closeDialog();
    } else {
      setOpenDialog(true);
    }
  };

  const closeDialog = () => {
    setPlanDrawerOpen(false);
    setOpenDialog(false);
    setIsEdit(false);
  };

  const handleSubmitSuccess = () => {
    setIsSaved(true);
    closeDialog();
  };

  const selectedId = (type: string, id: number) => {
    setSelectedPlanId(id);
  };

  const [columnDefs] = useState<ColDef[]>([
    {
      headerName: "No",
      field: "no",
      flex: 0.7,
      cellStyle: (params: any) => {
        if (params.node.rowPinned) {
          return params.data.customStyle; // customStyle에서 스타일 적용
        }
        return null;
      },
    },
    {
      headerName: "담당자",
      field: "user",
      flex: 0.8,
      cellStyle: (params: any) => {
        if (params.node.rowPinned) {
          return params.data.customStyle;
        }
        return null;
      },
    },
    {
      headerName: "업체명",
      field: "company",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 1.2,
      cellRenderer: (params: ValueFormatterParams) => {
        const compId = params.data.comp_id;
        // console.log(params, compId, "compId");
        return (
          <p
            className="comp_name"
            onClick={() =>
              navigate(`/companies/${params.value}`, {
                state: { id: compId },
              })
            }
          >
            {params.value}
          </p>
        );
      },
      cellStyle: (params: any) => {
        if (params.node.rowPinned) {
          return params.data.customStyle;
        }
        return null;
      },
    },
    {
      headerName: "상담 예상액",
      field: "ex_price",
      flex: 1.1,
      cellStyle: (params: any) => {
        if (params.node.rowPinned) {
          return params.data.customStyle;
        }
        return null;
      },
    },
    {
      headerName: "수주액",
      field: "con_price",
      flex: 1,
      cellStyle: (params: any) => {
        if (params.node.rowPinned) {
          return params.data.customStyle;
        }
        return null;
      },
    },
    {
      headerName: "납품시기",
      field: "delivery_date",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 1.1,
      cellStyle: (params: any) => {
        if (params.node.rowPinned) {
          return params.data.customStyle;
        }
        return null;
      },
    },
    {
      headerName: "예상수주율(%)",
      field: "ex_order_rate",
      flex: 1,
      cellStyle: (params: any) => {
        if (params.node.rowPinned) {
          return params.data.customStyle;
        }
        return null;
      },
    },
    {
      headerName: "수주율(%)",
      field: "con_order_rate",
      flex: 1,
      cellStyle: (params: any) => {
        if (params.node.rowPinned) {
          return params.data.customStyle;
        }
        return null;
      },
    },
    {
      headerName: "업무내용",
      field: "mplan_note",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 1.9,
      cellStyle: (params: any) => {
        if (params.node.rowPinned) {
          return params.data.customStyle;
        }
        return null;
      },
    },
  ]);

  const [detailColumnDefs] = useState<ColDef[]>([
    // { headerName: "No", field: "mplan_id", flex: 0.1 },
    {
      headerName: "No",
      field: "no",
      flex: 0.1,
    },
    {
      headerName: "업체명",
      field: "company",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 0.2,
    },
    {
      headerName: "상세내용 & 영업활동 분석",
      field: "mplan_detail",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 1,
    },
  ]);

  return (
    <>
      <div className="mainTop_wrapper">
        <div className="title">
          <div className="menu">Work management</div>
          <div className="sub_menu">
            <div className="sub_menu__title">
              <h1>월간계획서</h1>
              <SelectUserInfo selectUserId={filterUser} />
            </div>
            <div>
              <button
                className="write_button"
                onClick={() => handleSetOpen(true, "newMonthPlan")}
              >
                <WriteIcon />
                월간계획서 작성
              </button>
            </div>
          </div>
        </div>

        <Box
          id={"month_plan_card"}
          style={{ height: "fit-content", marginBottom: "20px" }}
        >
          <PlanDate setDate={setDate} />
        </Box>
        <p style={{ fontSize: "22px", margin: "10px 0" }}>
          기존 고객사 상담 현황
        </p>
        <Card
          className="month_card"
          style={{ height: "fit-content", marginBottom: "20px" }}
        >
          <div>
            <MonthGrid
              name="고객사상담현황"
              columnDefs={columnDefs}
              rowData={existingCompanyData}
              setOpen={handleSetOpen}
              setId={selectedId}
              newData={updatedData}
              deletedId={deletedId}
            />
          </div>
        </Card>
        <p style={{ fontSize: "22px", margin: "10px 0" }}>
          신규 고객사 상담 현황
        </p>
        <Card
          className="month_card"
          style={{ height: "fit-content", marginBottom: "20px" }}
        >
          <div>
            <MonthGrid
              name="고객사상담현황"
              columnDefs={columnDefs}
              rowData={newCompanyData}
              setOpen={handleSetOpen}
              setId={selectedId}
              newData={updatedData}
              deletedId={deletedId}
            />
          </div>
        </Card>
        <p style={{ marginBottom: "10px", fontSize: "22px" }}>
          상세내용 및 영업활동 분석
        </p>
        <Card className="month_card" style={{ height: "fit-content" }}>
          <div>
            <p>- 기존 고객사</p>
            <MonthGrid
              columnDefs={detailColumnDefs}
              rowData={existingCompanyData}
              setOpen={handleSetOpen}
              setId={selectedId}
              newData={updatedData}
              deletedId={deletedId}
            />
          </div>
          <div>
            <p>- 신규 고객사</p>
            <MonthGrid
              columnDefs={detailColumnDefs}
              rowData={newCompanyData}
              setOpen={handleSetOpen}
              setId={selectedId}
              newData={updatedData}
              deletedId={deletedId}
            />
          </div>
        </Card>
        <ViewPlanDrawer
          open={userDrawerOpen === "view" && planDrawerOpen}
          onClose={closeDrawer}
          planId={selectedPlanId}
          setUpdatedData={setUpdatedData}
          setDeletedId={setDeletedId}
        />
        <CreateWorkDrawer
          open={userDrawerOpen === "create" && planDrawerOpen}
          onClose={closeDrawer}
          onSubmitSuccess={handleSubmitSuccess}
          type={"MonthPlan"}
        />
        <DialogText
          open={openDialog}
          content="변경 사항이 저장되지 않을 수 있습니다. 계속하시겠습니까?"
          onCancel={() => setOpenDialog(false)}
          onClose={closeDialog}
        />
      </div>
    </>
  );
}
