import { useEffect, useState } from "react";
import PhoneForm from "./PhoneForm";
import { CancelButton, SaveButton, successSave } from "components/Common";
import { ReactComponent as CompanyIcon } from "assets/writeIcon/company.svg";
import { ReactComponent as MemberIcon } from "assets/writeIcon/name.svg";
import { ReactComponent as PartIcon } from "assets/writeIcon/part.svg";
import { ReactComponent as RoleIcon } from "assets/writeIcon/role.svg";
import { ReactComponent as PhoneIcon } from "assets/writeIcon/phone.svg";
import { ReactComponent as MailIcon } from "assets/writeIcon/mail.svg";
import { useErrorHandler } from "components/ErrorHandler";
import { postMember } from "api/member";
import { getCompany } from "api/company";
import { useLocation } from "react-router-dom";

interface WorkLogWriteProps {
  onClose: (event?: any) => void;
  onSubmitSuccess: (e?: any) => void;
}

const MemberWrite = ({ onClose, onSubmitSuccess }: WorkLogWriteProps) => {
  const location = useLocation();
  const handleError = useErrorHandler();
  const [memberName, setMemberName] = useState<string>("");
  const [department, setDepartment] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [memberPhone, setMemberPhone] = useState<string>("--");
  const [email, setEmail] = useState<string>("");
  const [note, setNote] = useState<string>("");
  const [compName, setCompName] = useState<string>("");

  const handlePhoneNumberChange = (phoneNumber: string) => {
    setMemberPhone(phoneNumber);
  };

  useEffect(() => {
    const callCompany = async () => {
      const compData = await getCompany(location.state.id);
      console.log(compData, "compData");
      setCompName(compData.data.comp_name);
    };
    callCompany();
  }, [location.state.id]);

  // 취소버튼
  const onCancel = () => {
    onClose();
  };

  // 저장방법
  const handleSubmit = async () => {
    if (memberName === "" || role === "" || memberPhone === "") {
      alert("필수값이 입력되지 않았습니다. ");
    } else {
      try {
        const data = {
          member_name: memberName,
          member_department: department,
          member_role: role,
          member_phone: memberPhone,
          member_email: email,
          member_note: note,
          is_deleted: false,
        };
        await postMember(location.state.id, data);
        // setWorkList((prev) => [...prev, data]);
        successSave();
        onClose(false);
        // successSave();
        onSubmitSuccess();
      } catch (error) {
        handleError(error); // 오류 처리 함수 호출
      }
    }
  };

  return (
    <>
      <div className="write_list_wrapper">
        <div className="user_option">
          <div>
            <div className="div_icon">
              <CompanyIcon style={{ marginRight: "4px" }} />
              업체명
            </div>
            <span>{compName}</span>
          </div>
          <div>
            <div className="div_icon">
              <MemberIcon style={{ marginRight: "4px" }} />
              성명
              <span style={{ color: "red" }}>&nbsp;*</span>
            </div>
            <input
              type="span"
              name="member_name"
              value={memberName}
              onChange={({ target }) => setMemberName(target.value)}
            />
          </div>
          <div>
            <div className="div_icon">
              <PartIcon style={{ marginRight: "4px" }} />
              부서
            </div>
            <input
              type="span"
              name="member_department"
              value={department}
              onChange={({ target }) => setDepartment(target.value)}
            />
          </div>
          <div>
            <div className="div_icon">
              <RoleIcon style={{ marginRight: "4px" }} />
              직책
              <span style={{ color: "red" }}>&nbsp;*</span>
            </div>
            <input
              type="span"
              name="member_role"
              value={role}
              onChange={({ target }) => setRole(target.value)}
            />
          </div>
          <div className="input_phone_number">
            <div className="div_icon">
              <PhoneIcon style={{ marginRight: "4px" }} />
              연락처
              <span style={{ color: "red" }}>&nbsp;*</span>
            </div>
            <PhoneForm
              phoneNumberType="tel"
              phoneNumberData={memberPhone}
              onPhoneNumberChange={handlePhoneNumberChange}
            />
          </div>
          <div style={{ marginBottom: "20px" }}>
            <div className="div_icon">
              <MailIcon style={{ marginRight: "4px" }} />
              이메일
            </div>
            <input
              type="span"
              name="member_email"
              value={email}
              onChange={({ target }) => setEmail(target.value)}
            />
          </div>
        </div>

        <div className="log_note">
          비고
          <textarea
            value={note}
            style={{ height: "140px", marginTop: "4px", color: "#000" }}
            name="work_content"
            onChange={({ target }) => setNote(target.value)}
            disabled={false}
          />
        </div>
        <div className="write_bottom">
          <>
            <CancelButton onClickHandler={onCancel} />
            <SaveButton onClickHandler={handleSubmit} />
          </>
        </div>
      </div>
    </>
  );
};

export default MemberWrite;
