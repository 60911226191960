import { CounselingType, updateCons } from "api/counseling";
import { useEffect, useState } from "react";
import { ReactComponent as CalendarIcon } from "assets/writeIcon/calendar.svg";
import { ReactComponent as CompanyIcon } from "assets/writeIcon/company.svg";
import { ReactComponent as MemberIcon } from "assets/writeIcon/user.svg";
import { ReactComponent as PhoneIcon } from "assets/writeIcon/phone.svg";
import { ReactComponent as ProjectIcon } from "assets/writeIcon/project.svg";
import { ReactComponent as ItemIcon } from "assets/writeIcon/item.svg";
import { ReactComponent as CountIcon } from "assets/writeIcon/count.svg";
import { ReactComponent as PriceIcon } from "assets/writeIcon/price.svg";
import { ReactComponent as SumIcon } from "assets/writeIcon/sum.svg";
import { ReactComponent as UserIcon } from "assets/writeIcon/user.svg";
import { ReactComponent as ProgressIcon } from "assets/writeIcon/progress.svg";
import { ReactComponent as NoteIcon } from "assets/writeIcon/note.svg";
import AutoCompleteCompany from "components/AutoCompleteCompany";
import DialogText from "components/DialogText";
import { CancelButton, SaveButton } from "components/Common";
import { useErrorHandler } from "components/ErrorHandler";
import { autoSelectCompIdState, isEditedState } from "recoil/stateStore";
import { Member, getCompanyMember, getCompanyMembers } from "api/member";
import { useRecoilValue, useSetRecoilState } from "recoil";

interface EditConsFormProps {
  consData: CounselingType;
  setConsData: Function;
}

const EditConsForm: React.FC<EditConsFormProps> = ({
  consData,
  setConsData,
}) => {
  const loginUserIdStr = localStorage.getItem("user_id");
  const loginUserId: string = loginUserIdStr ? loginUserIdStr : "";
  const [openDialog, setOpenDialog] = useState(false);
  const [consDate, setConsDate] = useState<string>(
    consData.cons_date.split("T")[0]
  );
  const [compId, setCompId] = useState<number | null>(consData.company.comp_id);
  const [memberId, setMemberId] = useState<number | null>(
    consData.member.member_id
  );
  const [project, setProject] = useState<string>(consData.project);
  const [item, setItem] = useState<string>(consData.item);
  const [amount, setAmount] = useState<number>(consData.amount);
  const [price, setPrice] = useState<number>(consData.price);
  const [progress, setProgress] = useState<string>(consData.result);
  const [note, setNote] = useState<string>(consData.cons_note);
  const [memberPhone, setMemberPhone] = useState<string>(
    consData.member.member_phone
  );
  const handleError = useErrorHandler();
  const [members, setMembers] = useState<Member[]>([]);
  const autoSelectCompId = useRecoilValue(autoSelectCompIdState);
  const setIsEdit = useSetRecoilState(isEditedState);

  // 업무일지 날짜
  const handleConsDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setConsDate(value);
  };

  useEffect(() => {
    setCompId(autoSelectCompId);

    if (autoSelectCompId) {
      const callMembers = async () => {
        try {
          const response = await getCompanyMembers(autoSelectCompId);
          const memberData = response.data.content;
          setMembers(memberData.member_list);
        } catch (error) {
          console.log("no members");
        }
      };
      callMembers();
    }
  }, [autoSelectCompId]);

  // 담당자 선택 - 부서값 자동 채워짐
  // 고객사 담당자에 대한 부서값 조회
  const handleSelectMember = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedMemberId = parseInt(event.target.value);
    setMemberId(selectedMemberId);

    if (compId !== null) {
      if (!isNaN(selectedMemberId) && selectedMemberId !== -1) {
        try {
          const memberInfo = await getCompanyMember(compId, selectedMemberId);
          console.log(memberInfo, "memberInfo");
          setMemberPhone(memberInfo.data.member_phone);
        } catch (error) {
          console.error(error);
        }
      } else {
        setMemberId(null);
        // setMemberPart("-");
      }
    }
  };

  // 취소버튼
  const onCancel = () => {
    setOpenDialog(true);
  };

  const handleSubmit = async () => {
    console.log("수정 저장");
    if (
      compId === null ||
      compId === -1 ||
      memberId === null ||
      project === "" ||
      item === "" ||
      amount === 0 ||
      price === 0 ||
      progress === ""
    ) {
      alert("필수값이 입력되지 않았습니다.");
    } else {
      try {
        const data = {
          cons_date: consDate,
          user_id: loginUserId,
          user_name: consData.user_name,
          comp_id: compId,
          member_id: memberId,
          project: project,
          item: item,
          amount: amount,
          price: price,
          result: progress,
          cons_note: note,
        };
        const consId = consData.cons_id;
        const updateMemberData = await updateCons(consId, data);
        setConsData(updateMemberData);
        // setMemberList((prev) => [...prev, updateMemberData]);
        // console.log(workLogData.work_id, data, updateWorkLog, "수정 데이터");
        // successSave();
        setIsEdit(false);
      } catch (error) {
        handleError(error);
        console.log("edited Counseling not saved");
      }
    }
  };

  return (
    <div className="write_list_wrapper">
      <div className="work_option">
        <div className="write_date">
          <div className="div_icon" style={{ marginRight: "15px" }}>
            <CalendarIcon style={{ marginRight: "4px" }} />
            일시
          </div>
          <input
            type="date"
            name="cons_date"
            value={consDate}
            style={{ marginLeft: "15px", width: "fit-content" }}
            onChange={handleConsDate}
          />
        </div>

        <div>
          <div className="div_icon">
            <UserIcon style={{ marginRight: "4px" }} />
            작성자
          </div>

          <span style={{ paddingLeft: "5px" }}>
            {consData.user_name} ({loginUserIdStr})
          </span>
        </div>
        <div>
          <div className="div_icon">
            <CompanyIcon style={{ marginRight: "4px" }} />
            업체명
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>

          <AutoCompleteCompany />
        </div>
        <div>
          <div className="div_icon">
            <MemberIcon style={{ marginRight: "4px" }} />
            업체 담당자
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <select
            disabled={compId === null || compId === -1}
            value={memberId != null && memberId != -1 ? memberId : -1}
            onChange={handleSelectMember}
            style={{ paddingLeft: "5px" }}
          >
            {compId === null || compId === -1 ? (
              <option value="-1">고객사를 먼저 선택하세요</option>
            ) : (
              <>
                <option value="-1">선택하세요</option>
                {members.map((member) => (
                  <option key={member.member_id} value={member.member_id}>
                    {member.member_name} {member.member_role}
                  </option>
                ))}
              </>
            )}
          </select>
        </div>
        <div className="write_member_phone">
          <div className="div_icon">
            <PhoneIcon style={{ marginRight: "4px" }} />
            연락처
          </div>
          <div className="member_phone">
            <>
              <input
                type="text"
                readOnly
                placeholder="010"
                style={{ textAlign: "center" }}
                value={memberPhone.substring(0, 3)}
              />
              <span>-</span>
              <input
                type="text"
                readOnly
                placeholder="1234"
                style={{ textAlign: "center" }}
                value={memberPhone.substring(4, 8)}
              />
              <span>-</span>
              <input
                type="text"
                readOnly
                placeholder="5678"
                style={{ textAlign: "center" }}
                value={memberPhone.substring(9, 13)}
              />
            </>
          </div>
        </div>
        <div className="textarea_wrapper">
          <div className="div_icon">
            <ProjectIcon style={{ marginRight: "4px" }} />
            프로젝트
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <textarea
            name="project"
            value={project}
            style={{ color: "#000", marginTop: "5px", height: "80px" }}
            onChange={({ target }) => setProject(target.value)}
          />
        </div>
        <div className="textarea_wrapper">
          <div className="div_icon">
            <ItemIcon style={{ marginRight: "4px" }} />
            품목
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <textarea
            value={item}
            name="text"
            style={{ color: "#000", marginTop: "5px", height: "80px" }}
            onChange={({ target }) => setItem(target.value)}
          />
        </div>
        <div>
          <div className="div_icon">
            <CountIcon style={{ marginRight: "4px" }} />
            수량
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <input
            type="number"
            value={amount}
            onChange={({ target }) => setAmount(parseInt(target.value))}
          />
        </div>
        <div>
          <div className="div_icon">
            <PriceIcon style={{ marginRight: "4px" }} />
            단가
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <input
            type="number"
            className="input_price"
            value={price}
            onChange={({ target }) => setPrice(parseInt(target.value))}
          />
        </div>
        <div className="write_cons_sum">
          <div className="div_icon">
            <SumIcon style={{ marginRight: "4px" }} />
            합계
          </div>
          <span>
            {amount !== 0 && price !== 0 ? Number(amount * price) : 0}
          </span>
        </div>
        <div className="textarea_wrapper">
          <div className="div_icon">
            <NoteIcon style={{ marginRight: "4px" }} />
            비고
          </div>
          <textarea
            value={note}
            style={{ height: "140px", marginTop: "4px", color: "#000" }}
            name="cons_note"
            onChange={({ target }) => setNote(target.value)}
            disabled={false}
          />
        </div>
        <div className="work_process">
          <div className="div_icon">
            <ProgressIcon style={{ marginRight: "4px" }} />
            진행상황
          </div>
          <select
            value={progress}
            onChange={({ target }) => setProgress(target.value)}
          >
            <option value="NONE">예정</option>
            <option value="INPROGRESS">진행중</option>
            <option value="DROP">미진행</option>
            <option value="COMPLETE">완료</option>
          </select>
        </div>
      </div>
      <div className="write_bottom">
        <>
          <DialogText
            open={openDialog}
            content="변경 사항이 저장되지 않을 수 있습니다. 계속하시겠습니까?"
            onCancel={() => setOpenDialog(false)}
            onClose={() => setIsEdit(false)}
          />
          <CancelButton onClickHandler={onCancel} />
          <SaveButton onClickHandler={handleSubmit} />
        </>
      </div>
    </div>
  );
};
export default EditConsForm;
