import { Dialog, DialogActions, DialogContentText } from "@mui/material";
import { ReactComponent as ConfirmIcon } from "assets/save.svg";
import { ReactComponent as CancelIcon } from "assets/round_cancel.svg";
import React from "react";

interface DialogProps {
  open: boolean;
  content: string;
  onCancel: (event: any) => void;
  onClose?: (event: any) => void;
}

export const DialogText: React.FC<DialogProps> = ({
  open,
  content,
  onClose,
  onCancel,
}) => {
  return (
    <Dialog open={open}>
      <div
        style={{
          width: "400px",
          height: "fit-content",
          padding: "24px 12px 12px 24px",
          borderRadius: "10px",
        }}
      >
        <DialogContentText
          sx={{ color: "#000", marginBottom: "32px", fontSize: "14px" }}
        >
          {content}
        </DialogContentText>
        <DialogActions className="dialog">
          <button
            className="dialog__cancel_button"
            onClick={onCancel}
            style={{
              //   border: "1px solid #ccc",
              backgroundColor: "#fff",
            }}
          >
            <div
              className="div_icon"
              style={{ color: "#000", justifyContent: "center", gap: "5px" }}
            >
              <CancelIcon />
              취소
            </div>
          </button>
          <button className="dialog__confirm_button" onClick={onClose}>
            <div
              className="div_icon"
              style={{
                color: "#fff",
                justifyContent: "center",
                gap: "5px",
              }}
            >
              <ConfirmIcon />
              확인
            </div>
          </button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default DialogText;
