import { Dialog, DialogActions, DialogContentText } from "@mui/material";

import { ReactComponent as ConfirmIcon } from "assets/save.svg";
import { ReactComponent as CancelIcon } from "assets/round_cancel.svg";
import { useErrorHandler } from "./ErrorHandler";
import { useNavigate } from "react-router-dom";

interface DeleteDialogProps {
  type?: string;
  open: boolean;
  onCancel: (event: any) => void;
  onDelete: (event: any) => void;
}

const DialogDelete: React.FC<DeleteDialogProps> = ({
  type,
  open,
  onCancel,
  onDelete,
}) => {
  const handleError = useErrorHandler();
  const navigate = useNavigate();

  const onConfirm = async () => {};

  return (
    <Dialog open={open}>
      <div
        style={{
          width: "400px",
          height: "fit-content",
          padding: "24px 12px 12px 24px",
          borderRadius: "10px",
        }}
      >
        <DialogContentText
          sx={{ color: "#000", marginBottom: "32px", fontSize: "14px" }}
        >
          해당 데이터를 삭제하시겠습니까?
        </DialogContentText>
        <DialogActions className="dialog">
          <button
            onClick={onCancel}
            style={{
              border: "1px solid #ccc",
              backgroundColor: "#fff",
              cursor: "pointer",
            }}
          >
            <div
              className="div_icon"
              style={{ color: "#000", justifyContent: "center", gap: "5px" }}
            >
              <CancelIcon />
              취소
            </div>
          </button>
          <button
            onClick={onDelete}
            style={{
              cursor: "pointer",
              borderStyle: "none",
              backgroundColor: "#023373",
            }}
          >
            <div
              className="div_icon"
              style={{ color: "#fff", justifyContent: "center", gap: "5px" }}
            >
              <ConfirmIcon />
              확인
            </div>
          </button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default DialogDelete;
