import React, { useState, useRef, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { ColDef } from "ag-grid-community";
import "styles/Chart.css";
import { useRecoilValue } from "recoil";
import { isEditedState } from "recoil/stateStore";

import UpdatedRowData from "./UpdatedRowData";

interface CompanyGridProps {
  columnDefs: ColDef[];
  rowData: any[];
  type: string;
  setOpen: (status: boolean, type: string) => void;
  setId: (type: string, id: number) => void;
  memberData?: any;
  salesData?: any;
  deletedId?: number;
}

const CompanyGrid: React.FC<CompanyGridProps> = ({
  columnDefs,
  rowData,
  type,
  setOpen,
  setId,
  memberData,
  salesData,
  deletedId,
}) => {
  const gridRef = useRef<AgGridReact>(null);
  const [quickFilterText, setQuickFilterText] = useState<string>("");
  const filterInputRef = useRef<HTMLInputElement>(null);
  const isEdit = useRecoilValue(isEditedState);

  const toggleDrawer = () => {
    setOpen(true, type);
  };

  const onRowClicked = async (event: any) => {
    if (type === "members") {
      const memberId = event.data.member_id;
      setId("member", memberId);
      toggleDrawer();
    }
    if (type === "sales") {
      const workId = event.data.work_id;
      setId("sales", workId);
      toggleDrawer();
    }
  };

  useEffect(() => {
    if (!isEdit && memberData) {
      if (type === "members") {
        updateRow(memberData.member_id, memberData);
      }
    }
  }, [memberData]);

  useEffect(() => {
    if (!isEdit && salesData) {
      if (type === "sales") {
        updateRow(salesData.work_id, salesData);
      }
    }
  }, [salesData]);

  useEffect(() => {
    if (deletedId !== -1) {
      onRowDelete();
    }
  }, [deletedId]);

  const gridOptions = {
    getRowId: (params: any) => {
      if (type === "members") {
        return String(params.data.member_id);
      } else if (type === "sales") {
        return String(params.data.work_id);
      }
      return "";
    },
  };

  const onRowDelete = () => {
    const id = String(deletedId);
    if (gridRef.current && gridRef.current.api) {
      const rowNode = gridRef.current.api.getRowNode(id);
      if (rowNode) {
        gridRef.current.api.applyTransaction({ remove: [rowNode.data] });
      }
    }
  };

  const updateRow = (id: number, newData: any) => {
    if (gridRef.current && gridRef.current.api) {
      if (!newData || !id) {
        console.log("Invalid data or missing id in newData.");
        return;
      }
      const rowNode = gridRef.current.api.getRowNode(String(id));
      console.log(rowNode?.data, "rowNode.data");
      if (!rowNode) {
        console.error(`Row with id : ${newData.id} not found.`);
        return;
      }
      const updatedData = UpdatedRowData(newData);

      gridRef.current.api.applyTransaction({
        update: [updatedData],
      });
      console.log(`Row with id: ${newData.id} updated:`, updatedData);
    } else {
      console.log("Grid API is not initialized.");
    }
  };

  //필터 리셋
  const resetFilters = () => {
    gridRef.current?.api.setFilterModel(null);
    gridRef.current?.api.onFilterChanged();
    setQuickFilterText("");
    if (filterInputRef.current) {
      filterInputRef.current.value = "";
    }
  };

  const localeText = {
    // Set locale text to Korean
    contains: "포함",
    notContains: "포함하지 않음",
    blank: "빈 값",
    // Custom filters
    dateFormatOoo: "yyyy-mm-dd",
  };

  return (
    <>
      <div className="grid-container">
        <div className="filter">
          <div>
            <div className="wordSearch_container">
              <label>검색 :</label>
              <input
                ref={filterInputRef}
                type="text"
                placeholder="검색어를 입력하세요"
                onChange={(e) => setQuickFilterText(e.target.value)}
              />
              <button className="resetBtn" onClick={resetFilters}>
                필터 리셋
              </button>
            </div>
          </div>
        </div>
        <div className="ag-theme-alpine" style={{ height: 300 }}>
          <AgGridReact
            ref={gridRef}
            gridOptions={gridOptions}
            columnDefs={columnDefs}
            rowData={rowData}
            quickFilterText={quickFilterText}
            localeText={localeText}
            popupParent={document.body} //필터 누르면 표하단 가려지는 문제 해결용
            onRowClicked={onRowClicked}
            overlayNoRowsTemplate={"데이터가 존재하지 않습니다."} // 데이터 없는 경우 뜨는 메세지
          />
        </div>
      </div>
    </>
  );
};
export default CompanyGrid;
